import { useVendedoresByUser } from 'client/hooks/vendedores';

interface UseVendedoresSelectProps {
  codEmpresa: number | string;
  codUsuario: number | string;
}

export const UseVendedoresSelect = ({ codEmpresa, codUsuario }: UseVendedoresSelectProps) => {
  const { data } = useVendedoresByUser(codEmpresa, codUsuario);

  const vendedoresOptions = data?.map((item) => ({
    value: item.codVendedor,
    codSupervisor: item.codSupervisor,
    label: `${item.nome} - [${item.codVendedor}]`,
  }));

  return {
    vendedoresOptions,
  };
};
