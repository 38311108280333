import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getVendedores } from 'client/api';
import { getVendedoresByUsuario } from 'client/api/vendedores';
import { Ivendedores } from 'client/interfaces';

export const useVendedores = (codEmpresa: number | string, enabled = true) =>
  useQuery<Ivendedores[], AxiosError>(['vendedores', codEmpresa], async () => await getVendedores(codEmpresa), {
    enabled,
  });


  export const useVendedoresByUser = (codEmpresa: number | string, codUsuario: number | string, enabled = true) =>
    useQuery<Ivendedores[], AxiosError>(['vendedores', codEmpresa], async () => await getVendedoresByUsuario(codEmpresa, codUsuario), {
      enabled,
    });