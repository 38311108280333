import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import {
  cpfCodErpSelector,
  empresaCNPJSelector,
  login,
  setEmpresaCNPJ,
  setUserCpf,
} from '../reducer/auth';
import styles from './styles.module.scss';
import * as S from './styles';

interface FormState {
  password: string;
}

function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const empresaCNPJ = useAppSelector(empresaCNPJSelector);
  const cpfCodErp = useAppSelector(cpfCodErpSelector);
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors, dirtyFields },
  } = useForm<FormState>();
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const onSubmit: SubmitHandler<FormState> = async ({ password }) => {
    try {
      setIsLoading(true);
      await dispatch(
        login({
          password,
          cpfCodErp: cpfCodErp ?? '',
        }),
      ).unwrap();

      queryClient.invalidateQueries({
        queryKey: ['user-data'],
      });
    } catch (e: unknown) {
      if (typeof e === 'number') {
        switch (e) {
          case 401:
            setError('password', {
              message: 'senha inválida',
            });
            return;
        }
      } else {
        const missingProperties = localStorage.getItem('vmais_missingProperties');
        if (missingProperties && missingProperties.includes('SENHA')) {
          toast.warning('Cadastre um nova senha');
        } else {
          toast.error('Erro ao validar usuário');
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeUser = () => {
    dispatch(setUserCpf(null));
  };

  const handleChangeCompany = () => {
    dispatch(setEmpresaCNPJ(null));
    dispatch(setUserCpf(null));
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <S.LoginMainBox>
      <form onSubmit={handleSubmit(onSubmit)} className="form w-100" noValidate>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Código Empresa ou CNPJ</label>
          <div className="input-group mb-5">
            <input
              value={empresaCNPJ ?? ''}
              className="form-control form-control-lg form-control-solid"
              readOnly
              disabled
            />
            <a onClick={handleChangeCompany} className="btn btn-light">
              Alterar
            </a>
          </div>
        </div>

        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">CPF ou Matrícula ERP</label>
          <div className="input-group mb-5">
            <input
              value={cpfCodErp ?? ''}
              className="form-control form-control-lg form-control-solid"
              readOnly
              disabled
            />
            <a onClick={handleChangeUser} className="btn btn-light">
              Alterar
            </a>
          </div>
        </div>

        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">Senha</label>
          <div className="input-group mb-5">
            <input
              {...register('password', { required: true })}
              placeholder="digite sua senha"
              className={clsx('form-control form-control-lg form-control-solid', {
                'is-invalid': (dirtyFields.password ?? false) && errors.password,
              })}
              autoFocus
              type={showPassword ? 'text' : 'password'}
              name="password"
            />
            <a onClick={togglePasswordVisibility} className="btn btn-light">
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </a>
          </div>
          {errors.password != null && (
            <div className="fv-plugins-message-container invalid-feedback">
              <span role="alert">{errors.password.message}</span>
            </div>
          )}
        </div>

        <div className="text-center">
          <button type="submit" className={`${styles.button} btn btn-lg  w-100 mb-5`} disabled={isLoading}>
            {!isLoading && <span className="indicator-label">Entrar</span>}
            {isLoading && (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Validando...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
      </form>
    </S.LoginMainBox>
  );
}

export default LoginForm;
