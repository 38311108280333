/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useRef, useState } from 'react';

import {
  getClientesSectorList,
  getClientesWithoutSector,
  getClientesWithSector,
  getMapaIcones,
  getSetores,
  getSupervisores,
  getVendedores,
} from 'client/api';
import { IRoteirizaData } from 'client/interfaces/roteirizador.interface';
import { postRouterData } from 'modules/mapa/pages/Roteirizador/components/executaRoteiro/executaRoteiro';
import { useMapSectorsStore } from 'storesZustand/mapSectorsStore';
import { Isetores2 } from 'client/interfaces';

export interface MapProviderProps {
  children: ReactNode;
}

type ToValidadeCoordinates = {
  lat: number | string;
  lng: number | string;
};

type ToValidadeCoordinatesReturn = {
  isValid: boolean;
  coord: ToValidadeCoordinates;
};

type MarkersControlerProps = {
  currentWeek: boolean;
  nextWeeks: boolean;
  notInRoute: boolean;
};

type ReProcessRouterProps = {
  codSetor?: string | number;
  codVendedor?: string | number;
  codMapa?: string | number;
  codEmpresa: string | number;
};

export interface MapContextProps {
  loadCliMap: boolean;
  setLoadCliMap: React.Dispatch<React.SetStateAction<boolean>>;
  loadCliMapMessage: string | ReactNode;
  setLoadCliMapMessage: React.Dispatch<React.SetStateAction<string | ReactNode>>;
  polygonos: any;
  setPolygonos: React.Dispatch<React.SetStateAction<any>>;
  mapGridShow: boolean;
  setMapGridShow: React.Dispatch<React.SetStateAction<boolean>>;
  clientes: never[];
  setClientes: React.Dispatch<React.SetStateAction<never[]>>;
  clientesList: never[];
  setClientesList: React.Dispatch<React.SetStateAction<never[]>>;
  initialZoom: number;
  setInitialZoom: React.Dispatch<React.SetStateAction<number>>;
  currentPin: {
    lat: any;
    lng: any;
  };
  setCurrentPin: React.Dispatch<
    React.SetStateAction<{
      lat: any;
      lng: any;
    }>
  >;
  colorIconesMapList: any;
  setColorIconesMapList: any;
  viewSetorCad: boolean;
  setViewSetorCad: React.Dispatch<React.SetStateAction<boolean>>;
  setorToEdit: any;
  setSetorToEdit: React.Dispatch<React.SetStateAction<any>>;
  setores: Isetores2[];
  setSetores: React.Dispatch<Isetores2[]>;
  showVinculoSetor: boolean;
  setShowVinculoSetor: React.Dispatch<React.SetStateAction<boolean>>;
  showEditSetor: boolean;
  setShowEditSetor: React.Dispatch<React.SetStateAction<boolean>>;
  currentSetor: any;
  setCurrentSetor: React.Dispatch<React.SetStateAction<any>>;
  currentCli: any;
  setCurrentCli: React.Dispatch<React.SetStateAction<any>>;
  vendedores: any;
  setVendedores: React.Dispatch<React.SetStateAction<any>>;
  supervisores: any;
  setSupervisores: React.Dispatch<React.SetStateAction<any>>;
  reloadAllData: () => void;
  codEmpresa: number;
  setCodEmpresa: React.Dispatch<React.SetStateAction<number>>;
  codmapa: number;
  SetCodMapa: React.Dispatch<React.SetStateAction<number>>;
  roteirizaSetor: any;
  setRoteirizaSetor: React.Dispatch<React.SetStateAction<any>>;
  getRoteirizaData: () => Promise<any>;
  validateCoordinates: (ToValidadeCoordinates) => ToValidadeCoordinatesReturn;
  markersControler: MarkersControlerProps;
  setMarkersControler: React.Dispatch<React.SetStateAction<MarkersControlerProps>>;
  ReProcessRouterData: (ReProcessRouterProps) => void;
  handleGetCliWithoutSector: () => void;
  isClientsWithoutSectorLoaded: boolean;
  isSemSetorSelected: () => boolean;
  searchRef: any;
}

export const MapContext = createContext({} as MapContextProps);

export const MapProvider = ({ children }: MapProviderProps) => {
  const { codCompanyUser, codUser } = useMapSectorsStore();

  const sectors = localStorage.getItem('setores');

  const [loadCliMap, setLoadCliMap] = useState(true);
  const [loadCliMapMessage, setLoadCliMapMessage] = useState('');
  const [mapGridShow, setMapGridShow] = useState(true);

  const [polygonos, setPolygonos] = useState<any>([]);
  const [clientes, setClientes] = useState<any>([]);
  const [initialZoom, setInitialZoom] = useState(14);
  const [currentPin, setCurrentPin] = useState({ lat: '-1.7360918', lng: '-48.8766365' });
  const [clientesList, setClientesList] = useState<any>([]);
  const [viewSetorCad, setViewSetorCad] = useState<boolean>(false);
  const [setorToEdit, setSetorToEdit] = useState<any>('');

  const [setores, setSetores] = useState(sectors ? JSON.parse(sectors) : []);
  const [showVinculoSetor, setShowVinculoSetor] = useState(false);
  const [showEditSetor, setShowEditSetor] = useState(false);
  const [currentSetor, setCurrentSetor] = useState<any>(null);
  const [currentCli, setCurrentCli] = useState<any>(null);
  const [vendedores, setVendedores] = useState<any>([]);
  const [supervisores, setSupervisores] = useState<any>([]);

  const [codEmpresa, setCodEmpresa] = useState(0);
  const [codmapa, SetCodMapa] = useState(0);

  const [roteirizaSetor, setRoteirizaSetor] = useState<IRoteirizaData>(null);

  const [colorIconesMapList, setColorIconesMapList] = useState<any>(null);

  const [markersControler, setMarkersControler] = useState<MarkersControlerProps>({
    currentWeek: true,
    nextWeeks: false,
    notInRoute: false,
  });

  const searchRef: any = useRef(null);

  async function reloadAllData() {
    if (codmapa == 0 || codEmpresa == 0) {
      return;
    }

    // BUSCA LISTA DE CORES/ICONES DO MAPA DE SETORES
    getMapaIcones()
      .then((res: any) => {
        setColorIconesMapList(res ?? []);
      })
      .catch((err: Error) => {
        console.log('Erro ao buscar dados de cores e ícones :', err);
      });

    // BUSCA LISTA DE SETORES
    getClientesSectorList(codCompanyUser, codUser, codmapa)
      .then((res) => {
        let setoresListAll = res;
        const customSort = (a, b) => {
          const codSetorA = Number(a.codSetor);
          const codSetorB = Number(b.codSetor);
          return codSetorA - codSetorB;
        };
        setoresListAll.sort(customSort);

        // const noCliSetor = [
        //   {
        //     setor: ' Clientes sem Setor',
        //     cor: '606060',
        //     codSetor: -1,
        //     codRca: -1,
        //     codMapa: -1,
        //     codGa: -1,
        //     codEmpresa: -1,
        //     atualizaAutomatico: 'N',
        //     polygono: [],
        //     ativo: 'S',
        //   },
        // ];

        // setoresListAll = noCliSetor.concat(setoresListAll);
        for (let i = 0; i < setoresListAll.length; i++) {
          const sector = setoresListAll[i];
          if (sector.CODSETOR == 0) {
            sector.CODSETOR = -1;
          }
          sector.atualizaAutomatico = sector.ATUALIZA_AUTOMATICO;
          sector.codEmpresa = sector.COD_EMPRESA;
          sector.ativo = sector.ATIVO;
          sector.codGa = sector.CODGA ?? 0;

          sector.codMapa = sector.COD_MAPA;
          sector.codRca = sector.COD_RCA;
          sector.codSetor = sector.CODSETOR;

          sector.cor = sector.COR;
          sector.setor = sector.NOMESETOR;
          sector.polygono = [];
          setoresListAll[i] = sector;
        }

        !currentSetor &&
          setCurrentSetor({
            setores: setoresListAll
              ? setoresListAll.filter((sector: Isetores2) => sector.CODSETOR != 0 && sector.CODSETOR != -1)
              : null,
          });
        setSetores(setoresListAll);

        // const polys = setoresListAll.filter((setor: any) => setor.polygono.length > 0);
        // for (let i = 0; i < polys.length; i++) {
        //   polys[i].latlngs = JSON.parse(polys[i].polygono[0].latlong);
        // }
        // setoresListAll = polys;
        setPolygonos([]);
        setPolygonos(setoresListAll);

        let dataSetoresUser = [];

        getClientesSectorList(codCompanyUser, codUser, codmapa)
          .then((res) => {
            dataSetoresUser = res;
          })
          .catch(() => {});

        getClientesWithSector(codCompanyUser, codUser, codmapa)
          .then((res) => {
            let objetosFiltrados = res;
            // const objetosFiltrados: any = res?.filter(
            //   (objeto) => dataSetoresUser?.includes(+objeto.CODSETOR) || +objeto.CODSETOR == 0,
            // );
            if (isSemSetorSelected()) {
              getClientesWithoutSector(codCompanyUser, codUser, codmapa).then((resNoSector) => {
                objetosFiltrados = [...res, ...resNoSector];
                handleFilterAndUpdateMapClients(objetosFiltrados);
                setIsClientsWithoutSectorLoaded(true);
              });
            } else {
              setCurrentPin({ lat: '-1.7360918', lng: '-48.8766365' });
              handleFilterAndUpdateMapClients(objetosFiltrados);
            }
          })
          .catch((err) => {
            setLoadCliMap(false);
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // BUSCA LISTA DE VENDEDORES
    const vendedores = await getVendedores(codEmpresa);
    setVendedores(vendedores.sort((a, b) => a['nome'].localeCompare(b['nome'])));

    // BUSCA LISTA DE SUPERVISORES
    const supervisores = await getSupervisores(codEmpresa);
    setSupervisores(supervisores.sort((a, b) => a['nomesup'].localeCompare(b['nomesup'])));
  }

  const [isClientsWithoutSectorLoaded, setIsClientsWithoutSectorLoaded] = useState(false);

  const isSemSetorSelected = () => {
    if (currentSetor && currentSetor.setores) {
      for (let i = 0; i < currentSetor.setores.length; i++) {
        if (currentSetor.setores[i].CODSETOR == -1) {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const handleGetCliWithoutSector = () => {
    if (!isClientsWithoutSectorLoaded && setores[0] && setores[0].CODSETOR == -1 && isSemSetorSelected()) {
      getClientesWithoutSector(codCompanyUser, codUser, codmapa).then((res) => {
        const cliWithoutSectors = res;
        const allCliList = [...clientes, ...cliWithoutSectors];
        handleFilterAndUpdateMapClients(allCliList);
        setIsClientsWithoutSectorLoaded(true);
      });
    }
  };

  const handleFilterAndUpdateMapClients = (clientes: any[]) => {
    for (let i = 0; i < clientes.length; i++) {
      clientes[i].codCli = clientes[i].CODCLI ?? -1;
      clientes[i].razaosocial = clientes[i].RAZAOSOCIAL;
      clientes[i].latitude = clientes[i].LATITUDE;
      clientes[i].longitude = clientes[i].LONGITUDE;
      clientes[i].codSetor = clientes[i].CODSETOR;
      clientes[i].nomeSetor = clientes[i].NOMESETOR;
      clientes[i].cor = clientes[i].COR;
      clientes[i].icone = clientes[i].ICONE;
      clientes[i].endLogradouro = clientes[i].ENDLOGRADOURO;
      clientes[i].endNumero = clientes[i].ENDNUMERO;
      clientes[i].endCompl = clientes[i].ENDCOMPL;
      clientes[i].endBairro = clientes[i].ENDBAIRRO;
      clientes[i].endCidade = clientes[i].ENDCIDADE;
      clientes[i].endUf = clientes[i].ENDUF;
      clientes[i].endCep = clientes[i].END_CEP;
      clientes[i].freqVisita = clientes[i].FREQVISITA;
      clientes[i].tempoMinVisita = clientes[i].TEMPOMINVISITA;
      clientes[i].dtProxVisita = clientes[i].DT_PROX_VISITA;
      clientes[i].vlPotencial = clientes[i].VL_POTENCIAL;
      clientes[i].vlMesAtual = clientes[i].VL_MES_ATUAL;
      clientes[i].vlMedUltTrim = clientes[i].VL_MED_ULT_TRIM;
      clientes[i].bloqueioRoteiro = clientes[i].BLOQ_ROTEIRO;
      clientes[i].setorAtivo = clientes[i].SETOR_ATIVO;
      clientes[i].domingoManha = clientes[i].DOMINGO_MANHA;
      clientes[i].domingoTarde = clientes[i].DOMINGO_TARDE;
      clientes[i].segundaManha = clientes[i].SEGUNDA_MANHA;
      clientes[i].segundaTarde = clientes[i].SEGUNDA_TARDE;
      clientes[i].tercaManha = clientes[i].TERCA_MANHA;
      clientes[i].tercaTarde = clientes[i].TERCA_TARDE;
      clientes[i].quartaManha = clientes[i].QUARTA_MANHA;
      clientes[i].quartaTarde = clientes[i].QUARTA_TARDE;
      clientes[i].quintaManha = clientes[i].QUINTA_MANHA;
      clientes[i].quintaTarde = clientes[i].QUINTA_TARDE;
      clientes[i].sextaManha = clientes[i].SEXTA_MANHA;
      clientes[i].sextaTarde = clientes[i].SEXTA_TARDE;
      clientes[i].sabadoManha = clientes[i].SABADO_MANHA;
      clientes[i].sabadoTarde = clientes[i].SABADO_TARDE;
      clientes[i].agendamentoDoCliente = clientes[i].AGENDAMENTO_DO_CLIENTE;
      clientes[i].sequencia = clientes[i].SEQUENCIA;
      clientes[i].statusSetor = clientes[i].SETOR_ATIVO;
    }
    setClientes(clientes);
    let markersCli: any = clientes;
    if (currentSetor && currentSetor.setores) {
      let markersCliFilter = [];
      for (let i = 0; i < currentSetor.setores.length; i++) {
        clientes.map((cli: any) => {
          if (currentSetor.setores[i].codSetor == -1 && !cli.codSetor) {
            markersCliFilter.push(cli);
          } else if (cli.codSetor === currentSetor.setores[i].codSetor) {
            markersCliFilter.push(cli);
          }
        });
      }
      markersCli = markersCliFilter;
      // setClientesList(clientes);
      hadleCliSearch(clientes);
    } else {
      // setClientesList(clientes);
      hadleCliSearch(clientes);
    }
    setLoadCliMap(false);
  };

  // FUNÇÃO QUE TRATA E RETORNA DADOS DO SETOR / VENDEDOR A SE ROTEIRIZAR
  async function getRoteirizaData() {
    if (roteirizaSetor !== null) {
      return roteirizaSetor;
    } else {
      return JSON.parse(localStorage.getItem('roteirizaSetor'));
    }
  }

  const validateCoordinates = (coords: ToValidadeCoordinates): ToValidadeCoordinatesReturn => {
    if (!coords.lat || !coords.lng) {
      return {
        isValid: false,
        coord: {
          lat: +coords.lat,
          lng: +coords.lng,
        },
      };
    }

    coords.lat = coords.lat.toString();
    coords.lng = coords.lng.toString();

    if (!coords.lat.includes('.') && coords.lat.length >= 2) {
      coords.lat = +`${coords.lat.slice(0, 2)}.${coords.lat.slice(2)}`;
    }
    if (!coords.lng.includes('.') && coords.lng.length >= 2) {
      coords.lng = +`${coords.lng.slice(0, 2)}.${coords.lng.slice(2)}`;
    }

    return {
      isValid: true,
      coord: {
        lat: +coords.lat,
        lng: +coords.lng,
      },
    };
  };

  async function ReProcessRouterData({ codSetor, codVendedor, codMapa, codEmpresa }: ReProcessRouterProps) {
    try {
      if (codMapa) {
        const setoresList = await getSetores(+codMapa);
        for (let i = 0; i < setoresList.length; i++) {
          const setor = setoresList[i];
          if (setor.codRca && setor.atualizaAutomatico == 'S') {
            await postRouterData(setor.codSetor.toString(), setor.codRca.toString(), setor.codEmpresa.toString(), 0)
              .then(() => {
                console.log(`Setor ${setor.codSetor.toString()} reprocessado!`);
              })
              .catch((err) => {
                console.log(err);
              });
          }
        }
      } else if (codVendedor && codSetor && codEmpresa) {
        postRouterData(codSetor.toString(), codVendedor.toString(), codEmpresa.toString(), 0)
          .then(() => {
            console.log(`Setor ${codSetor.toString()} reprocessado!`);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
      console.log('Erro ao reprocessar dados do roteiro!');
    }
  }

  const hadleCliSearch = (cliArray: any) => {
    if (searchRef.current != null && searchRef.current.value != '') {
      const currentSetores = currentSetor ? currentSetor.setores : [];
      setTimeout(() => {
        if (searchRef.current != null && searchRef.current.value === '') {
          let fullCliListToSet: any = [];
          if (currentSetores.length > 0) {
            for (let i = 0; i < currentSetores.length; i++) {
              let clisFiltered = cliArray.filter((cli: any) => cli.codSetor == currentSetores[i].codSetor);
              fullCliListToSet = [...fullCliListToSet, ...clisFiltered];
            }
          } else {
            fullCliListToSet = cliArray;
          }

          if (!mapGridShow) {
            setClientesList(fullCliListToSet);
          }
          return;
        }

        let cliList: any = [];

        const searchRefArray = searchRef.current.value.toUpperCase().split(',');

        for (let i = 0; i < cliArray.length; i++) {
          let actualCli: any = cliArray[i];
          for (let j = 0; j < searchRefArray.length; j++) {
            let actualSearchText: string = searchRefArray[j];
            if (
              actualCli.razaosocial.includes(actualSearchText) ||
              actualCli.codCli == +actualSearchText ||
              actualCli.codCli.toString().includes(actualSearchText)
            ) {
              cliList.push(actualCli);
            }
          }
        }

        let newCliList: any = [];
        let sectorsCliList: any = [];

        if (currentSetores.length > 0) {
          for (let i = 0; i < currentSetores.length; i++) {
            let clisFiltered = cliList.filter(
              (cli: any) =>
                cli.codSetor == currentSetores[i].codSetor || (!cli.codSetor && currentSetores[i].codSetor == -1),
            );
            let cliSectorsFiltered = cliArray.filter((cli: any) => cli.codSetor == currentSetores[i].codSetor);
            sectorsCliList = [...sectorsCliList, ...cliSectorsFiltered];
            newCliList = [...newCliList, ...clisFiltered];
          }
        } else {
          newCliList = cliList;
        }

        let actualSearchText: string = searchRef.current ? searchRef.current.value : '';
        if (actualSearchText.includes('/')) {
          newCliList = sectorsCliList?.filter((cli) =>
            new Date(cli.dtProxVisita).toLocaleDateString('pt-BR').includes(actualSearchText),
          );
        }

        if (!mapGridShow) {
          setClientesList(newCliList);
        }
      }, 500);
    } else {
      setClientesList(cliArray);
    }
  };

  return (
    <MapContext.Provider
      value={{
        colorIconesMapList,
        setColorIconesMapList,
        loadCliMap,
        setLoadCliMap,
        loadCliMapMessage,
        setLoadCliMapMessage,
        setPolygonos,
        polygonos,
        mapGridShow,
        setMapGridShow,
        clientes,
        setClientes,
        initialZoom,
        setInitialZoom,
        currentPin,
        setCurrentPin,
        clientesList,
        setClientesList,
        viewSetorCad,
        setViewSetorCad,
        setorToEdit,
        setSetorToEdit,
        setores,
        setSetores,
        showVinculoSetor,
        setShowVinculoSetor,
        showEditSetor,
        setShowEditSetor,
        currentSetor,
        setCurrentSetor,
        currentCli,
        setCurrentCli,
        vendedores,
        setVendedores,
        supervisores,
        setSupervisores,
        reloadAllData,
        codEmpresa,
        setCodEmpresa,
        codmapa,
        SetCodMapa,
        setRoteirizaSetor,
        roteirizaSetor,
        getRoteirizaData,
        validateCoordinates,
        markersControler,
        setMarkersControler,
        ReProcessRouterData,
        handleGetCliWithoutSector,
        isClientsWithoutSectorLoaded,
        isSemSetorSelected,
        searchRef,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};
