import styled from 'styled-components';

type MenuItemProps = {
  active?: boolean;
};

export const MenuMainBox = styled.div`
  position: relative;
  .aside-menu {
    scrollbar-width: none !important;
  }
  .aside:not(:hover) .aside-menu .menu-title {
    opacity: 0 !important;
    transition: opacity 0.3s ease !important;
  }
`;

export const MenuKtAsideBox = styled.div`
  scrollbar-width: none;
  &:hover {
    scrollbar-width: auto;
    scrollbar-color: #cccccc10 !important;
    scrollbar-base-color: #cccccc10 !important;
  }
  max-height: 85dvh;
  overflow: hidden;
`;

export const MenuTreeBox = styled.section<MenuItemProps>`
  .p-treenode-icon {
    color: var(--primary-green) !important;
    height: 10px !important;
  }

  .p-treenode-children {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }

  .p-treenode {
    background-color: #00000010;
  }

  .p-treenode-content {
    padding-left: 5px !important;
    background-color: #00000010;
    background-color: transparent !important;
  }
  .p-treenode-content:hover {
    background: #ffffff10 !important;
    outline: none !important;
  }
  .p-ghost-content {
    width: 10px;
    height: 5px;
  }
  .p-treenode-leaf {
    margin-top: 1px !important;
    background-color: #30334c !important;
  }
  .p-treenode-leaf:hover {
    background-color: transparent !important;
  }
  .p-treenode-leaf:has(.active) {
    background-color: #ffffff10 !important;
  }
  .p-treenode-leaf:not(:has(.active)) {
    background-color: #00000000;
  }
  * {
    caret-color: transparent;
    outline: none !important;
  }

  .p-tree {
    padding: 0 !important;
  }
`;

export const MenuItem = styled.div`
  color: #cccccc;
  width: 100% !important;
`;

type MenuSubItemProps = {
  ktMenuMinimized: boolean;
};

export const MenuItemBox = styled.div<MenuSubItemProps>`
  width: 100% !important;
  color: #cccccc;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  color: ${(props) => (props.ktMenuMinimized ? `transparent !important` : '#FFF !important')};
  background-color: transparent !important;

  span {
    max-width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
  }

  &:hover {
    background-color: transparent !important;
  }
  *:hover {
    background-color: transparent;
  }
`;
export const MenuItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 7.5px;
  font-size: 13px !important;
  font-weight: normal !important;
  padding-left: 25px !important;
`;

export const MenuItemTitleCod = styled.span<MenuSubItemProps>`
  color: #fff;
  padding-left: 0px;
  color: ${(props) => (props.ktMenuMinimized ? `transparent !important` : '#FFF !important')};
`;

export const MenuItemTitleName = styled.span<MenuSubItemProps>`
  color: #27b1a7;
  color: ${(props) => (props.ktMenuMinimized ? `transparent !important` : '#27b1a7 !important')};
`;

export const MenuItemBoxIcon = styled.span`
  color: #cccccc;
  margin: 5px;

  button {
    border: 1px solid #cccccc15 !important;
    border-radius: 10rem !important;
  }

  button:hover {
    background-color: #cccccc10 !important;
    color: #ffffff !important;
  }
`;

export const MenuSearchBox = styled.div`
  color: #fff !important;
  span {
    padding: 5px 10px;
    border-radius: 3rem;
    background-color: #30334c !important;
    border: 1px solid #cccccc;
  }

  input {
    background-color: transparent;
    border: none !important;
    outline: none !important;
    color: #fff !important;
    caret-color: #fff;
    padding-right: 25px;
  }

  input::placeholder {
    color: #fff !important;
  }

  i {
    margin-right: 10px;
    transition: all 0.2s ease;
  }

  span:hover i {
    margin-right: 10px;
    color: #fff !important;
  }
`;

export const MenuItemDashBoard = styled.div`
  color: #fff;
  width: 100%;
  background-color: #00000010;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background: #ffffff10 !important;
    outline: none !important;
  }
`;

export const MenuItemDashBoardTitle = styled.span<MenuSubItemProps>`
  color: ${(props) => (props.ktMenuMinimized ? `transparent !important` : '#FFF !important')};
  padding-left: 5px;
`;

export const MenuItemDashBoardIcon = styled.span`
  margin: 7.5px;
  color: #27b1a7 !important;
`;

type MenuVersionBoxProps = {
  show?: boolean;
};

export const MenuVersionBox = styled.div<MenuVersionBoxProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px;
  color: #fff;
  transition: all 0.5s ease;
  white-space: nowrap;
  opacity: ${(props) => (props.show ? `1` : '0')};
`;
