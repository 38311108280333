import useMediaQuery from 'hooks/useMediaQuery';
import { Dialog } from 'primereact/dialog';
import { SetStateAction, useRef, useState } from 'react';
import VmButton from 'components/VmButton/VmButton';
import styled from 'styled-components';
import { InputTextarea } from 'primereact/inputtextarea';

import * as S from './newKpiModal.styles';
import { useKpi } from 'modules/kpi/kpi.context';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { postNewKpi } from 'client/api/kpis';
import { toast } from 'react-toastify';

interface newKpiModalProps {
  showNewKpiModal: boolean;
  setShowNewKpiModal: React.Dispatch<SetStateAction<boolean>>;
}

interface newKpiProps {
  nrokpi: number;
  titulo: string;
  ordemapresenta: number;
  agrupador: number;
  freqatualiza: number;
  dtproxatu: string;
  sqlleitura: string;
  cod_empresa: number;
  dt_vmais: string;
  itens: null;
}

export default function NewKpiModal({ showNewKpiModal, setShowNewKpiModal }: newKpiModalProps) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { handleGetKpiList, agrupadorOptions, selectedEmpresa } = useKpi();

  const defaultNewKpiData: newKpiProps = {
    nrokpi: 0,
    titulo: '',
    ordemapresenta: 1,
    agrupador: 1,
    freqatualiza: 240,
    dtproxatu: '',
    sqlleitura: '',
    cod_empresa: selectedEmpresa.codEmpresa,
    dt_vmais: '',
    itens: null,
  };

  const [toInsertKpi, setToInsertKpi] = useState<newKpiProps>({ ...defaultNewKpiData });

  const handleValidateNewKpiFields = () => {
    for (const key in toInsertKpi) {
      if (toInsertKpi.hasOwnProperty(key)) {
        if (
          (toInsertKpi[key] === '' || toInsertKpi[key] === 0) &&
          key !== 'dtproxatu' &&
          key !== 'dt_vmais' &&
          key !== 'dtproxatu' &&
          key !== 'nrokpi' &&
          key !== 'sqlleitura'
        ) {
          toast.warn(`O campo '${key}' está vazio.`);
          return;
        }
      }
    }
    handleInsertKpi();
  };

  const textareaRef = useRef(null);

  function handleFormatedDateToInsert(minutesOffset: number): string {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + minutesOffset);
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = currentDate.getFullYear();
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  const handleInsertKpi = () => {
    console.log(agrupadorOptions.filter((agrupador: any) => +agrupador.code == toInsertKpi.agrupador)[0]);
    const kpiDataToInsert = { ...toInsertKpi };
    kpiDataToInsert.sqlleitura = textareaRef.current ? textareaRef.current.value : '';
    kpiDataToInsert.dtproxatu = handleFormatedDateToInsert(+kpiDataToInsert.freqatualiza ?? 0);
    kpiDataToInsert.dt_vmais = handleFormatedDateToInsert(+kpiDataToInsert.freqatualiza ?? 0);
    if (kpiDataToInsert.sqlleitura == '') {
      toast.warn(`O campo sqlleitura está vazio.`);
    }
    postNewKpi(kpiDataToInsert)
      .then((res) => {
        if (res.succeeded) {
          handleGetKpiList();
          toast.success('KPI inserido com sucesso');
          setShowNewKpiModal(false);
        }
      })
      .catch((err) => {
        toast.error('Falha inesperada ao inserir KPI.');
        console.log('err :', err);
      });
  };

  const StyledTextarea = styled(InputTextarea)`
    resize: none;
  `;

  return (
    <Dialog
      header={'Novo KPI'}
      visible={showNewKpiModal}
      style={{ width: isWebScreen ? '55vw' : '90%' }}
      onHide={() => {
        setShowNewKpiModal(false);
      }}
    >
      <S.EditKpiModalFormBox>
        <S.EditKpiModalFormRow>
          <S.KpiInputBox>
            <label htmlFor="titulo_kpi">Título</label>
            <InputText
              id="titulo_kpi"
              placeholder="Título"
              style={{
                width: 'clamp(200px, 250px, 300px)',
              }}
              value={toInsertKpi.titulo}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                setToInsertKpi((prevState: any) => {
                  return {
                    ...prevState,
                    titulo: e.target.value,
                  };
                });
              }}
            />
          </S.KpiInputBox>

          <S.KpiInputBox>
            <label htmlFor="freqatualiza_kpi">Freq. Atualiza</label>
            <InputText
              id="freqatualiza_kpi"
              placeholder="Freq. Atualiza"
              style={{
                width: 'clamp(75px, 90px, 120px)',
              }}
              value={toInsertKpi.freqatualiza}
              onChange={(e) => {
                setToInsertKpi((prevState: any) => {
                  return {
                    ...prevState,
                    freqatualiza: e.target.value,
                  };
                });
              }}
            />
          </S.KpiInputBox>

          <S.KpiInputBox>
            <label htmlFor="agrupador_kpi">Agrupador</label>
            <Dropdown
              options={agrupadorOptions}
              value={(toInsertKpi.agrupador ?? 1).toString()}
              onChange={(e) => {
                setToInsertKpi((prevState: any) => {
                  return {
                    ...prevState,
                    agrupador: e.value,
                  };
                });
              }}
              placeholder="Agrupador"
              optionLabel="name"
              optionValue="code"
              itemTemplate={(agrupador) => {
                return '[' + agrupador.code + '] - ' + agrupador.name;
              }}
              id="agrupador_kpi"
            />
          </S.KpiInputBox>
        </S.EditKpiModalFormRow>

        <S.EditKpiModalFormRow>
          <S.KpiInputBox>
            <label htmlFor="sqlleitura_kpi">SQL Apuração</label>
            <StyledTextarea
              id="sqlleitura_kpi"
              ref={textareaRef}
              defaultValue={textareaRef.current ? textareaRef.current.value : ''}
              onChange={(e) => {
                textareaRef.current.value = e.target.value;
              }}
              rows={19}
              cols={100}
            />
          </S.KpiInputBox>
        </S.EditKpiModalFormRow>

        <S.EditKpiModalFormFooter>
          <VmButton
            options={{
              label: 'Salvar',
              style: 'btn-success',
              size: 'btn-md',
            }}
            onClick={() => {
              handleValidateNewKpiFields();
            }}
          />
        </S.EditKpiModalFormFooter>
      </S.EditKpiModalFormBox>
    </Dialog>
  );
}
