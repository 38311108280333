/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useState } from 'react';
import { BsCheck, BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { editSetor } from 'client/api';
import ButtonComponent from 'components/Button';
import { MapContext } from 'providers/map';

import * as S from './styles';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { InputSwitch } from 'primereact/inputswitch';

const EditSetor = () => {
  const {
    setorToEdit,
    setShowEditSetor,
    showEditSetor,
    vendedores,
    supervisores,
    codmapa,
    codEmpresa,
    reloadAllData,
    colorIconesMapList,
  } = useContext(MapContext);

  const [setorName, setSetorName] = useState('');
  const [cor, setCor] = useState<any>({ nome: 'preto', cor: '000000' });
  const [supervisorSelecionado, setSupervisorSelecionado] = useState<any>([]);
  const [vendedorSelecionado, setVendedorSelecionado] = useState<any>([]);
  const [autSelecionado, setAutSelecionado] = useState<string>('S');

  useEffect(() => {
    let toEditCor = colorIconesMapList?.filter((colors: any) => colors.cor === setorToEdit.cor) ?? [];
    setCor(toEditCor ? toEditCor[0] : []);
  }, [colorIconesMapList, setorToEdit]);

  useEffect(() => {
    const supervisorName = supervisores.filter((ga: any) => ga.codSupervisor === setorToEdit.codGa);
    setSupervisorSelecionado(supervisorName.length > 0 ? supervisorName[0] : []);

    const vendedorName = vendedores.filter((ga: any) => ga.codVendedor === setorToEdit.codRca);
    setVendedorSelecionado(vendedorName.length > 0 ? vendedorName[0] : []);

    setSetorName(setorToEdit.setor);

    setSectorStatus({ name: setorToEdit.ativo == 'S' ? 'ATIVO' : 'INATIVO', code: setorToEdit.ativo == 'S' });

    setAutSelecionado(setorToEdit.atualizaAutomatico ?? 'S');
  }, [setorToEdit]);

  const handleSaveSetor = () => {
    if (setorName === '' || setorToEdit === '') {
      return;
    }

    let toEditSetor = {
      codSetor: setorToEdit.codSetor,
      setor: setorName,
      CodRca: vendedorSelecionado.codVendedor,
      ativo: sectorStatus.code,
      codFilial: 1,
      codGa: supervisorSelecionado.codSupervisor,
      codGerente: supervisorSelecionado.codSupervisor,
      codMapa: codmapa,
      cor: cor.cor,
      CodEmpresa: codEmpresa,
      atualizaAutomatico: autSelecionado,
    };

    editSetor(toEditSetor)
      .then((res) => {
        reloadAllData();
        toast.success('Setor editado com sucesso!');
      })
      .catch((err) => {
        toast.error('Erro ao editar setor!');
      });

    setVendedorSelecionado([]);
    setSupervisorSelecionado([]);
    setSetorName('');
    setShowEditSetor(false);
  };

  const DialogFooterPolygon = (
    <S.BoxButtons>
      <ButtonComponent icon={<BsX />} text="Cancelar" onClick={() => setShowEditSetor(false)} />
      <ButtonComponent icon={<BsCheck />} text="Salvar" onClick={(e) => handleSaveSetor()} />
    </S.BoxButtons>
  );

  const corTemplate = (rowData: any) => {
    return (
      <S.CorOption cor={rowData.cor}>
        <S.Cor cor={rowData.cor} />
        {rowData.nome}
      </S.CorOption>
    );
  };

  const vendedorOptionTemplate = (vendedor: any) => {
    return (
      <S.SelectOption>
        [{vendedor.codVendedor}] {vendedor.nome}
        <S.SelectOptionCloseButton
          show={vendedorSelecionado && vendedorSelecionado.codVendedor == vendedor.codVendedor}
          onClick={() => setVendedorSelecionado([])}
        >
          <AiOutlineCloseCircle />
        </S.SelectOptionCloseButton>
      </S.SelectOption>
    );
  };

  const supervisorOptionTemplate = (supervisor: any) => {
    return (
      <S.SelectOption>
        [{supervisor.codSupervisor}] {supervisor.nomesup}
        <S.SelectOptionCloseButton
          show={supervisorSelecionado && supervisorSelecionado.codSupervisor == supervisor.codSupervisor}
          onClick={() => setSupervisorSelecionado([])}
        >
          <AiOutlineCloseCircle />
        </S.SelectOptionCloseButton>
      </S.SelectOption>
    );
  };

  const autoRouterTemplate = (option: any) => {
    return <span>{option.option == 'S' ? 'SIM' : 'NÃO'}</span>;
  };

  type FilterProps = {
    name: string;
    code: boolean;
  };
  const [sectorStatus, setSectorStatus] = useState<FilterProps>({ name: 'ATIVO', code: true });
  const filters = [
    { name: 'ATIVO', code: true },
    { name: 'INATIVO', code: false },
  ];

  return (
    <div>
      <Dialog
        visible={showEditSetor}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={'Editar Setor'}
        modal
        className="p-fluid"
        onHide={() => setShowEditSetor(false)}
        footer={DialogFooterPolygon}
      >
        <form className={'novoSetorForm'}>
          <S.Box htmlFor="name" className="font-bold">
            Nome do Setor
            <InputText id="name" placeholder="Setor" value={setorName} onChange={(e) => setSetorName(e.target.value)} />
          </S.Box>

          <S.Box htmlFor="name" className="font-bold">
            Supervisor
            <Dropdown
              options={supervisores}
              optionLabel="nomesup"
              placeholder={supervisorSelecionado?.nomesup || 'Selecione um supervisor'}
              value={supervisorSelecionado}
              itemTemplate={supervisorOptionTemplate}
              filter
              className="w-full md:w-14rem"
              onChange={(e) => setSupervisorSelecionado(e.target.value)}
              showClear
            />
          </S.Box>

          <S.Box htmlFor="name" className="font-bold">
            Vendedor
            <Dropdown
              options={vendedores}
              optionLabel="nome"
              placeholder={vendedorSelecionado?.nome || 'Selecione um vendedor'}
              value={vendedorSelecionado}
              itemTemplate={vendedorOptionTemplate}
              filter
              className="w-full md:w-14rem"
              onChange={(e) => {
                setVendedorSelecionado(e.target.value);
              }}
              showClear
            />
          </S.Box>

          <S.Box htmlFor="option" className="font-bold">
            Roteiriza Automático
            <Dropdown
              options={[
                { option: 'S', checked: true, label: 'SIM' },
                { option: 'N', label: 'NÃO' },
              ]}
              optionLabel="label"
              defaultChecked={true}
              placeholder={autSelecionado == 'S' ? 'SIM' : 'NÃO'}
              filter
              className="w-full md:w-14rem"
              onChange={(e) => {
                setAutSelecionado(e.target.value.option);
              }}
              itemTemplate={autoRouterTemplate}
            />
          </S.Box>

          <S.LineBox>
            <S.Box htmlFor="description" className="font-bold">
              Cor:
              <Dropdown
                options={colorIconesMapList}
                optionLabel="nome"
                defaultChecked={true}
                placeholder={cor ? cor.nome : ''}
                itemTemplate={corTemplate}
                onChange={(e) => {
                  setCor(e.target.value);
                }}
              />
            </S.Box>

            <S.Box htmlFor="description" className="font-bold">
              Ativo/Inativo:
              <InputSwitch
                checked={sectorStatus.code}
                onChange={(e) => setSectorStatus({ name: e.value ? 'ATIVO' : 'INATIVO', code: e.value })}
              />
            </S.Box>
          </S.LineBox>
        </form>
      </Dialog>
    </div>
  );
};

export default EditSetor;
