import { useEffect } from 'react';
import * as S from './verbasList.styles';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import VmButton from 'components/VmButton/VmButton';
import { InputText } from 'primereact/inputtext';
import { useVerbas } from 'modules/verbas/verbas.context';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { VerbasFilterProps, VerbasFilterStatusProps } from 'modules/verbas/verbas.types';
import { Dropdown } from 'primereact/dropdown';
import VerbasTable from 'modules/verbas/components/verbasTable/verbasTable.index';
import SupervTable from 'modules/verbas/components/supervTable/supervTable.index';
import VendedorTable from 'modules/verbas/components/vendedorTable/vendedorTable.index';

export default function VerbasList() {
  const { verbasFilter, setVerbasFilter, statusFilterOptions } = useVerbas();

  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();

  const { setTitlePage } = useTitlePageStore();

  useEffect(() => {
    setTitlePage('Verbas');
  }, []);

  return (
    <S.VerbasMainBox>
      <S.VerbasHeaderMainBox>
        <S.VerbasHeaderRow>
          <SelectEmpresa />
          <S.VerbasInputBox>
            <label htmlFor="report">Verba</label>
            <InputText
              id="rule"
              placeholder="Buscar pelo código ou nome da verba"
              style={{
                width: 'clamp(200px, 250px, 300px)',
              }}
              value={verbasFilter.text}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                setVerbasFilter((prevState: VerbasFilterProps) => {
                  return {
                    ...prevState,
                    text: e.target.value,
                  };
                });
              }}
            />
          </S.VerbasInputBox>
          <S.VerbasInputBox>
            <label htmlFor="report">Listar Verbas</label>
            <Dropdown
              value={
                statusFilterOptions.filter((options: VerbasFilterStatusProps) => verbasFilter.status == options.code)[0]
              }
              options={statusFilterOptions}
              emptyMessage="Nenhum Status :/"
              optionLabel={`name`}
              onChange={(e) => {
                setVerbasFilter((prevState: VerbasFilterProps) => {
                  return {
                    ...prevState,
                    status: e.value.code,
                  };
                });
              }}
            />
          </S.VerbasInputBox>
          <VmButton
            options={{
              label: '+ Verba',
              style: 'btn-success',
              size: 'btn-md',
            }}
          />
        </S.VerbasHeaderRow>
      </S.VerbasHeaderMainBox>

      <VerbasTable codEmpr={selectedEmpresa.codEmpresa} />
      <S.VerbasContentBox>
        <S.VerbasContentItem>
          <SupervTable codEmpr={selectedEmpresa.codEmpresa} />
        </S.VerbasContentItem>

        <S.VerbasContentItem>
          <VendedorTable codEmpr={selectedEmpresa.codEmpresa} />
        </S.VerbasContentItem>
      </S.VerbasContentBox>
    </S.VerbasMainBox>
  );
}
