import { DrawingManager, GoogleMap, InfoWindow, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import { editPoly as editPolyFunction, removeCliSetorVinculo } from 'client/api/areaMapa';
import { IMapaClient } from 'client/interfaces/index';
import { TITLE_PAGE } from 'enums/titlePages';
import CliList from 'modules/mapa/components/cliList/cliList';
import CliAddressModal from 'modules/mapa/components/cliList/components/cliAddressModal/cliAddressModal';
import VinculoCliSetorModal from 'modules/mapa/components/cliList/components/vinculoCliSetor/vinculoCliSetor';
import EditSetor from 'modules/mapa/components/editSetor/editSetor';
import LoadCliMap from 'modules/mapa/components/loadCliMap/loadCliMap';
import MapHeader from 'modules/mapa/components/mapHeader/mapHeader';
import MapSidebar from 'modules/mapa/components/mapSideBar/mapSidebar';
import PolyData from 'modules/mapa/components/polyData/polyData';

import { useTitlePageStore } from 'storesZustand/titlePageStore';

import CreatePoly from './components/createPoly/createPoly';
import { MarkerMapSectorsClients } from './components/MarkerMapSectorsClients';
import PolyEditBox from './components/polyEditBox/polyEditBox';
import polyInterpol from './components/polyInterpol/polyInterpol';
import ValidaCliPoly from './components/validaCliPoly/validaCliPoly';
import VinculoSetorModal from './components/vinculoSetorModal/vinculoSetorModal';
import * as S from './styles';
import { useLoaderEffect } from 'providers/loaderEffect';
import { MapContext } from 'providers/map';

export default function Regioes() {
  const {
    clientes,
    polygonos,
    currentSetor,
    currentCli,
    reloadAllData,
    codEmpresa,
    setCodEmpresa,
    codmapa,
    SetCodMapa,
    validateCoordinates,
    handleGetCliWithoutSector,
    isClientsWithoutSectorLoaded,
    setores,
    isSemSetorSelected,
  } = useContext(MapContext);
  const { setLoader } = useLoaderEffect();

  const { setTitlePage, titlePage } = useTitlePageStore();
  const {
    codEmpresa: codEmpresaParam,
    codUser: codUser,
    codMapa: codMapaParam,
  } = useParams<{ codEmpresa: string; codUser: string; codMapa: string }>();

  const [showEditAddressModal, setShowEditAddressModal] = useState<boolean>(false);

  const [selectedMarker, setSelectedMarker] = useState<IMapaClient>(null);

  const [showEditVinculoModal, setShowEditVinculoModal] = useState<boolean>(false);

  type FilterProps = {
    name: string;
    code: string;
  };
  const [selectedFilter, setSelectedFilter] = useState<FilterProps>({ name: 'Ativos', code: 'A' });

  const handleRemoveCliSector = (codEmpresa: string) => {
    Swal.fire({
      title: `Deseja remover o Cliente[${selectedMarker?.codCli}] do Setor ?`,
      icon: 'error',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não, voltar',
      showCancelButton: true,
    })
      .then((result) => {
        if (result.isConfirmed) {
          let toRemoveCliSetorVinculo = {
            codSetor: +selectedMarker.codSetor,
            codCli: +selectedMarker.codCli,
            codFilial: 1,
            codEmpresa: +codEmpresa,
          };
          removeCliSetorVinculo(toRemoveCliSetorVinculo)
            .then(() => {
              reloadAllData();
              toast.success('Vinculo do cliente x setor removido!');
            })
            .catch((err: Error) => {
              console.error(err);
              toast.error('Falha ao remover vinculo do cliente x setor!');
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (currentCli) {
      setSelectedMarker(currentCli);
      setMapCenter({
        lat: currentCli.latitude ?? 0,
        lng: currentCli.longitude ?? 0,
      });
      setMapZoom(14);
    } else if (mapZoom > 12) {
      setMapZoom(12);
    }
  }, [currentCli]);

  // DEFINE STATE DE CONTROLE DA EXIBIÇÃO DO MAPA
  const [showMap, setShowMap] = useState<any>(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAUHxQUnO76uq2HBu2X6xzaLZPapIFv--0',
    libraries: ['drawing', 'places'],
  });

  useEffect(() => {
    if (isLoaded && !showMap) {
      setShowMap(isLoaded);
      reloadAllData();
    }
  }, [isLoaded]);

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  const [mapCenter, setMapCenter] = useState<any>({
    lat: -15.79630312741709,
    lng: -47.89262600823724,
  });
  // DEFINE STATE DE CONTROLE DO ZOOM ATUAL DO MAPA
  const [mapZoom, setMapZoom] = useState<number>(8);

  useEffect(() => {
    setCodEmpresa(codEmpresaParam ? parseInt(codEmpresaParam) : 0);
    SetCodMapa(codMapaParam ? parseInt(codMapaParam) : 0);
  }, [codEmpresaParam, codMapaParam, codEmpresa, codmapa]);

  useEffect(() => {
    handleCliMarkers();
    if (!isClientsWithoutSectorLoaded && setores[0] && setores[0].CODSETOR == -1 && isSemSetorSelected()) {
      handleGetCliWithoutSector();
      setLoader({
        show: true,
        text: 'Por favor aguarde...',
      });
    }
    if (selectedMarker) {
      const newSelectedMarker: any = clientes.filter((cli: any) => cli.codCli == selectedMarker.codCli);
      setSelectedMarker(
        newSelectedMarker[0] && newSelectedMarker[0].codCli
          ? newSelectedMarker[0]
          : newSelectedMarker.codCli
          ? newSelectedMarker
          : null,
      );
    }
  }, [clientes, selectedFilter, currentSetor]);

  const handleCliMarkers = () => {
    if (currentSetor && currentSetor.setores) {
      if (clientes.length > 0) {
        for (let i = 0; i < clientes.length; i++) {
          let cliCoordData: any = clientes[i];
          if (clientes[0] && +cliCoordData.latitude !== 0 && +cliCoordData.longitude !== 0) {
            const coords = validateCoordinates({ lat: +cliCoordData.latitude, lng: +cliCoordData.longitude });
            if (coords.isValid) {
              setMapCenter(coords.coord);
              break;
            }
          }
        }

        let markersCli: any = clientes;
        if (currentSetor && currentSetor.setores) {
          let markersCliFilter = [];
          for (let i = 0; i < currentSetor.setores.length; i++) {
            clientes.map((cli: any) => {
              if (currentSetor.setores[i].codSetor == -1 && !cli.codSetor) {
                markersCliFilter.push(cli);
              } else if (cli.codSetor === currentSetor.setores[i].codSetor) {
                markersCliFilter.push(cli);
              }
            });
          }
          markersCli = markersCliFilter;

          markersCli.length > 0 && setMapCenter({ lat: +markersCli[0].latitude, lng: +markersCli[0].longitude });
          if (markersCli.length > 0) {
            markersCli = markersCli.reverse();
            for (let i = 0; i < markersCli.length; i++) {
              let cliCoordData: any = markersCli[i];
              if (markersCli[0] && +cliCoordData.latitude !== 0 && +cliCoordData.longitude !== 0) {
                setMapCenter({ lat: +cliCoordData.latitude, lng: +cliCoordData.longitude });
                break;
              }
            }
          }
        }

        // VALIDA FILTRO DE SETORES ATIVOS / INATIVOS / TODOS
        if (selectedFilter.code == 'I') {
          markersCli = markersCli.filter((cli: any) => cli.statusSetor == 'N');
        }

        let showNoSectorCli = false;
        if (currentSetor && currentSetor.setores) {
          for (let s = 0; s < currentSetor.setores.length; s++) {
            if (currentSetor.setores[s].codSetor == -1) {
              showNoSectorCli = true;
            }
          }
        }

        markersCli = markersCli?.map((cli: IMapaClient) => {
          if (cli.codSetor == 0 && !showNoSectorCli) {
            return;
          } else {
          return (
            <Marker
              icon={{
                url: cli.icone,
              }}
              key={Math.random()}
              position={{ lat: +cli.latitude, lng: +cli.longitude }}
              onClick={() => {
                setSelectedMarker(cli);
              }}
            />
          );
          }
        });
        setCliMarkers(markersCli);
      } else {
        if (polygonos.length > 0 && polygonos[0].latlngs && polygonos[0].latlngs[0]) {
          setMapCenter(polygonos[0].latlngs[0]);
        }
        setCliMarkers([]);
      }
    } else {
      setCliMarkers([]);
    }
    setLoader({
      show: false,
      text: '',
    });
  };

  // DEFINE STATE COM LISTA DE CLIENTES PARA OS MARKERS
  const [cliMarkers, setCliMarkers] = useState<any>(null);
  // DEFINE STATE QUE ARMAZENA COORDENADAS DO NOVO POLYGONO
  const [newPolyCoords, setNewPolyCoords] = useState<any>();
  // DEFINE STATE DE CONTROLE DA EXIBIÇÃO DA MODAL DE VINCULO DE SETORES
  const [showVinculoSetor, setShowVinculoSetor] = useState(false);

  // DEFINE STATE QUE CONTROLA O INCIO DO EVENTO DE DESENHO DE POLYGONO
  const [drawingMode, setDrawingMode] = useState(null);
  // REFERENCIA AO GERENCIADOR DE DESENHO
  const drawingManagerRef = useRef(null);
  // DEFINE STATE QUE CONTROLA EDIÇÃO DOS POLYGONOS
  const [editPoly, setEditPoly] = useState(false);

  // FUNÇÃO QUE FORMATA DADOS DE COORDENADAS NO OBJETO CORRETO
  const formatLatLong = (poly: any) => {
    // ARMAZENA DADOS DAS COORDENADAS DO ARRAY RECEBIDO
    const polygonCoords = poly.getPath().getArray();

    // DEFINE INSTÂNCIA LOCLA PARA RECEBER LATLONGS TRATADAS
    const coordinates = [];

    // MAPEIA E TRATA COORDENADAS DO ARRAY
    for (let i = 0; i < polygonCoords.length; i++) {
      coordinates.push(polygonCoords[i].toJSON());
    }

    // RETORNA ARRAY TRATADO
    return coordinates;
  };

  // FUNÇÃO QUE INICIA O DESENHO DE UM POLY
  const handlePolyStart = () => {
    // ATUALIZA STATE COM O TIPO DESENHO
    if (drawingMode) {
      setDrawingMode(null);
    } else {
      setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    }
  };

  // CALLBACK FUNCTION PARA QUANDO O USUÁRIO FINALIZAR A CRIAÇÃO DE UM POLYGONO
  const handlePolygonComplete = (polygon: any) => {
    let coordinates = formatLatLong(polygon);
    polygon.setMap(null);
    setNewPolyCoords(coordinates);
    setShowVinculoSetor(true);
  };

  // ARRAY DE REFERÊNCIA DOS POLYGONOS
  const polygonRefs = useRef([]);
  // FUNÇÃO QUE ATRIBUI REFERÊNCIA AOS POLYGONOS
  const savePolygonRef = (index: any, ref: any) => {
    polygonRefs.current[index] = ref;
  };

  // DEFINE STATE DE CONTROLE DO POLYGONO SELECIONADO
  const [actualPoly, setActualPoly] = useState(null);
  // DEFINE STATE DO POLYGONO A SER EDITADO
  const [toEditPoly, setToEditPoly] = useState(null);
  // DEFINE STATE DE INDEX DO POLYGONO A SER EDITADO
  const [toEditPolyIndex, setToEditPolyIndex] = useState(null);

  // FUNÇÃO QUE TRATA A EDIÇÃO DO POLYGONO
  async function handlePolygonEdit() {
    let newPoly = formatLatLong(polygonRefs.current[toEditPolyIndex].state.polygon);
    // DEFINE VLAOR LOCAL COM LISTA DE POLYGONOS SEM O POLYGONO A SER EDITADO
    const polyList = polygonos?.filter((polygonData: any) => polygonData.setor !== toEditPoly.setor);

    // MAPEIA CLIENTES DENTRO DO POLYGONO
    let cliInPoly = await ValidaCliPoly(clientes, newPoly);

    // VALIDA INTERPOLAÇÃO DO SETOR EDITADO COM OS DEMAIS POLYGONOS
    polyInterpol(newPoly, polyList)
      .then((res: any) => {
        if (res) {
          toast.error('Área com interpolação!');
          return;
        } else {
          //DEFINE VALOR PARA DADOS A SEREM ENVIADOS PARA O SERVIDOR
          const newPolyData = {
            codArea: toEditPoly.codSetor,
            codSetor: toEditPoly.codSetor,
            codFilial: 1,
            codEmpresa: codEmpresa,
            latlngs: JSON.stringify(newPoly),
            clientes: cliInPoly.filter((cli: any) => cli.inclusaoManual === 'N'),
          };

          editPolyFunction([newPolyData])
            .then((res: any) => {
              reloadAllData();
              toast.success('Sucesso na edição da área!');

              setToEditPoly(null);
            })
            .catch((err: any) => {
              console.error(err);
              toast.error('Erro ao processar edição da área!');
              cancelPolyEdit();
            });
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  const cancelPolyEdit = () => {
    setToEditPolyIndex(null);
    setToEditPoly(null);
  };

  useEffect(() => {
    setTitlePage(TITLE_PAGE.CLIENT_SECTORS);
  }, [titlePage]);

  return (
    <S.MapMainBox>
      {/* MENU LATERAL */}
      <MapSidebar
        codMapaParam={codMapaParam}
        codEmpresaParam={codEmpresaParam}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      {/* LOADING DO MAPA */}
      <LoadCliMap />
      {/* MODAL DE VINCULO DE SETOR */}
      <VinculoSetorModal
        polygonos={polygonos}
        clientes={clientes}
        CreatePoly={CreatePoly}
        coordinates={newPolyCoords}
        showVinculoSetor={showVinculoSetor}
        setShowVinculoSetor={setShowVinculoSetor}
      />
      {/* MODAL DE EDIÇÃO DE SETOR */}
      <EditSetor />
      {/* MODAL DE DADOS DO POLYGONO */}
      <PolyData clickPoly={actualPoly} setClickPoly={setActualPoly} setToEditPoly={setToEditPoly} />
      {/* BOX DE EDIÇÃO DO POLYGONO */}
      <PolyEditBox poly={toEditPoly} handlePolygonEdit={handlePolygonEdit} cancelPolyEdit={cancelPolyEdit} />

      {showMap && (
        <GoogleMap
          mapContainerClassName="map-container"
          center={mapCenter}
          zoom={mapZoom}
          options={{
            streetViewControl: true,
            streetViewControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            fullscreenControl: false,
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            mapTypeControl: false,
            mapTypeControlOptions: {
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
          }}
        >
          {/* HEADER DO MAPA */}
          <MapHeader
            startPoly={handlePolyStart}
            editPoly={editPoly}
            setEditPoly={setEditPoly}
            drawingMode={drawingMode}
          />
          {/* LISTA DE CLIENTES */}
          <CliList
            codEmpresaParam={codEmpresaParam}
            codMapaParam={codMapaParam}
            setShowEditAddressModal={setShowEditAddressModal}
            setSelectedMarker={setSelectedMarker}
          />

          {/* CLIENTES NO MAPA */}
          {cliMarkers && <div id={'markersBox'}>{cliMarkers}</div>}

          {/* POPUP DO MARKER */}
          {selectedMarker && (
            <InfoWindow
              position={{ lat: +selectedMarker.latitude, lng: +selectedMarker.longitude }}
              onCloseClick={handleInfoWindowClose}
              options={{
                pixelOffset: new window.google.maps.Size(0, -15),
              }}
            >
              <MarkerMapSectorsClients
                codCompany={codEmpresaParam}
                codMap={codMapaParam}
                selectedMarker={selectedMarker}
                handleEditAddress={() => setShowEditAddressModal(!showEditAddressModal)}
                handleEditBond={() => setShowEditVinculoModal(!showEditVinculoModal)}
                handleRemoveCliSector={() => handleRemoveCliSector(codEmpresaParam)}
                selectedFilter={selectedFilter}
              />
            </InfoWindow>
          )}

          <DrawingManager
            drawingMode={drawingMode}
            ref={drawingManagerRef}
            onPolygonComplete={handlePolygonComplete}
            options={{
              drawingControl: false,
              drawingControlOptions: {
                position: window.google.maps.ControlPosition.LEFT_CENTER,
                drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
              },
            }}
            onLoad={(drawingManager) => (drawingManagerRef.current = drawingManager)}
          />

          {polygonos &&
            polygonos?.map((poly: any, index: number) => {
              return (
                <Polygon
                  key={Math.random()}
                  path={poly.latlngs}
                  ref={(ref) => savePolygonRef(index, ref)}
                  options={{
                    fillColor: currentSetor
                      ? currentSetor.codSetor === poly.codSetor
                        ? '#' + poly.cor
                        : 'transparent'
                      : '#' + poly.cor,
                    fillOpacity: 0.45,
                    strokeColor: '#' + poly.cor,
                    strokeOpacity: 1,
                    strokeWeight: 1,
                    editable: toEditPoly && toEditPoly.setor === poly.setor,
                  }}
                  onClick={(e: any) => {
                    setActualPoly(poly);
                    setToEditPolyIndex(index);
                  }}
                />
              );
            })}
        </GoogleMap>
      )}

      {showEditAddressModal && (
        <CliAddressModal
          isAdress={true}
          showModal={showEditAddressModal}
          setShowModal={setShowEditAddressModal}
          cliData={selectedMarker}
          codMapaParam={codMapaParam}
          codEmpresaParam={codEmpresaParam}
        />
      )}
      <VinculoCliSetorModal
        setShowVinculoSetor={setShowEditVinculoModal}
        showVinculoSetor={showEditVinculoModal}
        cliente={selectedMarker}
        codEmpresa={+codEmpresaParam}
      />
    </S.MapMainBox>
  );
}
