import * as S from './styles';
import { FaInfo, FaRuler, FaSearch } from 'react-icons/fa';
import { BsCalendar2WeekFill, BsFillPersonFill, BsPersonBadgeFill } from 'react-icons/bs';
import { IoMdPin } from 'react-icons/io';
import useMediaQuery from 'hooks/useMediaQuery';
import { Image } from 'primereact/image';

export default function AnaliseCards({ data, setSelectedCli, selectedCli, setShowMenu }: any) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const handleRowClick = (rowData: any) => {
    if (selectedCli && selectedCli.codcli == rowData.codcli) {
      setSelectedCli(null);
    } else {
      !isWebScreen && setShowMenu(false);
      setSelectedCli(rowData ?? null);
    }
  };

  const DataCard = ({ data }: any) => {
    return (
      <S.DataCard
        status={data.status}
        selected={selectedCli && data.codcli === selectedCli.codcli}
        onClick={() => handleRowClick(data)}
      >
        <S.CardImage>
          <S.CardTitle status={data.status}>
            {data.status == 'L' ? 'LIBERADO' : data.status == 'R' ? 'REJEITADO' : 'ANÁLISE'}
          </S.CardTitle>
          <Image src={`${process.env.REACT_APP_BASE_URL}/fotos_exemplo_formulario/sem-imagem.jpeg`} alt="Image" />
        </S.CardImage>

        <S.CardInfo>
          <S.CardRow>
            <S.CardContent status={data.status}>
              <BsFillPersonFill />[{data.codcli}] {data.nomeCliente}
            </S.CardContent>
          </S.CardRow>

          <S.CardRow>
            <S.CardContent status={data.status}>
              <IoMdPin /> {data.endLogradouro}, {data.endNumero} - {data.endBairro}. {data.endCidade} - {data.endUf}
            </S.CardContent>
          </S.CardRow>

          <S.CardRow>
            <S.CardContent status={data.status}>
              <BsPersonBadgeFill />[{data.codusur}] {data.nomeVendedor}
            </S.CardContent>
          </S.CardRow>

          <S.CardRow>
            <S.CardContent status={data.status}>
              <FaRuler />
              {data.distancia}
            </S.CardContent>

            <S.CardContent status={data.status}>
              <BsCalendar2WeekFill />
              {new Date(data.dtinclusao).toLocaleDateString('pt-BR')}
            </S.CardContent>
          </S.CardRow>
          {data.status != 'A' && (
            <S.CardRow>
              <S.CardContent status={data.status}>
                <FaSearch />
                Func. Análise:{' '}
                {data.codfuncanalise && data.nomefuncanalise ? `[${data.codfuncanalise}] ${data.nomefuncanalise}` : ''}
              </S.CardContent>
            </S.CardRow>
          )}
          {data.status != 'A' && (
            <S.CardRow>
              <S.CardContent status={data.status}>
                <FaInfo />
                Obs.: {data.observacao}
              </S.CardContent>
            </S.CardRow>
          )}
        </S.CardInfo>

        {/* <S.CardSubTitle></S.CardSubTitle> */}
      </S.DataCard>
    );
  };

  return (
    <S.AnaliseTableMainBox>
      <S.DataCardsList>
        {data.length > 0
          ? data?.map((data: any) => <DataCard key={Math.random()} data={data} />)
          : 'Nenhum dado encontrado :/'}
      </S.DataCardsList>
    </S.AnaliseTableMainBox>
  );
}
