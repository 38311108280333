import { CiMap, CiViewTable } from "react-icons/ci";
import { useAnaliseRoteiro } from "../../analiseRoteiro.context";
import * as S from './mapToggle.styles';


export default function MapToggle(){
    const { viewMode, setViewMode} = useAnaliseRoteiro();

    return <S.HeaderMapToggle>
    <S.HeaderMapToggleButton
      active={viewMode == 'map'}
      onClick={() => {
        setViewMode('map');
      }}
    >
      <CiMap /> Mapa
    </S.HeaderMapToggleButton>
    <S.HeaderMapToggleButton
      active={viewMode == 'list'}
      onClick={() => {
        setViewMode('list');
      }}
    >
      <CiViewTable /> Grid
    </S.HeaderMapToggleButton>
  </S.HeaderMapToggle>
}