import { UseFindByRoleManagers } from 'hooks/useManagers/useFindByRoleManagers';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';

interface IFilterByManagerCompanyParams {
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  codCompany: number | string;
  codUser: number | string;
}

export const FilterByManagerCompany = ({ control, setValue, codCompany, codUser }: IFilterByManagerCompanyParams) => {
  const { managersByRoleOptions, refetchManagersByRole } = UseFindByRoleManagers({
    codCompany: Number(codCompany),
    codUser: Number(codUser),
  });

  useEffect(() => {
    refetchManagersByRole();
  }, [codUser, codCompany]);

  return (
    <>
      <Controller
        name="codManager"
        rules={{
          required: true,
        }}
        control={control}
        render={({ field }) => (
          <Dropdown
            placeholder="Selecione um Gerente"
            value={field.value}
            options={managersByRoleOptions}
            onChange={(e) => setValue('codManager', e.value)}
            emptyMessage="Nenhum Gerente Encontrado :/"
            filter
            showClear
          />
        )}
      />
    </>
  );
};
