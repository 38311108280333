import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { StringBodyTemplate, styleHeaderCenter, textBodyTemplate } from 'components/datatable/templates';
import { IGroupClient } from 'client/interfaces/groupClient';
import { useGroupClientsFiliais, useGroupClientsFilial } from 'client/hooks/groupClients';
import { EditGroupClients } from '../../EditGroupClients';
import { DeleteGroupClient } from '../../DeleteGroupClient';
import { useListGroupClients } from 'hooks/useGroupClients/useListGroupClients';
import VmButton from 'components/VmButton/VmButton';
import { AiFillEdit } from 'react-icons/ai';
import { useGroupClientMember } from 'storesZustand/groupClientMember';
import { BsPeopleFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

interface ITableGroupClients {
  codEmpresa: string;
  codFilial: any;
  active: any;
  selectedClient: number;
  filterNameGroup: string;
}

export const TableGroupClients = ({
  codEmpresa,
  codFilial,
  active,
  selectedClient,
  filterNameGroup,
}: ITableGroupClients) => {
  const {
    isOpenModalEdit,
    setIsOpenModalEdit,
    isOpenModalDelete,
    setIsOpenModalDelete,
    rowGroupClient,
    setRowGroupClient,
  } = useListGroupClients();

  const {
    data: groupClientsFilial,
    isLoading: isLoadingFilial,
    refetch: refetchFilial,
  } = useGroupClientsFiliais(codEmpresa, codFilial ?? 0);

  const setGroupInfo = useGroupClientMember((state) => state.setGroupInfo);

  const navigate = useNavigate();

  const handleEditGroup = (rowData: IGroupClient) => {
    const handleOpenModal = (rowData: IGroupClient) => {
      setRowGroupClient(rowData);
      setIsOpenModalEdit(!isOpenModalEdit);
    };

    return (
      <VmButton
        options={{
          icon: <AiFillEdit />,
          iconPosition: 'left',
          rounded: true,
          tooltip: {
            show: true,
            text: 'Editar',
            position: 'right',
          },
          style: 'btn-light',
        }}
        style={{ marginRight: '10px' }}
        onClick={() => {
          handleOpenModal(rowData);
        }}
      />
    );
  };

  const handleAddContent = async (rowData: IGroupClient) => {
    await setGroupInfo(rowData);

    return navigate(`/grupo-clientes/listar/membros/${codEmpresa}`);
  };

  const handleAddMemberToGroup = (rowData: IGroupClient) => {
    return (
      <VmButton
        options={{
          icon: (
            <>
              +<BsPeopleFill />
            </>
          ),
          iconPosition: 'left',
          rounded: true,
          tooltip: {
            show: true,
            text: 'Listar/Vincular Clientes',
            position: 'right',
          },
          style: 'btn-light',
        }}
        style={{ marginRight: '10px' }}
        onClick={() => {
          handleAddContent(rowData);
        }}
      />
    );
  };

  const filtered = active === 'T' ? groupClientsFilial : groupClientsFilial?.filter((grupo) => grupo?.ativo === active);
  const filteredByName = filtered?.filter((grupo) =>
    grupo?.nomeGrupo.toLowerCase().includes(filterNameGroup.toLowerCase()),
  );

  // Causando loop de requisições
  // useEffect(() => {
  //   refetchFilial();
  // }, [selectedClient, codFilial, codEmpresa, active]);

  return (
    <>
      <DataTable
        value={filteredByName}
        loading={isLoadingFilial}
        header=""
        dataKey="id"
        editMode="cell"
        emptyMessage="Nenhum dado encontrado"
        paginator
        rowsPerPageOptions={[10, 25, 50]}
        rows={10}
      >
        <Column
          header="Nome Grupo"
          field="nomeGrupo"
          body={(rowData) => textBodyTemplate(rowData?.nomeGrupo, rowData?.codgrupo)}
        />
        <Column field="qtdClientesGrupo" header="Qtd. Clientes" headerStyle={styleHeaderCenter} />
        <Column field="exibirFv" header="Exibir FV" body={(rowData) => StringBodyTemplate(rowData.exibirFv)} />
        <Column header="Código Empresa" field="codempresa" />
        <Column header="Código Filial" field="codfilial" />
        <Column header="Ativo/Inativo" field="ativo" body={(rowData) => StringBodyTemplate(rowData.ativo)} />
        <Column header="" field="codfilial" body={(rowData) => handleAddMemberToGroup(rowData)} />
        <Column header="" field="codfilial" body={(rowData) => handleEditGroup(rowData)} />
      </DataTable>

      {isOpenModalEdit && (
        <EditGroupClients
          rowGroupClient={rowGroupClient}
          isOpenModalEdit={isOpenModalEdit}
          setIsOpenModalEdit={setIsOpenModalEdit}
        />
      )}

      <DeleteGroupClient
        rowGroupClient={rowGroupClient}
        isOpenModalDelete={isOpenModalDelete}
        setIsOpenModalDelete={setIsOpenModalDelete}
        refetch={refetchFilial}
      />
    </>
  );
};
