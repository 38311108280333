const supervisorList = [
  {
    codSupervisor: 101,
    supervisor: 'John Doe',
    vlDistSuperv: 5000,
    vlDistVendedor: 3000,
    vlAplic: 0,
    vlSaldo: 2000,
  },
  {
    codSupervisor: 102,
    supervisor: 'Jane Smith',
    vlDistSuperv: 7500,
    vlDistVendedor: 5500,
    vlAplic: 0,
    vlSaldo: 2000,
  },
  {
    codSupervisor: 103,
    supervisor: 'Michael Brown',
    vlDistSuperv: 6000,
    vlDistVendedor: 4500,
    vlAplic: 0,
    vlSaldo: 1500,
  },
];

export default supervisorList;
