import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './regrasImagesModal.styles';
import { RegrasImagesModalProps } from 'client/interfaces';
import { Dialog } from 'primereact/dialog';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import VmButton from 'components/VmButton/VmButton';
import { FaTrash } from 'react-icons/fa';
import { postDeleteRegraImage, postRegraImages } from 'client/api/regraComercial';
import ReactCrop, { type Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

export default function RegrasImagesModal({
  showRegrasImagesModal,
  setShowRegrasImagesModal,
  codEmpresa,
  handleGetRegrasComerciais,
  regra,
}: RegrasImagesModalProps) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [imagesForm, setImagesForm] = useState({
    img1: regra ? regra.img1 : null,
    img1Url: regra ? regra.img1 : null,
    img2: regra ? regra.img2 : null,
    img2Url: regra ? regra.img2 : null,
    img3: regra ? regra.img3 : null,
    img3Url: regra ? regra.img3 : null,
    codEmpresa: regra ? regra.codEmpresa : 0,
    codRegra: regra ? regra.codregra : 0,
  });

  useEffect(() => {
    setImagesForm({
      img1: regra ? regra.img1 : null,
      img1Url: regra && regra.img1 ? `${process.env.REACT_APP_BASE_URL}/fotos_campanhas/${regra.img1}` : null,
      img2: regra ? regra.img2 : null,
      img2Url: regra && regra.img2 ? `${process.env.REACT_APP_BASE_URL}/fotos_campanhas/${regra.img2}` : null,
      img3: regra ? regra.img3 : null,
      img3Url: regra && regra.img3 ? `${process.env.REACT_APP_BASE_URL}/fotos_campanhas/${regra.img3}` : null,
      codEmpresa: regra ? regra.codEmpresa : 0,
      codRegra: regra ? regra.codregra : 0,
    });
  }, [regra]);

  const handleImageSelect = (file: any, imageName: string) => {
    if (file.files && file.files[0]) {
      let img = file.files[0];
      if (
        file.files[0].type != 'image/png' &&
        file.files[0].type != 'image/webp' &&
        file.files[0].type != 'image/jpg' &&
        file.files[0].type != 'image/jpeg'
      ) {
        toast.error('Arquivo inválido!');
        return;
      }
      setImagesForm((prevState: any) => {
        prevState[imageName + 'Url'] = URL.createObjectURL(img) ?? img.name ?? '';
        prevState[imageName] = img;
        return { ...prevState };
      });
    }
  };

  const handleInsertImages = () => {
    if (typeof imagesForm.img1 != 'string' && !completedCrop) {
      toast.error('Ajuste da imagem de capa não finalizado');
      return;
    }
    postRegraImages(imagesForm)
      .then((res: any) => {
        if (res.succeeded) {
          toast.success('Sucesso ao gravar imagens');
          setShowRegrasImagesModal(false);
          handleGetRegrasComerciais();
        }
      })
      .catch((err: any) => {
        console.log('err :', err);
      });
  };

  const handleDeleteImage = (imageName: string, image: string, index: number) => {
    postDeleteRegraImage(imageName, imagesForm.codEmpresa, imagesForm.codRegra, index)
      .then((res: any) => {
        if (res.succeeded) {
          toast.success('Imagem Removida');
          handleGetRegrasComerciais();
          setImagesForm((prevState: any) => {
            prevState[image + 'Url'] = null;
            prevState[image] = null;
            return { ...prevState };
          });
        }
      })
      .catch((err: any) => {
        console.log('err :', err);
      });
  };

  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    x: 0,
    y: 0,
    width: 359,
    height: 162,
  });

  const [aspect, setAspect] = useState<number | undefined>(2 / 1);
  const [completedCrop, setCompletedCrop] = useState<Crop>(null);
  const imageRef = useRef<any>(null);

  useEffect(() => {
    if (imageRef.current) {
      generateCroppedImage();
    }
  }, [completedCrop, imageRef.current]);

  const generateCroppedImage = () => {
    if (!completedCrop || !imageRef.current) {
      return;
    }

    const image = imageRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = completedCrop.width * scaleX;
    canvas.height = completedCrop.height * scaleY;

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    );

    canvas.toBlob(
      (blob) => {
        if (blob) {
          const file = new File([blob], (regra ? `${regra.codregra}_01.png`: 'cropped-image.png'), { type: 'image/png' });
          setImagesForm((prevState: any) => {
            return { ...prevState, img1: file };
          });
        }
      },
      'image/png',
      1,
    );
  };

  return (
    <Dialog
      header={regra ? `Imagens da regra [${regra.codregra}] - ${regra.nomeregra}` : 'Imagens da regra'}
      visible={showRegrasImagesModal}
      style={{ width: isWebScreen ? '45vw' : '90%' }}
      onHide={() => {
        setShowRegrasImagesModal(false);
      }}
    >
      <S.RegrasImagesModalMainBox>
        <S.ImagemCard image={imagesForm.img1Url}>
          <S.FloatCenterAddImage
            onClick={() => {
              const imageIinput = document.getElementById('newCampanhaImagem1');
              imageIinput.click();
            }}
          />
          {imagesForm.img1 && typeof imagesForm.img1 == 'object' ? (
            <ReactCrop crop={crop} onChange={(c) => setCrop(c)} aspect={aspect} onComplete={(c) => setCompletedCrop(c)}>
              <img ref={imageRef} src={imagesForm.img1Url ? imagesForm.img1Url : ''} alt={imagesForm.img1Url ?? ''} />
            </ReactCrop>
          ) : (
            <img ref={imageRef} src={imagesForm.img1Url ? imagesForm.img1Url+`?t=${new Date().getTime()}_${Math.random()}` : ''} alt={imagesForm.img1Url ?? ''} />
          )}
          <S.RegrasImagesLabel>Tamanho ideal para a imagem: 359x162px</S.RegrasImagesLabel>
          <input
            type="file"
            id="newCampanhaImagem1"
            name="newCampanhaImagem1"
            accept="image/png, image/webp, image/jpeg, image/jpg"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleImageSelect(e.target, 'img1');
            }}
          />
          <S.FloatIcons>
            <FaTrash
              onClick={() => {
                setCompletedCrop(null);
                setImagesForm((prevState: any) => {
                  return { ...prevState, img1: regra ? regra.img1 : null };
                });
                imagesForm.img1 && handleDeleteImage(imagesForm.img1, 'img1', 1);
              }}
            />
          </S.FloatIcons>
        </S.ImagemCard>

        <S.ImagemCard image={imagesForm.img2Url}>
          <S.FloatCenterAddImage
            onClick={() => {
              const imageIinput = document.getElementById('newCampanhaImagem2');
              imageIinput.click();
            }}
          />
          <img src={imagesForm.img2Url ? imagesForm.img2Url : ''} alt={imagesForm.img2Url ?? ''} />
          <input
            type="file"
            id="newCampanhaImagem2"
            name="newCampanhaImagem2"
            accept="image/png, image/webp, image/jpeg, image/jpg"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleImageSelect(e.target, 'img2');
            }}
          />
          <S.FloatIcons>
            <FaTrash
              onClick={() => {
                imagesForm.img2 && handleDeleteImage(imagesForm.img2, 'img2', 2);
              }}
            />
          </S.FloatIcons>
        </S.ImagemCard>

        <S.ImagemCard image={imagesForm.img3Url}>
          <S.FloatCenterAddImage
            onClick={() => {
              const imageIinput = document.getElementById('newCampanhaImagem3');
              imageIinput.click();
            }}
          />
          <img src={imagesForm.img3Url ? imagesForm.img3Url : ''} alt={imagesForm.img3Url ?? ''} />
          <input
            type="file"
            id="newCampanhaImagem3"
            name="newCampanhaImagem3"
            accept="image/png, image/webp, image/jpeg, image/jpg"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleImageSelect(e.target, 'img3');
            }}
          />
          <S.FloatIcons>
            <FaTrash
              onClick={() => {
                imagesForm.img3 && handleDeleteImage(imagesForm.img3, 'img3', 3);
              }}
            />
          </S.FloatIcons>
        </S.ImagemCard>
      </S.RegrasImagesModalMainBox>

      <S.RegrasImagesModalFooterBox>
        {(imagesForm.img1 || imagesForm.img2 || imagesForm.img3) && (
          <VmButton
            options={{
              label: 'Salvar',
              style: 'btn-success',
            }}
            onClick={() => handleInsertImages()}
          />
        )}
      </S.RegrasImagesModalFooterBox>
    </Dialog>
  );
}
