const vendedorList = [
  {
    codVendedor: 201,
    vendedor: "Alice Johnson",
    codCli: 301,
    cliente: "ACME Corp",
    vlDistVendedor: 2000,
    vlAplic: 1500,
    vlSaldo: 500,
  },
  {
    codVendedor: 202,
    vendedor: "Bob White",
    codCli: 302,
    cliente: "Globex Inc",
    vlDistVendedor: 2500,
    vlAplic: 2000,
    vlSaldo: 500,
  },
  {
    codVendedor: 203,
    vendedor: "Charlie Davis",
    codCli: 303,
    cliente: "Umbrella Corp",
    vlDistVendedor: 3000,
    vlAplic: 2500,
    vlSaldo: 500,
  },
];

export default vendedorList;
