import { useMapsByUser } from 'client/hooks/mapa';
import { Mapa } from 'client/interfaces';
import { styleHeaderCenter } from 'components/datatable';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { classNames } from 'primereact/utils';

import * as S from '../style';
import VmButton from 'components/VmButton/VmButton';
import { AiFillEdit } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { ImMap2 } from 'react-icons/im';
import { useSectorsClientStore } from 'storesZustand/sectorsClients';
import { useEffect, useMemo } from 'react';
import { useMapSectorsStore } from 'storesZustand/mapSectorsStore';

interface IParamsListMapsByCompany {
  codCompany: number;
  codUser: number;
  status: string;
}

export const ListMapsByCompany = ({ codCompany, codUser, status }: IParamsListMapsByCompany) => {
  const { data: mapas, isLoading, isFetching } = useMapsByUser(codCompany, codUser);

  const { setCodUser, setCodCompanyUser } = useMapSectorsStore();

  const { setSelectedMap } = useSectorsClientStore();

  const navigate = useNavigate();

  const mapNameTemplate = (rowData: Mapa) => {
    return (
      <span>
        {rowData.nome.toUpperCase()} [{rowData.cod}]
      </span>
    );
  };

  const actionBodyTemplate = (rowData: Mapa) => {
    return (
      <S.TableButtonBox>
        <VmButton
          options={{
            icon: <AiFillEdit />,
            iconPosition: 'left',
            rounded: true,
            tooltip: {
              show: true,
              text: 'Editar',
              position: 'left',
            },
            style: 'btn-dark',
          }}
          onClick={() => navigate(`./${codCompany}/${rowData.cod}`)}
        />
      </S.TableButtonBox>
    );
  };

  const mapLinkTemplate = (rowData: Mapa) => {
    return (
      <S.TableButtonBox>
        <VmButton
          options={{
            icon: <ImMap2 />,
            iconPosition: 'left',
            rounded: true,
            tooltip: {
              show: true,
              text: 'Visualizar',
              position: 'right',
            },
            style: 'btn-success',
          }}
          onClick={() => {
            setSelectedMap(rowData);
            window.location.pathname = `/regioes/${rowData.codEmpresa}/${codUser}/${rowData.cod}`;
          }}
        />
      </S.TableButtonBox>
    );
  };

  const filteredItems = useMemo(() => {
    if (status === 'S') {
      return mapas?.filter((item) => item?.ativo);
    } else if (status === 'N') {
      return mapas?.filter((item) => !item?.ativo);
    } else {
      return mapas;
    }
  }, [mapas, status]);

  useEffect(() => {
    setCodUser(codUser);
    setCodCompanyUser(codCompany);
  }, [codUser, codCompany]);

  return (
    <DataTable
      value={filteredItems}
      loading={isLoading || isFetching}
      paginator
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      rows={10}
      rowsPerPageOptions={[10, 25, 50]}
      emptyMessage="Nenhum Mapa Encontrado"
      currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
      responsiveLayout="scroll"
      scrollHeight="calc(80vh - 150px)"
    >
      <Column header="CodEmpresa" field="codEmpresa" sortable />
      <Column header="CodFilial" field="codFilial" sortable headerClassName="testHead" />
      <Column header="Nome" field="nome" body={(rowData: Mapa) => mapNameTemplate(rowData)} sortable />
      <Column
        header="Ativo"
        headerStyle={styleHeaderCenter}
        field="ativo"
        body={(rowData: Mapa) => {
          return (
            <S.IconTable>
              <i
                className={classNames('pi', {
                  'true-icon pi-check-circle text-success': rowData.ativo,
                  'false-icon pi-times-circle text-danger': !rowData.ativo,
                })}
              />
            </S.IconTable>
          );
        }}
        sortable
        alignHeader="center"
        align="center"
      />
      <Column
        header="Mapa / Setor"
        body={(rowData: Mapa) => mapLinkTemplate(rowData)}
        align="center"
        alignHeader="center"
      />
      <Column
        header={'Editar'}
        body={(rowData: Mapa) => actionBodyTemplate(rowData)}
        align="center"
        alignHeader="center"
      />
    </DataTable>
  );
};
