import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Button } from 'primereact/button';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { confirmPopup, ConfirmPopup } from 'primereact/confirmpopup';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { useEffect, useRef, useState } from 'react';
import { BsCheck, BsDashLg, BsFillEyeFill, BsFillUnlockFill, BsPlusLg, BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { deleteMenusWithSub, saveMenusWithSub } from 'client/api';
import { useMenuWithSub, useUser } from 'client/hooks';
import { MenuSubDto, MenuWithSub, MenuWithSubUpdate } from 'client/interfaces';
import ButtonComponent from 'components/Button';
import ClickIcon from 'components/ClickIcon';
import { booleanEditor, numberEditor, textEditor } from 'components/datatable';
import VmButton from 'components/VmButton/VmButton';
import useMediaQuery from 'hooks/useMediaQuery';
import { useModalAddFunctionalityToCompany } from 'storesZustand/modalAddFunctionalityToCompany';

import ModalAddFunctionalityToCompany from './modalsMenu/ModalAddFunctionalityToCompany';
import * as S from './styles';
import SubItemMenu from './SubItemMenu';

const publicoTemplate = (data: MenuWithSub) =>
  data.publico ? <Tag value="Sim" severity="success" /> : <Tag value="Não" severity="danger" />;

const getNextCod = (data: MenuWithSub[]) => {
  let index = data.length;
  while (data.some((d) => d.codMenu === ++index));
  return index;
};

const validateData = (menus: MenuWithSub[]) => {
  const duplicateCods = menus.map((v) => v.codMenu).filter((v, i, vIds) => vIds.indexOf(v) !== i);

  if (duplicateCods.length) {
    throw new Error(`codMenu ${duplicateCods[0]} duplicado!`);
  }

  menus.forEach((menu) => {
    if (!menu.codMenu) {
      throw new Error(`codMenu do módulo ${menu.modulo} não pode ser vazio!`);
    }
    if (!menu.grupo) {
      throw new Error(`grupo do módulo ${menu.modulo} não pode ser vazio!`);
    }
    if (!menu.modulo) {
      throw new Error(`modulo do codMenu ${menu.codMenu} não pode ser vazio!`);
    }

    if (menu.menuSub.some((s) => !s.nomePermissao)) {
      throw new Error(`uma das permissões do menu ${menu.modulo} está vazio!`);
    }
  });
};

export interface IRowDataMenu {
  codMenu: number;
  codigo: number;
  grupo: string;
  id: number;
  modulo: string;
  nomeMenu: string;
  publico: boolean;
}

const ItensMenu = () => {
  const { data: user } = useUser();
  const { data, refetch, isFetching } = useMenuWithSub(user?.codEmpresa);
  const [menus, setMenus] = useState<MenuWithSubUpdate[]>(data?.map((d) => ({ ...d, id: d.codMenu })) ?? []);
  const menusToRemove = useRef<MenuWithSub[]>([]);
  const [isTouched, setIsTouched] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const isWebScreen = useMediaQuery('(min-width: 1060px)');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows | undefined>(undefined);

  useEffect(() => {
    if (!isTouched) {
      setMenus(data?.map((d) => ({ ...d, id: d.codMenu })) ?? []);
    }
  }, [data]);

  const createNewSub = (codMenu: number) => {
    setIsTouched(true);
    const _menu: MenuWithSubUpdate[] = menus.map((m) =>
      m.codMenu === codMenu
        ? {
            ...m,
            menuSub: [
              ...m.menuSub,
              {
                codMenuSub: (m.menuSub.length + 1) * -1,
                codMenu,
                codSubMenu: m.menuSub.length + 1,
                nomePermissao: '',
              },
            ],
            id: m.codMenu,
            edited: true,
          }
        : m,
    );
    setMenus(_menu);
  };

  const removeSubItem = (menuSub: MenuSubDto) => {
    setIsTouched(true);
    const _menu = menus.map((m) =>
      m.codMenu === menuSub.codMenu
        ? { ...m, menuSub: m.menuSub.filter((s) => s.codMenuSub !== menuSub.codMenuSub), edited: true }
        : m,
    );
    setMenus(_menu);
  };

  const updateSubMenu = (codMenu: number) => {
    const _menu = menus.map((m) => (m.codMenu === codMenu ? { ...m, edited: true } : m));
    setMenus(_menu);
    setIsTouched(true);
  };

  const rowExpansionTemplate = (data: MenuWithSub) => (
    <SubItemMenu menu={data} createNew={createNewSub} removeItem={removeSubItem} setEdited={updateSubMenu} />
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onCellEditComplete = (e: any) => {
    const { rowData, newValue, field, originalEvent: event } = e;
    setIsTouched(true);

    switch (field) {
      case 'publico':
      case 'codigo':
        rowData[field] = newValue;
        rowData.edited = true;
        break;
      case 'codMenu':
        if (newValue !== '') {
          rowData[field] = newValue;
          rowData.edited = true;
        } else event.preventDefault();
        break;
      default:
        if (newValue.trim().length > 0) {
          rowData[field] = newValue;
          rowData.edited = true;
        } else event.preventDefault();
        break;
    }
  };

  const expandAll = () => {
    const _expandedRows: DataTableExpandedRows = {};
    menus?.forEach((p) => (_expandedRows[`${p.codMenu}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(undefined);
  };

  const createNew = () => {
    setIsTouched(true);
    const cod = getNextCod(menus);
    setMenus([
      ...menus,
      {
        codMenu: cod,
        id: cod,
        grupo: '',
        menuSub: [],
        modulo: '',
        nomeMenu: '',
        codigo: '',
        publico: false,
        edited: true,
      },
    ]);
  };

  const queryClient = useQueryClient();

  const saveChanges = async () => {
    try {
      setIsSaving(true);
      validateData(menus);
      if (menusToRemove.current.length > 0) {
        await deleteMenusWithSub(menusToRemove.current.map((m) => m.codMenu));
      }
      await saveMenusWithSub(menus.filter((m) => m.edited));
      queryClient.invalidateQueries({
        queryKey: ['menu-with-sub'],
      });
      toast.success('Menus salvos com sucesso!');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(`Erro ao salvar menus: ${error.response?.statusText as string}`);
      }
      toast.error(`${error as string}`);
    } finally {
      setIsSaving(false);
    }
  };

  const cancelChanges = async () => {
    const result = (await refetch()).data;
    setMenus(result?.map((d) => ({ ...d, id: d.codMenu })) ?? []);
    setIsTouched(false);
    menusToRemove.current = [];
  };

  const header = (
    <>
      {isWebScreen ? (
        <div className="d-flex">
          <div className="col-6 d-grid gap-2 d-md-flex">
            <ButtonComponent icon={<BsPlusLg />} text="Novo" onClick={createNew} />
            <ButtonComponent variant="secondary" icon={<BsPlusLg />} text="Expandir Tudo" onClick={expandAll} />
            <ButtonComponent variant="secondary" icon={<BsDashLg />} text="Agrupar Tudo" onClick={collapseAll} />
          </div>
          <div className="col-6 d-grid gap-2 d-md-flex justify-content-md-end">
            <ButtonComponent disabled={!isTouched} icon={<BsX />} text="Cancelar" onClick={cancelChanges} />
            <ButtonComponent
              loading={isSaving}
              disabled={!isTouched}
              icon={<BsCheck />}
              text="Salvar"
              onClick={saveChanges}
            />
          </div>
        </div>
      ) : (
        <S.ContainerHeader>
          <S.Row>
            <ButtonComponent icon={<BsPlusLg />} text="Novo" onClick={createNew} />
            <ButtonComponent variant="secondary" icon={<BsPlusLg />} text="Expandir" onClick={expandAll} />
            <ButtonComponent variant="secondary" icon={<BsDashLg />} text="Agrupar" onClick={collapseAll} />
          </S.Row>
          <S.RowItem>
            <VmButton
              options={{
                icon: <BsX />,
                label: 'Cancelar',
                iconPosition: 'left',
                rounded: false,
                style: 'btn-success',
              }}
              onClick={() => cancelChanges()}
            />
            <VmButton
              style={{ marginLeft: '10px' }}
              options={{
                icon: <BsCheck />,
                label: 'Salvar',
                iconPosition: 'left',
                rounded: false,
                style: 'btn-success',
              }}
              onClick={() => saveChanges()}
            />
          </S.RowItem>
        </S.ContainerHeader>
      )}
    </>
  );

  const removeItem = (data: MenuWithSub) => {
    setIsTouched(true);
    const _menu = menus.filter((m) => m.codMenu !== data.codMenu);
    setMenus(_menu);
    menusToRemove.current.push(data);
  };

  const confirmDeleteItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, data: MenuWithSub) => {
    confirmPopup({
      target: event.currentTarget,
      message: 'Tem certeza que deseja excluir esse menu e seus submenus?',
      icon: 'pi pi-info-circle',
      acceptClassName: 'p-button-danger',
      accept: () => removeItem(data),
    });
  };

  const deleteTemplate = (data: MenuWithSub) => (
    <Button
      type="button"
      icon="pi pi-trash"
      onClick={(e) => confirmDeleteItem(e, data)}
      className="p-button-rounded p-button-danger p-button-text"
    />
  );

  const [openModalAddFunctionalityMenu, setModalAddFunctionalityMenu] = useState(false);

  const [rowDataMenu, setRowDataMenu] = useState<IRowDataMenu>({
    codMenu: 0,
    id: 0,
    grupo: '',
    modulo: '',
    nomeMenu: '',
    codigo: 0,
    publico: false,
  });

  const { setStatus, setSelectedCompany } = useModalAddFunctionalityToCompany();

  function handleAddFunctionalityToCompany(rowData: IRowDataMenu) {
    setModalAddFunctionalityMenu(true);
    setRowDataMenu(rowData);
    setStatus('default');
    setSelectedCompany({
      codEmpresa: 0,
      cnpj: '',
      razaosocial: '',
      fantasia: '',
      caminhoLogo: '',
    });
  }

  const actionBodyTemplate = (handleAddFunctionalityToCompany: () => void) => {
    return <ClickIcon icon={<BsFillUnlockFill />} onClick={handleAddFunctionalityToCompany} variant="secondary" />;
  };

  const actionSeeFunctionalityToCompany = (handleSeeFunctionalityToCompany: () => void) => {
    return <ClickIcon icon={<BsFillEyeFill />} onClick={handleSeeFunctionalityToCompany} variant="secondary" />;
  };

  return (
    <S.Container>
      <DataTable
        value={menus}
        loading={isFetching}
        sortMode="single"
        sortField="grupo"
        sortOrder={1}
        editMode="cell"
        dataKey="codMenu"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        header={header}
        onSelectionChange={(e) => setRowDataMenu(e.value)}
      >
        <Column expander style={{ width: '3rem' }} />
        <Column
          field="codMenu"
          header="Código"
          editor={numberEditor}
          sortable
          onCellEditComplete={onCellEditComplete}
          headerStyle={{ width: '3rem' }}
        />
        <Column
          field="grupo"
          header="Grupo Menu"
          editor={textEditor}
          onCellEditComplete={onCellEditComplete}
          sortable
        />
        <Column field="nomeMenu" header="Descrição Menu" editor={textEditor} onCellEditComplete={onCellEditComplete} />
        <Column field="modulo" header="Modulo" editor={textEditor} onCellEditComplete={onCellEditComplete} sortable />
        <Column field="to" header="Path" editor={textEditor} onCellEditComplete={onCellEditComplete} sortable />
        <Column
          field="codigo"
          header="Código Menu"
          editor={(options: ColumnEditorOptions) => numberEditor(options, { maxLength: 4 })}
          onCellEditComplete={onCellEditComplete}
        />
        <Column
          field="publico"
          header="Público"
          headerStyle={{ width: '3rem' }}
          editor={booleanEditor}
          onCellEditComplete={onCellEditComplete}
          body={publicoTemplate}
          sortable
        />
        <Column headerStyle={{ width: '3rem' }} body={deleteTemplate} />
        <Column
          headerStyle={{ width: '3rem' }}
          body={(rowData: IRowDataMenu) => actionBodyTemplate(() => handleAddFunctionalityToCompany(rowData))}
        />
      </DataTable>
      <ConfirmPopup />
      <ModalAddFunctionalityToCompany
        setOpenModal={setModalAddFunctionalityMenu}
        openModal={openModalAddFunctionalityMenu}
        data={rowDataMenu}
      />
    </S.Container>
  );
};

export default ItensMenu;
