import VmButton from 'components/VmButton/VmButton';
import * as S from './styles';

import { useState } from 'react';

import AnaliseModal from '../analiseModal';
import Swal from 'sweetalert2';
import { editVisita } from 'client/api';
import { toast } from 'react-toastify';
import { useUser } from 'client/hooks';

export default function ActionBar({ selectedCli, setSelectedCli, handleGetMainData }: any) {
  const [showModal, setShowModal] = useState(false);

  const {data: user} = useUser();

  const handleLiberar = () => {
    setShowModal(true);
  };

  const handleRejeitar = (toUpdateDataObject: any) => {
    editVisita(toUpdateDataObject)
    .then((res: any) => {
      toast.success('Analise rejeitada!');
      setSelectedCli([]);
      handleGetMainData();
    })
    .catch((err: any) => {
      console.log('err :', err);
      if (err.response.data.message) {
        toast.error(err.response.data.message);
      }
    });
  };

  const handleRejeitarConfirmation = (updateCadastro: 'S' | 'N') => {
    let updateDataObject = {
      Codempresa: selectedCli.codempresa,
      CodVendedor: selectedCli.codusur,
      Codcli: selectedCli.codcli,
      Status: 'R',
      Observacao: 'Rejeitado pelo painel web Vmais',
      latitude: selectedCli.latitude ?? '0',
      longitude: selectedCli.longitude ?? '0',
      AtualizaCadastro: updateCadastro,
      Codfuncanalise: user.codUsuario,
      Nomefuncanalise: user.nome,
    };
    setShowModal(false);

    Swal.fire({
      title: 'Tem certeza que deseja rejeitar esta localização ?',
      icon: 'question',
      confirmButtonText: 'Sim, rejeitar',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleRejeitar(updateDataObject);
      }
    });
  };

  return (
    <S.ActionBarMainBox>
      <VmButton
        options={{
          label: 'Liberar',
          style: 'btn-success',
          size: 'btn-sm',
        }}
        onClick={() => handleLiberar()}
      />
      <VmButton
        options={{
          label: 'Rejeitar',
          style: 'btn-danger',
          size: 'btn-sm',
        }}
        onClick={() => handleRejeitarConfirmation('N')}
      />
      {showModal && (
        <AnaliseModal
          selectedCli={selectedCli}
          showModal={showModal}
          setShowModal={setShowModal}
          setSelectedCli={setSelectedCli}
          handleGetMainData={handleGetMainData}
        />
      )}
    </S.ActionBarMainBox>
  );
}
