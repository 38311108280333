import { useEffect, useState } from 'react';
import * as S from './dailyPaginator.styles';
import { BsFillGridFill, BsGrid } from 'react-icons/bs';
import { MdOutlineDoubleArrow } from 'react-icons/md';
import { TbCalendarStats } from 'react-icons/tb';
import { useAnaliseRoteiro } from '../../analiseRoteiro.context';
import { PositionsStateProps } from '../../analiseRoteiro.types';

interface IPonto {
  sequencia: number;
  codCli?: number;
  nomeCli?: string;
  lat: number;
  lng: number;
  diaSemana?: string;
  dtProxVisita?: string;
  cor?: string;
  freqVisita?: number;
  distancia?: number;
}

interface IPosition {
  latitudePonto: number;
  longitudePonto: number;
  descricaoPonto: string;
  codPonto: number;
  diaSemana: string;
  dtProxVisita: string;
  clientesDaRota: IPonto[];
  cor: string;
  mes: string;
  semana: string;
}

export default function RouterDailyPaginator() {
  const [selectedDay, setSelectedDay] = useState('');

  const { positions, pages, cores, handlePagination, setPositions, setSelectedMarker } = useAnaliseRoteiro();

  useEffect(() => {
    const localSelectedDay = window.sessionStorage.getItem('routerSelectedDay');
    if (localSelectedDay) {
      setSelectedDay(localSelectedDay);
    }
  }, []);

  const handleGetLocalSlectedDay = () => {
    const localSelectedDay = window.sessionStorage.getItem('routerSelectedDay');
    return localSelectedDay ?? '';
  };

  return (
    <S.PaginatorBox>
      <S.PaginatorButtonPrev active={pages.showPrev}>
        <MdOutlineDoubleArrow
          onClick={() => {
            if (pages.showPrev && positions.all) {
              handlePagination(true);
            }
          }}
        />
      </S.PaginatorButtonPrev>
      <S.PaginatorDayCardIcon
        onClick={(e) => {
          setPositions((prev: PositionsStateProps) => {
            return {
              ...prev,
              current: prev.all,
            };
          });
          setSelectedDay('');
          setSelectedMarker(null);
          window.sessionStorage.removeItem('routerSelectedDay');
          window.sessionStorage.removeItem('routerSelectedMarker');
        }}
      >
        {positions.all.filter((rout: any) => pages.current === rout.semana + '/' + rout.mes).length == 1 ||
        positions.current.length ==
          positions.all.length ? (
          <BsFillGridFill />
        ) : (
          <BsGrid />
        )}
      </S.PaginatorDayCardIcon>
      {positions.all
        .filter((rout: any) => pages.current === rout.semana + '/' + rout.mes)
        .map((position: IPosition) => (
          <S.PaginatorDayCard
            onClick={(e) => {
              setPositions((prev: PositionsStateProps) => {
                return {
                  ...prev,
                  current: [position],
                };
              });
              setSelectedDay(new Date(position.dtProxVisita).toLocaleDateString('pt-BR'));
              setSelectedMarker(null);
              window.sessionStorage.setItem(
                'routerSelectedDay',
                new Date(position.dtProxVisita).toLocaleDateString('pt-BR'),
              );
              window.sessionStorage.removeItem('routerSelectedMarker');
              setSelectedMarker(null);
            }}
            key={position.codPonto + position.diaSemana + position.dtProxVisita + position.latitudePonto}
          >
            <S.PaginatorDayCardBar style={{ backgroundColor: cores[position.diaSemana] }}>.</S.PaginatorDayCardBar>
            <S.PaginatorDayCardText
              active={new Date(position.dtProxVisita).toLocaleDateString('pt-BR') == handleGetLocalSlectedDay()}
            >
              <span>{position.diaSemana.slice(0, 3)}</span>
              <span>
                {new Date(position.dtProxVisita).getDate() > 9
                  ? new Date(position.dtProxVisita).getDate()
                  : '0' + new Date(position.dtProxVisita).getDate()}
              </span>
            </S.PaginatorDayCardText>
          </S.PaginatorDayCard>
        ))}
      <S.PaginatorButtonNext active={pages.showNext}>
        <MdOutlineDoubleArrow
          onClick={() => {
            if (pages.showNext && positions.all) {
              handlePagination(false);
            }
          }}
        />
      </S.PaginatorButtonNext>
      <S.PaginatorWeekBox>
        <TbCalendarStats /> <span> Semana: {pages.weekName} </span>
      </S.PaginatorWeekBox>
    </S.PaginatorBox>
  );
}
