import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { BlockUI } from 'primereact/blockui';
import { InputSwitch } from 'primereact/inputswitch';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { BsCheckLg } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { updateMapa } from 'client/api';
import { useCidades, useEstados, useMapa } from 'client/hooks/mapa';
import { Mapa } from 'client/interfaces';
import { Option, Select } from 'components/Select/styles';
import VmButton from 'components/VmButton/VmButton';
import { UseFiliaisSelect } from 'hooks/useFiliais/useFiliaisSelect';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import { getGroupClient } from 'client/api/groupClients';

import * as S from './style';
import { InputText } from 'primereact/inputtext';
import UseMapRouterDaySelector from 'modules/mapa/components/mapRouterDaySelector/mapRouterDaySelector';
import { MapContext } from 'providers/map';

const EditMapForm = () => {
  const { id, codEmpresa: codEmpresaParam } = useParams<{ id?: string; codEmpresa: string }>();
  const { data: mapa, isLoading } = useMapa(id ? parseInt(id) : 0, !!id);
  const { optionsFiliais, handleSelectChange, selectedOption, setSelectedOption } = UseFiliaisSelect({
    codEmpresa: Number(codEmpresaParam),
  });
  const [isSaving, setIsSaving] = useState(false);

  const { Selectdays, daySelection, handleDataStrucuteAdjust } = UseMapRouterDaySelector();
  const { ReProcessRouterData } = useContext(MapContext);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, dirtyFields },
  } = useForm<Mapa>();

  const { setTitlePage } = useTitlePageStore();

  const { data: estados, isFetching: isLoadingEstados } = useEstados();

  const { data: cidades, isFetching: isLoadingCidades } = useCidades(
    watch().estados?.map((e) => e.coduf) ?? [],
    !!watch().estados,
  );

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [gruposCli, setGruposCli] = useState<any[]>([]);

  useEffect(() => {
    if (mapa) {
      const filialToSet = mapa.codFilial != 0 ? mapa.codFilial : optionsFiliais[0] ? optionsFiliais[0].value : null;
      setValue('nome', mapa.nome);
      setValue('ativo', mapa.ativo);
      setValue('codEmpresa', mapa.codEmpresa);
      setValue('codFilial', filialToSet);
      setValue('estados', mapa.estados ?? []);
      setValue('cidades', mapa.cidades ?? []);
      setValue('inCli', mapa.inCli ?? '');
      setValue('notInCli', mapa.notInCli ?? '');
      setValue('frequencia', mapa.frequencia ?? 0);
      setValue('tempoMinimo', mapa.tempoMinimo ?? 0);
      setValue('grupos', mapa.grupos ?? []);
      setSelectedOption(String(mapa.codFilial));
      handleGetGroupsData();
      setGruposCli(mapa.grupos);
      handleDataStrucuteAdjust(mapa, 'fowards');
    }
  }, [mapa]);

  const onSubmit = async (data: Mapa) => {
    const filialToSet =
      selectedOption != '0' ? selectedOption : optionsFiliais[0] ? optionsFiliais[0].value.toString() : '0';
    try {
      data.grupos &&
        data.grupos.map((group: any) => {
          const stateGroup = gruposCli.filter((g: any) => g.codgrupo == group.codgrupo)[0];
          group.tempoMinimoVisita = +stateGroup.tempoMinimoVisita ?? 0;
          group.nomeGrupo = stateGroup.nomeGrupo ?? '';
          return group;
        });

      data = handleDataStrucuteAdjust(data, 'backwords');

      const mapa: Mapa = {
        ...data,
        codFilial: Number(filialToSet),
        codEmpresa: Number(codEmpresaParam),
      };
      setIsSaving(true);

      await updateMapa({ ...mapa, cod: parseInt(id) }).then(() => {
        // ReProcessRouterData({
        //   codMapa: id,
        //   codEmpresa: codEmpresaParam
        // });
        toast.success('Mapa editado com sucesso!');
      });

      await queryClient.invalidateQueries(['mapas']);
      navigate('/mapa');
    } catch (e) {
      toast.error('Falha ao editar Mapa!');
    } finally {
      setIsSaving(false);
    }
  };

  interface GruposMapa {
    codmapa: number;
    codempresa: number;
    codgrupo: number;
    codfilial: number;
    nomeGrupo: string;
    tempoMinimoVisita: string;
    label: string;
  }

  const handleGetGroupsData = () => {
    setTitlePage('Editar Mapa');
    getGroupClient(codEmpresaParam).then((res: any) => {
      let groupList: GruposMapa[] = [];
      for (let i = 0; i < res.length; i++) {
        let group = res[i];
        group.tempoMinimoVisita = '0';
        group.codmapa = id;
        group.label = `${group.codgrupo} - ${group.nomeGrupo}`;

        for (let j = 0; j < mapa.grupos.length; j++) {
          let mapGroup: any = mapa.grupos[j];
          if (mapGroup.codgrupo == group.codgrupo) {
            group.tempoMinimoVisita = mapGroup.tempoMinimoVisita;
          }
        }
        groupList.push(group);
      }
      setGruposCli(groupList);
    });
  };

  const grupoTemplate = (options: GruposMapa) => {
    return (
      <S.GroupTemplate id={'ccorgz'}>
        <S.GrupoTitle>{`${options.codgrupo} ${options.nomeGrupo}`}</S.GrupoTitle>
        <S.GrupoTimeInputBox>
          <InputText
            placeholder="Tempo Mínimo (min)"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputSelectionChange(e, options);
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}
            defaultValue={options.tempoMinimoVisita}
            onClick={(e) => e.stopPropagation()}
          />
          <label>Tempo Mínimo Visita</label>
        </S.GrupoTimeInputBox>
      </S.GroupTemplate>
    );
  };

  const handleInputSelectionChange = (e: React.ChangeEvent<HTMLInputElement>, option: GruposMapa) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    option.tempoMinimoVisita = e.target.value;
    const gruposList = gruposCli;
    gruposList.map((group: any) => {
      if (group.codgrupo == option.codgrupo) {
        group.tempoMinimoVisita = option.tempoMinimoVisita;
        group.nomeGrupo = option.nomeGrupo;
      }
    });
    setGruposCli(gruposList);
  };

  return (
    <div className="m-10 ">
      <BlockUI containerStyle={{ zIndex: 0}} blocked={(!!id && isLoading) || isLoadingEstados || isLoadingCidades} template={<ProgressSpinner />}>
        <form className="form w-100" onSubmit={handleSubmit(onSubmit)}>
          <div className="fv-row mb-5 w-100 d-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">*Nome</label>
              <input
                {...register('nome', { required: 'Obrigatório' })}
                placeholder="Nome do Mapa"
                className={clsx('form-control form-control-lg', {
                  'is-invalid': (dirtyFields.nome ?? false) && errors.nome,
                })}
                autoFocus
                maxLength={300}
                type="text"
                name="nome"
              />
              {errors.nome != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.nome.message}</span>
                </div>
              )}
            </div>

            <div style={{ width: '45%' }}>
              <>
                <label className="form-label fs-6 fw-bolder text-dark">Cód Empresa</label>

                <input
                  {...register('codEmpresa')}
                  placeholder="Cód. Empresa"
                  className={clsx('form-control form-control-lg', {
                    'is-invalid': (dirtyFields.codEmpresa ?? false) && errors.codEmpresa,
                  })}
                  maxLength={8}
                  type="string"
                  name="codEmpresa"
                  disabled={true}
                  value={codEmpresaParam}
                />
                {errors.codEmpresa != null && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <span role="alert">{errors.codEmpresa.message}</span>
                  </div>
                )}
              </>
            </div>
          </div>

          <div className="fv-row mb-5 d-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ width: '45%', flexDirection: 'column', display: 'flex' }}>
              <label className="form-label fs-6 fw-bolder text-dark">*Cód Filial</label>
              <Select
                {...register('codFilial', { required: 'Obrigatório' })}
                onChange={handleSelectChange}
                value={selectedOption}
                placeholder="Selecione uma filial"
              >
                <Option disabled>Selecione uma filial</Option>
                {optionsFiliais?.map((filial, index) => (
                  <Option key={index} value={filial.value}>
                    {filial.label + ' [' + filial.value + ']'}
                  </Option>
                ))}
              </Select>

              {errors.codFilial != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.codFilial.message}</span>
                </div>
              )}
            </div>
            <div style={{ width: '45%', textAlign: 'left', justifyContent: 'start' }}>
              <div>
                <label className="form-label fs-6 fw-bolder text-dark">Ativo</label>
              </div>
              <Controller
                control={control}
                name="ativo"
                render={({ field: { onChange, value } }) => <InputSwitch checked={value} onChange={onChange} />}
              />
            </div>
          </div>

          <div className="fv-row mb-5 d-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">*Tempo Mínimo Visita</label>
              <input
                {...register('tempoMinimo', { required: 'Obrigatório' })}
                placeholder="Tempo Mínimo Visita"
                className="form-control form-control-lg"
                type="number"
                name="tempoMinimo"
              />
              {errors.tempoMinimo != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.tempoMinimo.message}</span>
                </div>
              )}
            </div>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">*Frequência Visita</label>
              <input
                {...register('frequencia', { required: 'Obrigatório' })}
                placeholder="Frequência Visita"
                className="form-control form-control-lg"
                type="number"
                name="frequencia"
              />
              {errors.frequencia != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.frequencia.message}</span>
                </div>
              )}
            </div>
          </div>

          <div className="fv-row mb-5 d-flex" style={{ justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">Clientes a Excluir</label>
              <input
                {...register('notInCli')}
                placeholder="Excluir clientes específicos do mapa"
                className="form-control form-control-lg"
                type="notInCli"
                name="notInCli"
              />
            </div>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">Clientes a Incluir</label>
              <input
                {...register('inCli')}
                placeholder="Incluir clientes específicos no mapa"
                className="form-control form-control-lg"
                type="inCli"
                name="inCli"
              />
            </div>
          </div>

          <S.MapFormRow>
            <div style={{ width: '45%' }}>
              <div>
                <label className="form-label fs-6 fw-bolder text-dark">Estados</label>
              </div>
              <Controller
                control={control}
                name="estados"
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    className="form-control form-control-lg"
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      setValue('cidades', []);
                    }}
                    required
                    options={estados ?? []}
                    optionLabel="nomeUf"
                    placeholder="Estados"
                    display="chip"
                    filter
                    filterBy="nomeUf"
                  />
                )}
              />
            </div>
            <div style={{ width: '45%' }}>
              <label className="form-label fs-6 fw-bolder text-dark">Dia/Turno da Roteirização</label>
              <Selectdays />
            </div>
          </S.MapFormRow>

          <div className="fv-row mb-5">
            <div>
              <label className="form-label fs-6 fw-bolder text-dark">Cidades</label>
            </div>
            <Controller
              control={control}
              name="cidades"
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  className="form-control form-control-lg"
                  value={value}
                  onChange={onChange}
                  options={cidades ?? []}
                  optionLabel="cidade"
                  placeholder="Cidades"
                  display="chip"
                  filter
                  filterBy="cidade"
                  dataKey="codcidade"
                />
              )}
            />
          </div>

          <div className="fv-row mb-5">
            <div>
              <label className="form-label fs-6 fw-bolder text-dark">Grupos</label>
            </div>
            <Controller
              control={control}
              name="grupos"
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  className="form-control form-control-lg"
                  value={value}
                  onChange={onChange}
                  options={gruposCli ?? []}
                  optionLabel="nomeGrupo"
                  placeholder="Grupos"
                  display="chip"
                  itemTemplate={grupoTemplate}
                  filter
                  filterBy="nomeGrupo"
                  dataKey="codgrupo"
                />
              )}
            />
          </div>

          <VmButton
            options={{
              label: 'Salvar',
              icon: <BsCheckLg />,
              iconPosition: 'left',
              rounded: false,
              style: 'btn-success',
              loading: isSaving,
            }}
            style={{ marginTop: '10px' }}
            type="submit"
          />
        </form>
      </BlockUI>
    </div>
  );
};

export default EditMapForm;
