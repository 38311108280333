import * as S from './header.styles';
import { useAnaliseRoteiro } from '../../analiseRoteiro.context';
import { UseFindByRoleManagers } from 'hooks/useManagers/useFindByRoleManagers';
import { Dropdown } from 'primereact/dropdown';
import { HeaderFilterProps } from '../../analiseRoteiro.types';
import { useEffect, useState } from 'react';
import { useUser, useUserByCompany } from 'client/hooks';
import { UseSupervisorByRole } from 'hooks/useSupervisor/useSupervisorByRole';
import { useVendedoresByUser } from 'client/hooks/vendedores';
import { useParams } from 'react-router-dom';

export default function Header() {
  const { selectedEmpresa, SelectEmpresa, setSelectedCodVendedor } = useAnaliseRoteiro();

  const { data: user } = useUser();

  const [filters, setFilters] = useState<HeaderFilterProps>({
    codGerente: 0,
    codSupervisor: 0,
    codVendedor: 0,
    codEmpresa: SelectEmpresa.codEmpresa,
    codUsuario: user ? user.codUsuario : 0,
  });

  const { managersByRoleOptions, refetchManagersByRole } = UseFindByRoleManagers({
    codCompany: selectedEmpresa.codEmpresa,
    codUser: filters.codUsuario ? filters.codUsuario : user ? user.codUsuario : 0,
  });
  const { optionsSupervisorByRole, refetchSupervisor } = UseSupervisorByRole({
    codCompany: selectedEmpresa.codEmpresa,
    codUser: filters.codUsuario ? filters.codUsuario : user ? user.codUsuario : 0,
  });
  const { data: vendedores, refetch: refetchVendedores } = useVendedoresByUser(
    selectedEmpresa.codEmpresa,
    filters.codUsuario ? filters.codUsuario : user ? user.codUsuario : 0,
  );
  const { data: usuarios, refetch: refetchUsers } = useUserByCompany(selectedEmpresa.codEmpresa);

  const { codVendedor } = useParams<{ codVendedor: string }>();

  useEffect(() => {
    refetchUsers();
  }, [SelectEmpresa.codEmpresa]);

  useEffect(() => {
    refetchManagersByRole();
    refetchSupervisor();
    refetchVendedores();
  }, [filters.codUsuario]);

  useEffect(() => {
    if (codVendedor) {
      setFilters((prev: HeaderFilterProps) => {
        return { ...prev, codVendedor: +codVendedor ?? 0 };
      });
    }
  }, [codVendedor]);

  return (
    <S.HeaderMainBox>
      <SelectEmpresa />

      <S.ARInputBox>
        <label>Usuário</label>
        <Dropdown
          placeholder="Selecione um Usuário"
          value={filters.codUsuario}
          options={usuarios}
          onChange={(e) => {
            setFilters((prev: HeaderFilterProps) => ({
              ...prev,
              codUsuario: e.value,
            }));
          }}
          emptyMessage="Nenhum Usuário Encontrado :/"
          filter
          filterBy="nome,codUsuario"
          showClear
          optionValue="codUsuario"
          optionLabel="nome"
          itemTemplate={(usuario) => {
            return <div>{`${usuario.nome} - [${usuario.codUsuario}]`}</div>;
          }}
        />
      </S.ARInputBox>

      <S.ARInputBox>
        <label>Gerente</label>
        <Dropdown
          placeholder="Selecione um Gerente"
          value={filters.codGerente}
          options={managersByRoleOptions}
          onChange={(e) => {
            setFilters((prev: HeaderFilterProps) => {
              return { ...prev, codGerente: e.value };
            });
          }}
          emptyMessage="Nenhum Gerente Encontrado :/"
          filter
          filterBy="label"
          showClear
        />
      </S.ARInputBox>

      <S.ARInputBox>
        <label>Supervisor</label>
        <Dropdown
          placeholder="Selecione um Supervisor"
          value={filters.codSupervisor}
          options={optionsSupervisorByRole}
          onChange={(e) => {
            setFilters((prev: HeaderFilterProps) => {
              return { ...prev, codSupervisor: e.value };
            });
          }}
          emptyMessage="Nenhum Supervisor Encontrado :/"
          filter
          filterBy="label"
          showClear
        />
      </S.ARInputBox>

      <S.ARInputBox>
        <label>Vendedor</label>
        <Dropdown
          placeholder="Selecione um Vendedor"
          value={filters.codVendedor}
          options={
            filters.codSupervisor
              ? vendedores.filter(
                  (vend: any) => +vend.codSupervisor == +filters.codSupervisor || filters.codSupervisor == -1,
                )
              : vendedores ?? []
          }
          onChange={(e) => {
            setSelectedCodVendedor(e.value);
            setFilters((prev: HeaderFilterProps) => {
              return { ...prev, codVendedor: e.value };
            });
          }}
          emptyMessage="Nenhum Vendedor Encontrado :/"
          optionValue="codVendedor"
          optionLabel="nome"
          itemTemplate={(vendedor) => {
            return <div>{`${vendedor.nome} - [${vendedor.codVendedor}]`}</div>;
          }}
          filter
          filterBy="nome,codVendedor"
          showClear
        />
      </S.ARInputBox>
    </S.HeaderMainBox>
  );
}
