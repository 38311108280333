'use client';

import { createContext, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  AgrupadorOptionsProps,
  CoresOptionsProps,
  GetKpiItemProps,
  ItemKpiFilterProps,
  ItemKpiListProps,
  KpiFilterProps,
  KpiListProps,
  PeriodKpiListProps,
} from './kpi.types';
import { KpiItemProps, KpiProps, PeriodKpiProps } from 'client/interfaces';
import { getAllKpi, getAllPeriodKpi, getItemKpi, postRunKpi } from 'client/api/kpis';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { useLoaderEffect } from 'providers/loaderEffect';
import { dialog } from 'reactivus';

export interface ProdContextProps {
  agrupadorOptions: AgrupadorOptionsProps[];
  kpis: KpiListProps;
  handleGetKpiList: () => void;
  selectedKpi: KpiProps;
  setSelectedKpi: React.Dispatch<SetStateAction<KpiProps>>;
  handleKpiHeaderFilter: () => void;
  kpiFilters: KpiFilterProps;
  setKpiFilters: React.Dispatch<SetStateAction<KpiFilterProps>>;
  handleGetItensKpi: (itemBody: GetKpiItemProps) => void;
  itensKpi: ItemKpiListProps;
  coresOptions: CoresOptionsProps[];
  itemKpiFilters: ItemKpiFilterProps;
  setItemKpiFilters: React.Dispatch<SetStateAction<ItemKpiFilterProps>>;
  SelectEmpresa: any;
  selectedEmpresa: SelectedEmpresaProps;
  handlePostRunKpi: (nrokpi: number) => void;
  handleConfirmKpiReRun: (kpi: KpiProps) => void;
  formatKpiNumber: (value: number) => string;
  periodKpis: PeriodKpiListProps;
  setPeriodKpis: React.Dispatch<SetStateAction<PeriodKpiListProps>>;
  selectedPeriodKpi: PeriodKpiProps;
  setSelectedPeriodKpi: React.Dispatch<SetStateAction<PeriodKpiProps>>;
}

type SelectedEmpresaProps = {
  codEmpresa: number;
  fantasia?: string;
};

export const KpiContext = createContext({} as ProdContextProps);

export const KpiProvider = ({ children }: any) => {
  const { setLoader } = useLoaderEffect();

  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();

  const { pathname } = useLocation();

  const agrupadorOptions: AgrupadorOptionsProps[] = [
    { name: 'Filial', code: '1' },
    { name: 'Gerente', code: '2' },
    { name: 'Supervisor', code: '3' },
    { name: 'Vendedor', code: '4' },
    { name: 'Cliente/Vendedor', code: '5' },
    { name: 'Fornecedor/Vendedor', code: '6' },
  ];

  const coresOptions: CoresOptionsProps[] = [
    { name: '#E91E63', code: '1' },
    { name: '#FE9738', code: '2' },
    { name: '#FEEC55', code: '3' },
    { name: '#56C5CB', code: '4' },
  ];

  const [kpiFilters, setKpiFilters] = useState({
    text: '',
    period: '',
  });

  const [itemKpiFilters, setItemKpiFilters] = useState({
    text: '',
    periodo: 'TODOS',
  });

  const [kpis, setKpis] = useState<KpiListProps>({ unfiltered: [], filtered: [] });
  const [periodKpis, setPeriodKpis] = useState<PeriodKpiListProps>({ unfiltered: [], filtered: [] });
  const [itensKpi, setItensKpi] = useState<ItemKpiListProps>({ unfiltered: [], filtered: [] });

  const [selectedKpi, setSelectedKpi] = useState<KpiProps>(null);

  const [selectedPeriodKpi, setSelectedPeriodKpi] = useState<PeriodKpiProps>(null);

  const handleGetKpiList = async () => {
    const kpisList = await getAllKpi(selectedEmpresa.codEmpresa);
    setKpis({
      filtered: kpisList.data ?? [],
      unfiltered: kpisList.data ?? [],
    });
    handleGetPeriodKpiList();
  };

  const handleGetPeriodKpiList = async () => {
    const kpisList: any = await getAllPeriodKpi(selectedEmpresa.codEmpresa);
    setPeriodKpis({
      filtered: kpisList ?? [],
      unfiltered: kpisList ?? [],
    });
  };

  useEffect(() => {
    handleGetKpiList();
  }, [selectedEmpresa, pathname]);

  useEffect(() => {
    setSelectedKpi(null);
    setSelectedPeriodKpi(null);
    setKpis({ unfiltered: [], filtered: [] });
    setPeriodKpis({ unfiltered: [], filtered: [] });
    setItensKpi({ unfiltered: [], filtered: [] })
  }, [selectedEmpresa]);

  const handleKpiHeaderFilter = () => {
    let kpiListFiltered =
      kpis?.unfiltered.filter(
        (rep: KpiProps) => rep.titulo.includes(kpiFilters.text) || rep.sqlleitura.includes(kpiFilters.text),
      ) ?? [];

    kpiListFiltered =
      kpiListFiltered?.filter(
        (rep: KpiProps) => rep.titulo.includes(kpiFilters.text) || rep.sqlleitura.includes(kpiFilters.text),
      ) ?? [];

    setKpis((prev: any) => {
      return {
        ...prev,
        filtered: kpiListFiltered,
      };
    });
  };

  useEffect(() => {
    if (selectedPeriodKpi && selectedKpi) {
      handleGetItensKpi({
        codEmpresa: selectedEmpresa.codEmpresa,
        nroKpi: selectedKpi.nrokpi,
        descPeriodo: selectedPeriodKpi.descPeriodo,
      });
    }
  }, [selectedPeriodKpi, selectedKpi]);

  const handleGetItensKpi = async (itemBody: GetKpiItemProps) => {
    const kpisItemList = await getItemKpi(itemBody);
    setItensKpi({
      filtered: kpisItemList,
      unfiltered: kpisItemList,
    });
  };

  useEffect(() => {
    itensKpi.unfiltered.length > 0 && handleKpiItensFilter();
  }, [itemKpiFilters]);

  const handleKpiItensFilter = () => {
    let filteredItemsKpi = itensKpi.unfiltered;

    if (itemKpiFilters.text) {
      filteredItemsKpi =
        filteredItemsKpi?.filter(
          (item: KpiItemProps) =>
            (item.NOME && item.NOME.includes(itemKpiFilters.text)) ||
            (item.CODIGO && item.CODIGO.toString().includes(itemKpiFilters.text)) ||
            (item.DESC_GRUPOB && item.DESC_GRUPOB.toString().includes(itemKpiFilters.text)) ||
            (item.CODGRUPOB && item.CODGRUPOB.toString().includes(itemKpiFilters.text)),
        ) ?? [];
    }

    setItensKpi((prev: any) => {
      return {
        ...prev,
        filtered: filteredItemsKpi,
      };
    });
  };

  const handlePostRunKpi = async (nrokpi: number) => {
    setLoader({
      show: true,
      text: `Por favor aguarde, apurando KPI Nº${nrokpi}`,
    });
    postRunKpi(selectedEmpresa.codEmpresa, nrokpi)
      .then((res) => {
        if (res.succeeded) {
          toast.success('KPI atualizado!');
          handleGetKpiList();
        } else {
          toast.error('Falha inesperada ao atualizar KPI!');
        }
      })
      .finally(() => {
        setLoader({
          show: false,
          text: '',
        });
      });
  };

  const handleConfirmKpiReRun = (kpi: KpiProps) => {
    dialog
      .show({
        showConfirmButton: true,
        confirmButtonText: 'Sim, atualizar',
        showCancelButton: true,
        cancelButtonText: 'Não, cancelar',
        allowClose: true,
        icon: 'question',
        title: 'Atualizar KPI?',
        html: `<span>Os dados do KPI <b>[${kpi.nrokpi}] ${kpi.titulo}</b> serão atualizados.</span>`,
      })
      .then((res) => {
        if (res.isConfirmed) {
          handlePostRunKpi(kpi.nrokpi);
        }
      });
  };

  function formatKpiNumber(value: number): string {
    return value
      .toFixed(2)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  return (
    <KpiContext.Provider
      value={{
        agrupadorOptions,
        kpis,
        handleGetKpiList,
        selectedKpi,
        setSelectedKpi,
        handleKpiHeaderFilter,
        kpiFilters,
        setKpiFilters,
        handleGetItensKpi,
        itensKpi,
        coresOptions,
        itemKpiFilters,
        setItemKpiFilters,
        SelectEmpresa,
        selectedEmpresa,
        handlePostRunKpi,
        handleConfirmKpiReRun,
        formatKpiNumber,
        periodKpis,
        setPeriodKpis,
        selectedPeriodKpi,
        setSelectedPeriodKpi,
      }}
    >
      {children}
    </KpiContext.Provider>
  );
};

export const useKpi = () => useContext(KpiContext);
