/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useState } from 'react';

export interface LoaderEffectProviderProps {
  children: ReactNode;
}

interface LoaderProps {
  show: boolean;
  text?: string;
}

export interface LoaderEffectContextProps {
  loader: LoaderProps;
  setLoader: React.Dispatch<React.SetStateAction<LoaderProps>>;
}

export const LoaderEffectContext = createContext({} as LoaderEffectContextProps);

export const LoaderEffectProvider = ({ children }: LoaderEffectProviderProps) => {
  const [loader, setLoader] = useState<LoaderProps>({
    show: false,
    text: '',
  });

  return (
    <LoaderEffectContext.Provider
      value={{
        loader,
        setLoader,
      }}
    >
      {children}
    </LoaderEffectContext.Provider>
  );
};

export const useLoaderEffect = () => useContext(LoaderEffectContext);
