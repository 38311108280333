import VmButton from 'components/VmButton/VmButton';
import * as S from './styles';

import { useState } from 'react';

import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import useMediaQuery from 'hooks/useMediaQuery';
import Swal from 'sweetalert2';
import { editVisita } from 'client/api';
import { toast } from 'react-toastify';
import { useUser } from 'client/hooks';

export default function AnaliseModal({ selectedCli, showModal, setShowModal, setSelectedCli, handleGetMainData }: any) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [obsInfo, setObsInfo] = useState('');

  const {data: user} = useUser();

  const handleLiberar = (toUpdateDataObject: any) => {
    editVisita(toUpdateDataObject)
      .then((res: any) => {
        toast.success('Analise liberada!');
        setSelectedCli([]);
        handleGetMainData();
      })
      .catch((err: any) => {
        console.log('err :', err);
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        }
      });
  };

  const handleLiberarConfirmation = (updateCadastro: 'S' | 'N') => {
    if (obsInfo == '') {
      toast.error('Nenhuma observação informada!');
      return;
    }
    let updateDataObject = {
      Codempresa: selectedCli.codempresa,
      CodVendedor: selectedCli.codusur,
      Codcli: selectedCli.codcli,
      Status: 'L',
      Observacao: obsInfo + (updateCadastro == 'S' ? ' [Atu.Cadastro]' : ''),
      latitude: selectedCli.latitude ?? '0',
      longitude: selectedCli.longitude ?? '0',
      AtualizaCadastro: updateCadastro, // S / N
      Codfuncanalise: user.codUsuario,
      Nomefuncanalise: user.nome,
    };
    setShowModal(false);

    Swal.fire({
      title: 'Tem certeza que deseja liberar esta localização ?',
      icon: 'question',
      confirmButtonText: 'Sim, liberar',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        handleLiberar(updateDataObject);
      }
    });
  };

  return (
    <Dialog
      visible={showModal}
      style={{ width: '70vh' }}
      breakpoints={{ '960px': '75vw', '641px': '90vw' }}
      header={'Liberar Localização'}
      modal
      className="p-fluid"
      onHide={() => {
        setShowModal(false);
      }}
    >
      <S.AnaliseModalMainBox>
        <S.ObsInput>
          <span className="p-float-label" style={{ marginTop: !isWebScreen ? '20px' : '', width: '100%' }}>
            <InputText value={obsInfo} onChange={(e) => setObsInfo(e.target.value)} />
            <label htmlFor="desc">Observação</label>
          </span>
        </S.ObsInput>

        <S.AnaliseModalButtonsBox>
          <VmButton
            options={{
              style: 'btn-success',
              label: 'Liberar',
            }}
            onClick={() => handleLiberarConfirmation('N')}
          />
          <VmButton
            options={{
              style: 'btn-info',
              label: 'Liberar + Atualizar Localização Cliente',
            }}
            onClick={() => handleLiberarConfirmation('S')}
          />
          <VmButton
            options={{
              style: 'btn-danger',
              label: 'Cancelar',
            }}
            onClick={() => setShowModal(false)}
          />
        </S.AnaliseModalButtonsBox>
      </S.AnaliseModalMainBox>
    </Dialog>
  );
}
