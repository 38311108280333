import { useQueryClient } from '@tanstack/react-query';
import { putGroupClient } from 'client/api/groupClients';
import { IGroupClient } from 'client/interfaces/groupClient';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

interface IUseEditGroupClient {
  setIsOpenModalEdit: React.Dispatch<React.SetStateAction<boolean>>;
  rowGroupClient: IGroupClient;
}

interface IForm {
  codgrupo: number;
  codempresa: number | string;
  codfilial: number | string;
  nomeGrupo: string;
  ativo: string;
  exibirFv: string;
}

export const UseEditGroupClient = ({ setIsOpenModalEdit, rowGroupClient }: IUseEditGroupClient) => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      nomeGrupo: rowGroupClient?.nomeGrupo,
      codempresa: rowGroupClient?.codempresa,
      codgrupo: rowGroupClient?.codgrupo,
      codfilial: rowGroupClient?.codfilial,
      ativo: rowGroupClient?.ativo,
      exibirFv: rowGroupClient?.exibirFv,
    },
  });

  const queryClient = useQueryClient();

  const values = watch();

  enum DefaultValues {
    COMPANY = 'Selecione uma empresa.',
    FILIAL = 'Selecione uma filial.',
  }

  const optionsExibirFV = [
    {
      value: 'S',
      label: 'Sim',
    },
    {
      value: 'N',
      label: 'Não',
    },
  ];

  const handleEditGroupClient = async (data) => {
    await putGroupClient({
      codempresa: Number(data?.codempresa),
      codfilial: Number(data?.codfilial),
      codgrupo: Number(data?.codgrupo),
      nomeGrupo: data.nomeGrupo,
      ativo: data.ativo,
      exibirFv: data.exibirFv,
    })
      .then(() => {
        toast.success('Grupo editado com sucesso!');
        setIsOpenModalEdit(false);
        queryClient.invalidateQueries({
          queryKey: ['group-clients'],
        });
      })
      .catch(() => {
        toast.error('Não foi possível editar Grupo.');
        setIsOpenModalEdit(false);
        queryClient.invalidateQueries({
          queryKey: ['group-clients'],
        });
      });
  };

  const handleClearFilial = () => {
    setValue('codfilial', DefaultValues.FILIAL);
  };

  const isDefaultCompany = (value) => value !== DefaultValues.COMPANY;
  const isDefaultFilial = (value) => value !== DefaultValues.FILIAL;

  //Default values
  useEffect(() => {
    setValue('codempresa', rowGroupClient?.codempresa);
    setValue('codgrupo', rowGroupClient?.codgrupo);
    setValue('codfilial', rowGroupClient?.codfilial);
    setValue('nomeGrupo', rowGroupClient?.nomeGrupo);
    setValue('ativo', rowGroupClient?.ativo);
    setValue('exibirFv', rowGroupClient?.exibirFv);
  }, [rowGroupClient]);

  return {
    isDefaultCompany,
    isDefaultFilial,
    DefaultValues,
    handleSubmit,
    control,
    values,
    setValue,
    errors,
    handleEditGroupClient,
    handleClearFilial,
    optionsExibirFV,
  };
};
