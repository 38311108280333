import { useContext, useState } from 'react';
import { BsFillPinMapFill, BsFillTrash3Fill, BsIntersect, BsPersonFillCheck, BsStopwatchFill } from 'react-icons/bs';
import { HiCurrencyDollar } from 'react-icons/hi';
import { toast } from 'react-toastify';

import copyFile from 'assets/svgs/copyFile.svg';
import globle from 'assets/svgs/globe.svg';
import history from 'assets/svgs/history.svg';
import mapMarker from 'assets/svgs/mapMarker.svg';
import marker from 'assets/svgs/marker.svg';
import user from 'assets/svgs/user.svg';
import VmButton from 'components/VmButton/VmButton';
import { MapContext } from 'providers/map';

import { EditFrequenceVisit } from './components/EditFrequenceVisit';
import * as S from './styles';

interface IMarkerMapSectorsClients {
  codCompany: string;
  codMap: string;
  selectedMarker: any;
  handleEditBond: () => void;
  handleEditAddress: () => void;
  handleRemoveCliSector: () => void;
  selectedFilter: {
    name: string;
    code: string;
  };
}

export const MarkerMapSectorsClients = ({
  handleRemoveCliSector,
  handleEditAddress,
  handleEditBond,
  selectedMarker,
  codCompany,
  codMap,
  selectedFilter,
}: IMarkerMapSectorsClients) => {
  const [showModalEditFrequenceVisit, setShowModalEditFrequenceVisit] = useState(false);
  const { setores } = useContext(MapContext);

  const copyText = () => {
    const textoNoParagrafo = `${selectedMarker.latitude}, ${selectedMarker.longitude}`;

    const textarea = document.createElement('textarea');
    textarea.value = textoNoParagrafo;

    document.body.appendChild(textarea);
    textarea.select();

    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Coordenadas copiadas para a área de transferência com sucesso!');
  };

  const clientSector = setores?.filter((set) => set.codSetor === selectedMarker.codSetor)[0];

  const numberFormat = (number) => {
    if (number != null && number.toString() == 'Infinity') {
      number = 100.0;
    }
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(number).replace('$', '');
  };

  type PercBarProps = {
    value: number;
  };

  const PercBar = ({ value }: PercBarProps) => {
    return (
      <S.PercBarBox>
        <S.PercBar
          width={value}
          color={value <= 25 ? '#f32929' : value <= 50 ? '#FFC300' : value <= 70 ? '#F64F3D' : '#228F6B'}
        ></S.PercBar>
        <S.PercBarText color={value <= 25 ? '#000' : value <= 50 ? '#000' : value <= 70 ? '#000' : '#000'}>
          {value}%
        </S.PercBarText>
      </S.PercBarBox>
    );
  };

  type PotencialDataProps = {
    vendaPotencial: number;
    vendaUltTri: number;
    vendaPercUltTri?: number;
    vendaUltMes: number;
    vendaPercUltMes?: number;
  };

  const vendaPotencialData = (props: PotencialDataProps) => {
    props.vendaPercUltTri = props.vendaUltTri < 1 ? 0 : +((props.vendaUltTri * 100) / props.vendaPotencial).toFixed(2);
    props.vendaPercUltMes = props.vendaUltMes < 1 ? 0 : +((props.vendaUltMes * 100) / props.vendaPotencial).toFixed(2);

    return (
      <S.SetorVendaPotencialRow>
        <S.SetorVendaPotencialRowContent>
          <span>Ult. Trim: R${numberFormat(props.vendaUltTri)}</span>
          <PercBar value={props.vendaPercUltTri} />
        </S.SetorVendaPotencialRowContent>
        <S.SetorVendaPotencialRowContent>
          <span>Ult. Mês: R${numberFormat(props.vendaUltMes)}</span>
          <PercBar value={props.vendaPercUltMes} />
        </S.SetorVendaPotencialRowContent>
      </S.SetorVendaPotencialRow>
    );
  };

  return (
    <S.MarkerContainer key={selectedMarker}>
      <S.MarkerHeader>
        <S.Badge
          style={{
            background: `#${
              selectedMarker.statusSetor == 'N' && selectedFilter.code == 'A' ? '000000' : selectedMarker?.cor
            }`,
            border: 'none',
          }}
        >
          <img src={user} />
        </S.Badge>
        {selectedMarker ? <p>{`${selectedMarker.razaosocial} [${selectedMarker.codCli}]`}</p> : '--'}
      </S.MarkerHeader>
      <S.MarkerBody>
        <S.MarkerRow>
          <span>
            <S.Badge>
              <img src={globle} />
            </S.Badge>
            <p>Setor</p>
          </span>

          <S.Description>
            <S.BadgeSquare
              style={{
                color: `#${
                  selectedMarker.statusSetor == 'N' && selectedFilter.code == 'A' ? '000000' : selectedMarker?.cor
                }`,
                borderColor: `#${
                  selectedMarker.statusSetor == 'N' && selectedFilter.code == 'A' ? '00000000' : selectedMarker?.cor
                }`,
              }}
            >
              <span>
                {selectedMarker.statusSetor == 'N' && selectedFilter.code == 'A'
                  ? 'Cliente sem setor'
                  : clientSector
                  ? `${clientSector.setor} [${clientSector.codSetor}]`
                  : 'Cliente sem setor'}
              </span>
            </S.BadgeSquare>
          </S.Description>
        </S.MarkerRow>
        <S.Separator />
        <S.MarkerRow>
          <span>
            <S.Badge>
              <img src={mapMarker} />
            </S.Badge>

            <p>Endereço</p>
          </span>

          <S.Description>
            {selectedMarker.endLogradouro} {selectedMarker.endNumero ? `Nº${selectedMarker.endNumero}` : '--'},{' '}
            {selectedMarker.endCidade} {selectedMarker.endUf}
          </S.Description>
        </S.MarkerRow>
        <S.Separator />

        <S.MarkerRow>
          <span>
            <S.Badge>
              <img src={marker} />
            </S.Badge>

            <p>Coordenadas</p>
          </span>

          <S.Description onClick={copyText} style={{ cursor: 'pointer' }}>
            {selectedMarker.latitude}, {selectedMarker.longitude} <img src={copyFile} />
          </S.Description>
        </S.MarkerRow>
        <S.Separator />

        <S.MarkerRow>
          <span>
            <S.Badge>
              <img src={history} />
            </S.Badge>
            <p>Frequência Visita:</p>
          </span>
          <S.Description onClick={copyText} style={{ cursor: 'pointer' }}>
            {selectedMarker.freqVisita}
          </S.Description>
        </S.MarkerRow>
        <S.Separator />

        <S.MarkerRow>
          <span>
            <S.Badge>
              <BsStopwatchFill color={'#a8aabc'} />
            </S.Badge>
            <p>Tempo Mínimo Visita:</p>
          </span>
          <S.Description onClick={copyText} style={{ cursor: 'pointer' }}>
            {selectedMarker.tempoMinVisita}
          </S.Description>
        </S.MarkerRow>
        <S.Separator />

        <S.MarkerRow>
          <span>
            <S.Badge>
              <HiCurrencyDollar color={'#a8aabc'} />
            </S.Badge>
            <p>
              Potencial: <br /> R${numberFormat(selectedMarker.vlPotencial)}{' '}
            </p>
          </span>
          <S.Description style={{ cursor: 'pointer' }}>
            {vendaPotencialData({
              vendaPotencial: selectedMarker.vlPotencial,
              vendaUltMes: selectedMarker.vlMesAtual,
              vendaUltTri: selectedMarker.vlMedUltTrim,
            })}
          </S.Description>
        </S.MarkerRow>

        <S.MarkerRowButtons>
          <VmButton
            style={{ marginRight: '10px' }}
            options={{
              style: 'btn-dark',
              rounded: true,
              icon: <BsPersonFillCheck />,
              iconPosition: 'right',
              size: `btn-sm`,
              tooltip: {
                show: true,
                position: 'top',
                text: 'Editar Visita',
              },
            }}
            onClick={() => {
              setShowModalEditFrequenceVisit(!showModalEditFrequenceVisit);
            }}
          />

          <VmButton
            options={{
              style: 'btn-dark',
              rounded: true,
              icon: <BsIntersect />,
              iconPosition: 'right',
              size: `btn-sm`,
              tooltip: {
                show: true,
                position: 'top',
                text: 'Vincular Clinte a Setor',
              },
            }}
            onClick={() => {
              handleEditBond();
            }}
          />

          <VmButton
            style={{ marginLeft: '10px' }}
            options={{
              style: 'btn-info',
              rounded: true,
              icon: <BsFillPinMapFill />,
              iconPosition: 'right',
              tooltip: {
                show: true,
                position: 'top',
                text: 'Editar Coordenadas Cliente',
              },
            }}
            onClick={() => {
              handleEditAddress();
            }}
          />

          <VmButton
            style={{ marginLeft: '10px' }}
            options={{
              style: 'btn-danger',
              rounded: true,
              icon: <BsFillTrash3Fill />,
              iconPosition: 'right',
              tooltip: {
                show: true,
                position: 'top',
                text: 'Remover Cliente',
              },
            }}
            onClick={() => handleRemoveCliSector()}
          />
        </S.MarkerRowButtons>
      </S.MarkerBody>
      {showModalEditFrequenceVisit && <EditFrequenceVisit
        codCompany={codCompany}
        codMap={codMap}
        selectedMarker={selectedMarker}
        showModal={showModalEditFrequenceVisit}
        setShowModal={setShowModalEditFrequenceVisit}
      />}
    </S.MarkerContainer>
  );
};
