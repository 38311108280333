import { useFiliais } from 'client/hooks/filiais';
import { useState } from 'react';

interface UseFiliaisSelectProtocols {
  optionsFiliais: {
    value: number;
    label: string;
  }[];
  handleSelectChange: (event: any) => void;
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  selectedOption: string;
  codEmpresaVMais: number;
}

interface IUseFiliaisParams {
  codEmpresa: number;
}

export const UseFiliaisSelect = ({ codEmpresa }: IUseFiliaisParams): UseFiliaisSelectProtocols => {
  const { data: filiais } = useFiliais(isNaN(codEmpresa) ? 0 : +codEmpresa);

  const [selectedOption, setSelectedOption] = useState('');
  const codEmpresaVMais = 2072021;

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const optionsFiliais = filiais?.map((item) => ({
    value: item.codFilial,
    label: `${item.nomeFilial} - [${item.codFilial}]`,
  }));

  return {
    optionsFiliais,
    selectedOption,
    setSelectedOption,
    handleSelectChange,
    codEmpresaVMais,
  };
};
