import {
  Iclientes,
  IEditSetor,
  IRegisterSetor,
  Isetores,
  Isupervisores,
  Ivendedores,
  Iicones,
  Isetores2,
} from 'client/interfaces';

import api from 'services/api';

const _controller = 'Setores';

export const getSupervisores = async (empresa: number): Promise<Isupervisores[]> => {
  const { data } = await api.get(`/Supervisor`, { params: { empresa } });
  return data;
};

export const getVendedores = async (empresa: number | string): Promise<Ivendedores[]> => {
  const { data } = await api.get(`/Vendedor`, { params: { empresa } });
  return data;
};

export const getSetores = async (codMapa: number | undefined): Promise<Isetores[]> => {
  const { data } = await api.get(`/${_controller}/setores`, { params: { codMapa } });
  return data;
};

export const getSetoresByUser = async (codEmpresa: number, codUser: number, codMapa: number): Promise<number[]> => {
  const { data } = await api.get(`/${_controller}/Allowed/ByUser/${codEmpresa}/${codUser}/${codMapa}`);
  return data;
};

export const getSetoresByRole = async (codEmpresa: number, codUser: number, codMapa: number): Promise<any> => {
  const { data } = await api.get(`/${_controller}/${codEmpresa}/${codUser}/${codMapa}`);
  return data;
};

export const getClientes = async (codEmpresa: number, codUser: number, codMapa: number): Promise<Iclientes[]> => {
  const { data } = await api.get(`/${_controller}/clientes/${codEmpresa}/${codUser}/${codMapa}/mapa`);
  return data;
};

export const getClientesWithSector = async (
  codEmpresa: number,
  codUser: number,
  codMapa: number,
): Promise<Iclientes[]> => {
  const { data } = await api.get(`/${_controller}/ClientsWithSector/${codEmpresa}/${codUser}/${codMapa}/map`);
  return data;
};

export const getClientesWithoutSector = async (
  codEmpresa: number,
  codUser: number,
  codMapa: number,
): Promise<Iclientes[]> => {
  const { data } = await api.get(`/${_controller}/ClientsBySectorCode/${codEmpresa}/${codUser}/${codMapa}/0/map`);
  return data;
};

export const getClientesSectorList = async (
  codEmpresa: number,
  codUser: number,
  codMapa: number,
): Promise<Isetores2[]> => {
  const { data } = await api.get(`/${_controller}/ClientsSectorsList/${codEmpresa}/${codUser}/${codMapa}/map`);
  return data;
};

export const getMapaIcones = async (): Promise<Iicones[]> => {
  const { data } = await api.get(`/iconeMapa`);
  return data;
};

export const editSetor = async (toEditSetor: IEditSetor): Promise<void> => {
  await api.put(`/${_controller}/${toEditSetor.codSetor}`, toEditSetor);
};

export const registerSetor = async (toRegisterSetor: IRegisterSetor): Promise<void> => {
  await api.post(`/${_controller}`, toRegisterSetor);
};

export const putImportCli = async (params: any): Promise<void> => {
  await api.put(`/AreaMapa/ImportaClienteSetor`, params);
};

export const putEditCliCoords = async (params: any): Promise<void> => {
  await api.put(`/Setores/UpdateLocalizacaoCli`, params);
};

export const deleteAllCliFromSetor = async (params: any): Promise<void> => {
  await api.delete(`/AreaMapa/removeTodosClienteSetor?codmepresa=${params.codmepresa}&codsetor=${params.codsetor}`);
};
