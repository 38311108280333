import * as S from './styles';

import PixCard from 'modules/checkout/components/pixCard/pixCard';
import CreditCard from 'modules/checkout/components/creditCard/creditCard';
import CliCard from 'modules/checkout/components/cliCard/cliCard';
import ProdList from 'modules/checkout/components/prodList/prodList';
import PaymentCard from 'modules/checkout/components/paymentCard/paymentCard';
import { FaLock } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { getPedidoCheckout, postPaymentCheckout } from 'client/api';
import { toast } from 'react-toastify';
import useMediaQuery from 'hooks/useMediaQuery';
import SuccessPage from 'modules/checkout/components/successPage/successPage';
import LostPage from 'modules/checkout/components/lostPage/lostPage';
import ErrorPage from 'modules/checkout/components/errorPage/errorPage';

const Buffer = require('buffer').Buffer;

type PedidoProps = {
  cliente: any;
  cab: any;
  itens: Array<any>;
};

export default function CheckoutPage() {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [pedido, setPedido] = useState<PedidoProps>({
    cliente: {},
    cab: {},
    itens: [],
  });

  const [paymentData, setPaymentData] = useState<any>({
    token: '',
    nome: '',
    numeroCartao: '',
    dataExpiracao: '',
    codigoSeguranca: '',
    valor: 0,
    bandeira: '',
    parcelas: '',
    moeda: 0,
    descricao: '',
  });

  const [pageStatus, setPageStatus] = useState<string>('');

  useEffect(() => {
    handleTokenValidation();
  }, []);

  const handleTokenValidation = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('t');
    if (token && token.length > 0) {
      try {
        const decodedToken = Buffer.from(token, 'base64').toString('utf8');
        if (decodedToken.split('|').length == 3) {
          setPaymentData((prev: any) => {
            return {
              ...prev,
              token: token,
            };
          });
          getPedidoCheckoutData(token);
        } else {
          setPageStatus('E');
        }
      } catch (err) {
        setPageStatus('E');
      }
    } else {
      setPageStatus('V');
    }
  };

  const getPedidoCheckoutData = (token: string) => {
    getPedidoCheckout(token)
      .then((res) => {
        setPageStatus('P');
        setPedido({
          cliente: res.cliente ?? {},
          cab: res.cab_pedido ?? {},
          itens: res.itens_pedido ?? [],
        });
        setPaymentData((prev: any) => {
          return {
            ...prev,
            valor: res.cab_pedido && res.cab_pedido.vltotal ? res.cab_pedido.vltotal : '',
            parcelas: res.cab_pedido && res.cab_pedido.parcelas ? res.cab_pedido.parcelas : '',
          };
        });
      })
      .catch((err) => {
        setPageStatus('E');
        console.log('err :', err);
      });
  };

  const paymentDataTests = [
    (dataToProccessPayment: any) => {
      if (dataToProccessPayment.nome.length == 0) {
        toast.error('Nome como no cartão não informado!');
        return false;
      } else {
        return true;
      }
    },
    (dataToProccessPayment: any) => {
      if (dataToProccessPayment.numeroCartao.length < 16) {
        toast.error('Número do cartão incompleto!');
        return false;
      } else {
        return true;
      }
    },
    (dataToProccessPayment: any) => {
      if (dataToProccessPayment.dataExpiracao.length < 7) {
        toast.error('Data de expiração inválida!');
        return false;
      } else {
        return true;
      }
    },
    (dataToProccessPayment: any) => {
      if (dataToProccessPayment.codigoSeguranca.length < 3) {
        toast.error('Código de segurança inválido!');
        return false;
      } else {
        return true;
      }
    },
  ];

  const handleProccessPayment = () => {
    const dataToProccessPayment = { ...paymentData };
    dataToProccessPayment.numeroCartao = dataToProccessPayment.numeroCartao.replaceAll(' ', '');
    for (let i = 0; i < paymentDataTests.length; i++) {
      if (!paymentDataTests[i](dataToProccessPayment)) {
        return;
      }
    }

    postPaymentCheckout(dataToProccessPayment)
      .then((res: any) => {
        if (res.status) {
          if (res && res.status == 'success') {
            setPageStatus('S');
            toast.success(res.message ?? 'Pagamento efetuado!');
          } else {
            toast.error(res.message ?? 'Falha ao processar pagamento!');
          }
        } else {
          if (res && res == 'Sucesso') {
            setPageStatus('S');
            toast.success('Pagamento efetuado!');
          } else {
            toast.error(res ?? 'Falha ao processar pagamento!');
          }
        }
      })
      .catch((err) => {
        console.log('err :', err);
        toast.error('Falha inesperada ao processar pagamento!');
      });
  };

  if (pageStatus == 'P') {
    return (
      <S.MainBox>
        <S.HeaderBox>
          <FaLock /> <span>Ambiente 100% seguro | Vmais Sistemas</span>
        </S.HeaderBox>
        <S.ColBox>
          <PaymentCard pedido={pedido} handleProccessPayment={handleProccessPayment} />

          {pedido.cab.codcob == 'PIX' ? <PixCard /> : <CreditCard card={paymentData} setCard={setPaymentData} />}
        </S.ColBox>

        <S.ColBox>
          <CliCard cliente={pedido.cliente} />
          <ProdList itens={pedido.itens} />
        </S.ColBox>
        {!isWebScreen && (
          <S.PProccessButtonBox>
            <S.PProccessButton
              onClick={() => {
                handleProccessPayment();
              }}
            >
              Finalizar Pagamento
            </S.PProccessButton>
          </S.PProccessButtonBox>
        )}
      </S.MainBox>
    );
  } else if (pageStatus == 'S') {
    return <SuccessPage />;
  } else if (pageStatus == 'E') {
    return <ErrorPage />;
  } else if (pageStatus == 'V') {
    return <LostPage />;
  } else {
    return <></>;
  }
}
