import { BsFillHouseFill } from 'react-icons/bs';
import * as S from './styles';
import { FaLock } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useAnaliseRoteiro } from '../../analiseRoteiro.context';
import { MapSettingsProps, PositionsStateProps } from '../../analiseRoteiro.types';

export default function StopListCard({ point, searchText }: { point: any; searchText: string }) {
  const [isCardExpanded, setIsCardExpanded] = useState(false);

  const { positions, setPositions, cores, setSelectedMarker, setMapSettings, selectedMarker } = useAnaliseRoteiro();

  useEffect(() => {
    const localSelectedDay = window.sessionStorage.getItem('routerSelectedDay') ?? '';
    if (positions.all.length == 1 && new Date(point.dtProxVisita).toLocaleDateString('pt-BR') == localSelectedDay) {
      setIsCardExpanded(true);
    } else if (positions.all.length > 1 && isCardExpanded && !positions.all) {
      setIsCardExpanded(false);
    }
  }, [positions.all]);

  useEffect(() => {
    const localSelectedDay = window.sessionStorage.getItem('routerSelectedDay') ?? '';
    const newPositions = positions.all.filter(
      (position: any) => new Date(position.dtProxVisita).toLocaleDateString('pt-BR') == localSelectedDay,
    );
    if (newPositions.length > 0) {
      setPositions((prev: PositionsStateProps) => {
        return {
          ...prev,
          current: newPositions,
        };
      });
    }
  }, []);

  const handleSelectDayBasedOnSelectedClient = (cli: any) => {
    if (positions.all.length > 1) {
      window.sessionStorage.setItem('routerSelectedDay', new Date(cli.dtProxVisita).toLocaleDateString('pt-BR'));
      const newPositions = positions.all.filter(
        (position: any) =>
          new Date(position.dtProxVisita).toLocaleDateString('pt-BR') ==
          new Date(cli.dtProxVisita).toLocaleDateString('pt-BR'),
      );
      if (newPositions.length > 0) {
        setPositions((prev: PositionsStateProps) => {
          return {
            ...prev,
            current: newPositions,
          };
        });
      }
    }
  };

  return (
    <S.PointMainCard expanded={isCardExpanded}>
      {/* CARD DO TITLE */}
      {point?.clientesDaRota?.filter(
        (cli: any) => cli.codCli.toString().includes(searchText) || cli.nomeCli.includes(searchText),
      ).length > 0 && (
        <S.PointMainCardTitle onClick={() => setIsCardExpanded(!isCardExpanded)}>
          <S.PointMainCardTitleDayString color={cores[point.diaSemana]}>
            <span>{point.diaSemana}</span>

            <S.PointMainCardTitleDay>
              {new Date(point.dtProxVisita).toLocaleDateString('pt-BR').slice(0, 5)}
            </S.PointMainCardTitleDay>
          </S.PointMainCardTitleDayString>
          <S.PointMainCardTitleKm color={cores[point.diaSemana]}>
            <div>
              <span>{point.distancia.toFixed(2)} Km</span>
              <S.PointsCliTotal>Clientes: {point.clientesDaRota.length ?? 0}</S.PointsCliTotal>
            </div>
            <S.CloseCardIcon>{isCardExpanded ? <IoIosArrowUp /> : <IoIosArrowDown />}</S.CloseCardIcon>
          </S.PointMainCardTitleKm>
        </S.PointMainCardTitle>
      )}

      {/* CARD DO PONTO */}
      {point.descricaoPonto.includes(searchText) && (
        <S.PointCard>
          <S.PointCardIcon
            dots={
              point.clientesDaRota.filter(
                (cli: any) => cli.codCli.toString().includes(searchText) || cli.nomeCli.includes(searchText),
              ).length == 0
                ? ''
                : '.........'
            }
          >
            <BsFillHouseFill />
          </S.PointCardIcon>
          <S.PointCardContent>{point.descricaoPonto}</S.PointCardContent>
          <S.PointCardDateTag color={cores[point.diaSemana]}>
            {new Date(point.dtProxVisita).toLocaleDateString('pt-BR')}
          </S.PointCardDateTag>
        </S.PointCard>
      )}

      {point.clientesDaRota
        .filter((cli: any) => cli.codCli.toString().includes(searchText) || cli.nomeCli.includes(searchText))
        .map((cli: any, index: number) => {
          return (
            <S.PointCard
              onClick={() => {
                if (+cli.lat && +cli.lng)
                  setMapSettings((prev: MapSettingsProps) => {
                    return { ...prev, center: [+cli.lat, +cli.lng] };
                  });
                setSelectedMarker(cli);
                handleSelectDayBasedOnSelectedClient(cli);
              }}
              active={selectedMarker && selectedMarker.codCli == cli.codCli}
            >
              <S.PointCardIcon
                color={cli.visitaBloqueio == 'N' ? cores[point.diaSemana] : '#CCCCCC'}
                dots={
                  index ==
                  point.clientesDaRota.filter(
                    (cli: any) => cli.codCli.toString().includes(searchText) || cli.nomeCli.includes(searchText),
                  ).length -
                    1
                    ? ''
                    : '.........'
                }
              >
                <span>{cli.sequencia}</span>
                {cli.visitaBloqueio == 'S' ? (
                  <S.BlockedVisitIcon>
                    <FaLock />
                  </S.BlockedVisitIcon>
                ) : (
                  ''
                )}
              </S.PointCardIcon>
              <S.PointCardContent>
                <S.PointCardCliName color={cli.visitaBloqueio == 'N' ? '#000000' : '#49505799'}>
                  [{cli.codCli}] {cli.nomeCli}
                </S.PointCardCliName>
                <S.PointCardCliData>{`${cli.fantasia ?? ''}`}</S.PointCardCliData>
                <S.PointCardCliData>{`${cli.endereco ?? ''} ${cli.endNumero ?? ''}, ${cli.endBairro ?? ''} - ${
                  cli.endCidade ?? ''
                }`}</S.PointCardCliData>
                <S.PointCardCliData>{`Distancia: ${cli.distancia} Km`}</S.PointCardCliData>
                <S.PointCardCliData
                  color={cli.visitaBloqueio == 'N' ? cores[point.diaSemana] : '#CCCCCC'}
                >{`Regra: ${cli.descPrioridade}`}</S.PointCardCliData>
              </S.PointCardContent>
              {new Date(cli.dtProxVisita).toLocaleDateString('pt-BR') != 'Invalid Date' && (
                <S.PointCardDateTag color={cli.visitaBloqueio == 'N' ? cores[point.diaSemana] : '#CCCCCC'}>
                  {new Date(cli.dtProxVisita).toLocaleDateString('pt-BR')}
                </S.PointCardDateTag>
              )}
            </S.PointCard>
          );
        })}
    </S.PointMainCard>
  );
}
