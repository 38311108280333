import * as S from './itensTable.styles';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { RegraItemProps, RegraProps } from 'client/interfaces';
import { Accordion, Button } from 'reactivus';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { useState } from 'react';
import NovoItemModal from '../novoItemModal/novoItemModal.index';
import { postDeleteRegraItem } from 'client/api/regraComercial';
import { toast } from 'react-toastify';
import { regraItemTypeDescriptions } from '../../regraComercial.types';

type ItensTableProps = {
  selectedRegra: RegraProps;
  codEmpr: number;
  handleGetRegrasComerciais: () => void;
};

export default function ItensTable({ selectedRegra, codEmpr, handleGetRegrasComerciais }: ItensTableProps) {
  const [showNovoItemModal, setShowNovoItemModal] = useState(false);

  const AccordionHeaderTemplate = () => {
    return (
      <S.AccordionHeaderTemplateBox>
        <span>Itens</span>
        <Button
          icon={<FaPlus />}
          color={selectedRegra ? 'success' : 'light'}
          label="Adicionar Itens"
          text
          shadow
          size="sm"
          onClick={(e: any) => {
            e.stopPropagation();
            selectedRegra && setShowNovoItemModal(true);
          }}
          disabled={!selectedRegra}
        />
      </S.AccordionHeaderTemplateBox>
    );
  };

  const removeRestricaoBodyTemplate = (rowData: RegraItemProps) => {
    return (
      <Button
        icon={<FaTrash />}
        color={'danger'}
        text
        onClick={(e: any) => {
          handleRemoveRegraRestricao(rowData);
        }}
      />
    );
  };

  const handleRemoveRegraRestricao = (restricaoToRemove: RegraItemProps) => {
    postDeleteRegraItem([restricaoToRemove])
      .then((res) => {
        if (res.succeeded) {
          handleGetRegrasComerciais();
        }
      })
      .catch((err) => {
        toast.error('Falha inesperada ao remover restrição');
        console.log('err :', err);
      });
  };

  return (
    <Accordion title={'Itens'} alwaysOpen headerTemplate={<AccordionHeaderTemplate />}>
      <S.ItensTableMainBox>
        <S.ItensBodyBox>
          <DataTable
            value={selectedRegra ? selectedRegra.regraItems : []}
            paginator={false}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={100}
            rowHover
            stripedRows
            scrollable
            scrollHeight="57dvh"
            style={{ maxWidth: '100%', minHeight: '57dvh' }}
            emptyMessage={'Esta regra não possui itens'}
          >
            <Column
              field="tiporestricao"
              header="Tipo"
              sortable
              style={{
                maxWidth: '100px',
              }}
              body={(rowData: RegraItemProps) => {
                return regraItemTypeDescriptions[rowData.tiporestricao];
              }}
            />
            <Column
              field="codigorestricao"
              header="Cód."
              sortable
              style={{
                maxWidth: '100px',
              }}
            />
            <Column
              field="descricao"
              header="Descrição"
              sortable
              style={{
                minWidth: '50%',
              }}
            />
            <Column field="" header="Remover" body={removeRestricaoBodyTemplate} />
          </DataTable>
        </S.ItensBodyBox>
      </S.ItensTableMainBox>
      {showNovoItemModal && (
        <NovoItemModal
          codEmpresa={codEmpr}
          handleGetRegrasComerciais={handleGetRegrasComerciais}
          selectedRegra={selectedRegra}
          setShowNovoItemModal={setShowNovoItemModal}
          showNovoItemModal={showNovoItemModal}
        />
      )}
    </Accordion>
  );
}
