import styled from 'styled-components';

export const EyeIcon = styled.i`
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`;

interface isMobileProps {
  isMobile: boolean;
}

export const Header = styled.div<isMobileProps>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;

  img {
    height: ${(props) => (props.isMobile ? '70%' : '70%')};
    width: ${(props) => (props.isMobile ? '30%' : '40%')};
  }
`;

export const Container = styled.div`
  padding: 0 10px;

  @media (max-width: 1060px) {
    padding: 0px !important;
  }
`;

// ---------- # ----------
// Ajustes CCorgz Refactoring

export const LoginMainBox = styled.section``;

export const RegisterButtonFixed = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #00000010;
  display: flex;
  justify-content: center;

  button {
    width: 95% !important;
  }
`;
