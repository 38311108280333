import styled, { keyframes } from 'styled-components';

export const HeaderMainBox = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px;
  border-radius: 5px;
  border: 2px solid #60606020;
  max-width: 97%;
  transition: all 0.4s ease;
`;

export const ARInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: clamp(100px, 200px, 375px);

  input::focus {
    outline: none;
    border: none;
  }
`;
