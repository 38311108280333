import {
  GetKpiItemProps,
  KpiAllPeriodReturnProps,
  KpiAllReturnProps,
  KpiCampanhaReturnProps,
  KpiItemProps,
  KpiProps,
  KpiRunReturnProps,
  KpiToHandledInCampanhaReturnProps,
  KpiToHandleInCampanhaProps,
} from 'client/interfaces';
import api from 'services/api';

const _controller = 'KPI';

export const getAllKpi = async (codEmpresa: string | number): Promise<KpiAllReturnProps> => {
  const { data } = await api.get(`${_controller}/GetAllKpi/${codEmpresa}`);
  return data;
};
export const getAllKpiCampanhas = async (codEmpresa: string | number, codregra: string | number): Promise<KpiCampanhaReturnProps[]> => {
  const { data } = await api.get(`${_controller}/GetAllKpi/${codEmpresa}`);
  const kpis = data.data ?? [];
  console.log('kpis :', kpis);
  const kpisNew = [];
  for (let i = 0; i < kpis.length; i++) {
    kpisNew.push({
      codigorestricao: kpis[i].nrokpi,
      descricao: kpis[i].titulo,
      nroKpi: kpis[i].nrokpi,
      codCampanha: codregra ?? 0
    });
  }
  return kpisNew;
};

export const getAllKpiOnCampanhas = async (
  codEmpresa: string | number,
  codCampanha: string | number,
): Promise<KpiAllReturnProps> => {
  const { data } = await api.get(`${_controller}/GetAllKPICampanha/${codEmpresa}/${codCampanha}`);
  return data;
};

export const getAllPeriodKpi = async (codEmpresa: string | number): Promise<KpiAllPeriodReturnProps> => {
  const { data } = await api.get(`${_controller}/GetPeriodoKPI/${codEmpresa}`);
  return data.data ?? [];
};

export const getItemKpi = async (itemBody: GetKpiItemProps): Promise<KpiItemProps[]> => {
  const { data } = await api.get(
    `${_controller}/GetItemsKPIByCode/${itemBody.codEmpresa}/${itemBody.nroKpi}/${itemBody.descPeriodo}`,
  );
  return data;
};

export const postRunKpi = async (codEmpresa: string | number, nroKpi: string | number): Promise<KpiRunReturnProps> => {
  const { data } = await api.post(`${_controller}/RunKpi/${nroKpi}`);
  return data;
};

export const putEditKpi = async (kpi: KpiProps): Promise<KpiRunReturnProps> => {
  const { data } = await api.post(`${_controller}/PutKpi`, kpi);
  return data;
};

export const postNewKpi = async (kpi: KpiProps): Promise<KpiRunReturnProps> => {
  const { data } = await api.post(`${_controller}/PostKpi`, kpi);
  return data;
};

export const putItemKpi = async (kpi: KpiProps): Promise<KpiRunReturnProps> => {
  const { data } = await api.post(`${_controller}/PutItemKPI`, kpi);
  return data;
};

export const postNewKpiOnCampanha = async (kpis: KpiToHandleInCampanhaProps): Promise<KpiToHandledInCampanhaReturnProps> => {
  const { data } = await api.post(`${_controller}/PostKpiCampanha`, kpis);
  return data;
};
export const postDeleteKpiOnCampanha = async (kpis: KpiToHandleInCampanhaProps): Promise<KpiToHandledInCampanhaReturnProps> => {
  const { data } = await api.post(`${_controller}/DeleteKpiCampanha`, kpis);
  return data;
};