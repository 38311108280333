import { useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { useEffect, useMemo } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { BsCheckLg } from 'react-icons/bs';
import { toast } from 'react-toastify';

import { duplicateItems, duplicateRestricoes, saveForm, updateForm } from 'client/api';
import { FormularioCab, MotivoNaoVendaProps } from 'client/interfaces';
import InputData from 'components/InputData';

import styles from './NewFormDialog.module.scss';
import * as S from './styles';
import useMediaQuery from 'hooks/useMediaQuery';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import VmButton from 'components/VmButton/VmButton';
import { MultiSelect } from 'primereact/multiselect';
import { useMotivosNaoVenda } from 'client/hooks';

interface NewFormDialogProps {
  form: FormularioCab | null;
  onClose: () => void;
  visible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
  clonedItem: number | null;
  codEmpresa?: number;
}

const NewFormDialog = ({ form, visible, setIsVisible, onClose, clonedItem, codEmpresa }: NewFormDialogProps) => {
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm<FormularioCab>({ defaultValues: form ?? undefined });
  const queryClient = useQueryClient();
  const isWebScreen = useMediaQuery('(min-width: 1060px)');
  const { selectedEmpresa } = UseSelectEmpresa();
  const { data: motivosNaoVenda } = useMotivosNaoVenda(codEmpresa);

  const onSubmit: SubmitHandler<FormularioCab> = async (data) => {
    data.codEmpresa = codEmpresa ?? selectedEmpresa.codEmpresa;
    try {
      if (form?.nroform) {
        await updateForm(data).then(() => {
          toast.success('Formulário atualizado com sucesso!');
        });
      } else {
        if (clonedItem == null) {
          await saveForm(data);
          toast.success('Formulário salvo com sucesso!');
        } else {
          confirmDialog({
            message: 'Deseja replicar também as restrições?',
            header: 'Replicar Restrições',
            icon: 'pi pi-question-circle',
            accept: async () => {
              const nro = await saveForm(data);
              await duplicateItems(clonedItem, nro, selectedEmpresa.codEmpresa);
              await duplicateRestricoes(clonedItem, nro, selectedEmpresa.codEmpresa);
              toast.success('Formulário clonado com sucesso!');
            },
            reject: async () => {
              const nro = await saveForm(data);
              await duplicateItems(clonedItem, nro, selectedEmpresa.codEmpresa);
              toast.success('Formulário clonado com sucesso!');
            },
          });
        }
      }
      setIsVisible(false);
      queryClient.invalidateQueries(['forms/cabecalho']);
    } catch (e) {
      setIsVisible(false);
      toast.error('Falha ao salvar formulário');
    }
  };

  useEffect(() => {
    setValue('exibeNaoVendaMotivos', form ? form.exibeNaoVendaMotivos ?? '' : '');
    setValue('fotoCategoria', form ? form.fotoCategoria ?? '' : '');
    setValue('exibeVenda', form ? form.exibeVenda ?? '' : 'N');
  }, [form]);

  const foraDeVigencia = useMemo(
    () => watch().dtfimvigencia != null && new Date(watch().dtfimvigencia) < new Date(),
    [watch().dtfimvigencia],
  );

  const header = useMemo(() => {
    if (form?.nroform) return `Editar Formulário ${form.nroform}`;
    if (clonedItem) return `Clonar Formulário ${clonedItem}`;
    return 'Novo Formulário';
  }, [form]);

  const handleMotivosNaoVendaSelection = (selectedString: string): MotivoNaoVendaProps[] | null => {
    const selectedArrayString = selectedString.split(`,`);
    const selectedArray = [];
    for (let i = 0; i < selectedArrayString.length; i++) {
      const selectedMotivo = motivosNaoVenda?.filter(
        (motivo: MotivoNaoVendaProps) => motivo.codMotivo == +selectedArrayString[i],
      );
      selectedMotivo && selectedArray.push(selectedMotivo[0].codMotivo);
    }
    return selectedArray ? selectedArray : null;
  };

  return (
    <Dialog header={header} visible={visible} style={{ width: isWebScreen ? '30vw' : '90vw' }} onHide={onClose}>
      <S.FormContainer onSubmit={handleSubmit(onSubmit)} className="form w-100">
        <S.NewFormRow>
          <S.NewFormInputBox>
            <label>* Descrição</label>
            <input
              {...register('descricao', { required: 'Obrigatório' })}
              placeholder="Descrição"
              className={clsx('form-control form-control-lg form-control-solid', {
                'is-invalid': (dirtyFields.descricao ?? false) && errors.descricao,
              })}
              autoFocus
              maxLength={300}
              type="descricao"
              name="descricao"
              width={'100%'}
              style={{
                width: '195%',
              }}
            />
            {errors.descricao != null && (
              <div className="fv-plugins-message-container invalid-feedback">
                <span role="alert">{errors.descricao.message}</span>
              </div>
            )}
          </S.NewFormInputBox>
        </S.NewFormRow>

        <S.NewFormRow>
          <S.NewFormRow>
            <S.NewFormInputBox>
              <label>* Cód. Filial</label>
              <input
                {...register('codfilial', { required: 'Obrigatório' })}
                placeholder="Cód. Filial"
                className={clsx('form-control form-control-lg form-control-solid', {
                  'is-invalid': (dirtyFields.codfilial ?? false) && errors.codfilial,
                })}
                autoFocus
                maxLength={6}
                type="codfilial"
                name="codfilial"
              />
              {errors.codfilial != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.codfilial.message}</span>
                </div>
              )}
            </S.NewFormInputBox>

            <S.ButtonsBox>
              <S.NewFormInputBox className={styles.flex1}>
                <label>Obrigatório</label>
                <Controller
                  control={control}
                  name="obrigatorio"
                  render={({ field: { onChange, value } }) => <InputSwitch checked={value} onChange={onChange} />}
                />
              </S.NewFormInputBox>

              <S.NewFormInputBox>
                <label>Ativo</label>
                <Controller
                  control={control}
                  name="ativo"
                  render={({ field: { onChange, value } }) => (
                    <InputSwitch
                      disabled={foraDeVigencia}
                      checked={value && !foraDeVigencia}
                      onChange={onChange}
                      tooltip="Fora do período de vigência"
                      tooltipOptions={{
                        disabled: !foraDeVigencia,
                        showOnDisabled: true,
                      }}
                    />
                  )}
                />
              </S.NewFormInputBox>
            </S.ButtonsBox>
          </S.NewFormRow>
        </S.NewFormRow>

        <S.NewFormRow>
          <S.NewFormInputBox>
            <label>* Data Início</label>
            <Controller
              control={control}
              name="dtiniciovigencia"
              rules={{ required: 'Obrigatório' }}
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <InputData
                    value={value ? new Date(value) : null}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    mask="99/99/9999"
                    onChange={(e) => onChange((e.value as Date).toISOString())}
                  />
                  {errors.dtiniciovigencia != null && (
                    <div className="fv-plugins-message-container invalid-feedback">
                      <span role="alert">{fieldState.error?.message}</span>
                    </div>
                  )}
                </>
              )}
            />
          </S.NewFormInputBox>

          <S.NewFormInputBox>
            <label>* Data Fim</label>
            <Controller
              control={control}
              name="dtfimvigencia"
              rules={{ required: 'Obrigatório' }}
              render={({ field: { onChange, value }, fieldState }) => (
                <>
                  <InputData
                    value={value ? new Date(value) : null}
                    dateFormat="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    mask="99/99/9999"
                    onChange={(e) => onChange((e.value as Date).toISOString())}
                  />
                  {errors.dtfimvigencia != null && (
                    <div className="fv-plugins-message-container invalid-feedback">
                      <span role="alert">{fieldState.error?.message}</span>
                    </div>
                  )}
                </>
              )}
            />
          </S.NewFormInputBox>
        </S.NewFormRow>

        <S.NewFormRow>
          <S.NewFormInputBox>
            <label>Exibir na Venda</label>
            <Controller
              control={control}
              name="exibeVenda"
              render={({ field: { onChange, value } }) => (
                <InputSwitch
                  checked={value == 'S'}
                  onChange={(e) => {
                    onChange(e.value ? 'S' : 'N');
                  }}
                />
              )}
            />
          </S.NewFormInputBox>

          <S.NewFormInputBox>
            <label>Exibir p/ Motivo de Não Venda</label>
            <Controller
              name="exibeNaoVendaMotivos"
              control={control}
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  placeholder="Exibir p/ Motivo de Não Venda"
                  value={value ? handleMotivosNaoVendaSelection(value ?? '') : ''}
                  options={motivosNaoVenda ?? []}
                  onChange={(e) => {
                    onChange(e.value.join(`,`));
                  }}
                  optionLabel="descMotivo"
                  optionValue="codMotivo"
                  itemTemplate={(motivo) => {
                    return `[${motivo.codMotivo}] ${motivo.descMotivo}`;
                  }}
                  filter
                  display="chip"
                  emptyFilterMessage={'Nenhum motivo disponível'}
                />
              )}
            />
          </S.NewFormInputBox>
        </S.NewFormRow>

        <S.NewFormRow
          style={{
            alignItems: `flex-end`,
          }}
        >
          <S.NewFormInputBox>
            <label>Imagem FV</label>
            <S.FormFvImage onClick={(e) => {}}>
              <img
                alt={'ImagemFV'}
                src={`${process.env.REACT_APP_BASE_URL}/categoria_formulario/${
                  !form || (form.fotoCategoria && form.fotoCategoria == '')
                    ? 'sem-imagem.jpeg'
                    : form.fotoCategoria ?? 'sem-imagem.jpeg'
                }`}
              />
            </S.FormFvImage>
          </S.NewFormInputBox>

          <S.ButtonsBox>
            <VmButton
              type="submit"
              options={{
                icon: <BsCheckLg />,
                label: 'Salvar',
                style: 'btn-success',
                width: '120px',
                iconPosition: 'left',
                size: 'btn-md',
              }}
            />
          </S.ButtonsBox>
        </S.NewFormRow>
      </S.FormContainer>

      <ConfirmDialog />
    </Dialog>
  );
};

export default NewFormDialog;
