import { DirectionsRenderer, GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api';
import L from 'leaflet';
import { Dropdown } from 'primereact/dropdown';
import { useContext, useEffect, useState } from 'react';
import { AiFillFileExcel, AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import { BsFillCalendar3WeekFill } from 'react-icons/bs';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { FcSettings } from 'react-icons/fc';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdEditCalendar, MdOutlineMap, MdViewList } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TITLE_PAGE } from 'enums/titlePages';
import AddressModal from 'modules/mapa/components/addressModal/AddressModal';
import LoadCliMap from 'modules/mapa/components/loadCliMap/loadCliMap';
import { MapContext } from 'providers/map';

import { useScreenwriterStore } from 'storesZustand/screenwriter';
import { useTitlePageStore } from 'storesZustand/titlePageStore';

import clientSvg1 from '../../../../assets/svgs/clientSvg1';
import HomeSvg from '../../../../assets/svgs/homeSvg';
import VmButton from '../../../../components/VmButton/VmButton';
import RouterCliClist from '../../components/routerCliList/routerCliList';
import { getCliCompData, getCliData, paginate } from './clientes';

import { postRouterData } from './components/executaRoteiro/executaRoteiro';
import MapZoomControl from './components/mapZoom';
import NextRouterPagination from './components/nextRouterPagination/index';

import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import { getRouterStartPointsData } from 'client/api/ponto';
import * as S from './styles';

import useMediaQuery from 'hooks/useMediaQuery';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ExecRouterModal } from './components/execRouterModal/execRouterModal';
import RouterSideBar from './components/routerSideBar/index';
import CliPopUp from './components/cliPopUp';
import AlterDtProxVisitaModal from './components/alterDtProxVisitaModal';
import { Button, Container, dialog, Tag } from 'reactivus';
import { RadioButton } from 'primereact/radiobutton';
import { useLoaderEffect } from 'providers/loaderEffect';

interface ICoord {
  lat: number;
  lng: number;
}

interface IPonto {
  sequencia: number;
  codCli?: number;
  nomeCli?: string;
  lat: number;
  lng: number;
  diaSemana?: string;
  dtProxVisita?: string;
  cor?: string;
  freqVisita?: number;
  distancia?: number;
  visitaBloqueio: string;
}

const Roteirizador = () => {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { setLoader } = useLoaderEffect();

  // CRIA INSTÂNCIA DE NEVEGAÇÃO DO REACT ROUTER DOM
  const navigate = useNavigate();
  const {
    codMapa: codMapaParam,
    codSetor: codSetorParam,
    codVendedor: codVendedorParam,
    codEmpresa: codEmpresaParam,
  } = useParams<{ codMapa: string; codEmpresa: string; codSetor: string; codVendedor: string }>();
  const { setParamsCodScreenwriter } = useScreenwriterStore();

  // IMPORTA PARAMETROS DE SETOR A SE ROTEIRIZAR
  const { getRoteirizaData, setLoadCliMap, markersControler } = useContext(MapContext);
  const { setTitlePage, titlePage } = useTitlePageStore();

  // DEFINE ARRAY DE CORES DE ACORDO COM DIA DA SEMANA
  const cores: any = {
    SEGUNDA: '#32ba70',
    TERÇA: '#375cff',
    QUARTA: '#ff6143',
    QUINTA: '#7328b3',
    SEXTA: '#e52755',
    SABADO: '#eed63b',
    DOMINGO: '#FF8C00',
  };

  // DEFINE STATE DE CENTRO DO MAPA
  const [mapCenter, setMapCenter] = useState<ICoord>({ lat: -15.79347680799033, lng: -47.882663471032025 });
  
  // DEFINE STATE DE CONTROLE DO ZOOM ATUAL DO MAPA
  const [mapZoom, setMapZoom] = useState<{ zoom: number; maxZoom: number }>({
    zoom: 8,
    maxZoom: 17,
  });

  // DEFINE STATE COM POSIÇÕES DO MAPA
  const [positions, setPositions] = useState<any>([]);

  const [routeTotalKm, setRouterTotalKm] = useState<number>(0);

  // DEFINE STATE ARRAY PARA FILTRO DAS POSIÇÕES
  const [currentPositions, setCurrentPositions] = useState<any>([]);

  const [compPositions, setCompPositions] = useState<any>([]);

  // DEFINE STATE DE CONTROLE DA PAGINAÇÃO
  const [page, setPage] = useState<string>('');
  const [pages, setPages] = useState<any>([]);

  // DEFINE STATE DE CONTROLE DE EXIBIÇÃO DO MAPA OU LISTA DE CLIENTES
  const [showMap, setShowMap] = useState<boolean>(false);

  const [showAlterDtProxVisitaModal, setShowAlterDtProxVisitaModal] = useState(false);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAUHxQUnO76uq2HBu2X6xzaLZPapIFv--0',
    libraries: ['drawing', 'places'],
  });

  useEffect(() => {
    if (isLoaded) {
      setShowMap(isLoaded);
    } else {
      window.sessionStorage.removeItem('routerSelectedMarker');
      window.sessionStorage.removeItem('routerSelectedDay');
    }
    setLoadCliMap(false);
  }, [isLoaded]);

  // DEFINE STATE DE CONTROLE DO MARKER SELECIONADO
  const [selectedMarker, setSelectedMarker] = useState<any>(null);

  // FUNÇÃO QUE TRATA O FECHAMENTO DE UM POPUP
  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  useEffect(() => {
    renderPositions();
  }, []);

  const handleGetSelectedMarkerFromSession = () => {
    let routerSelectedMarker: any = window.sessionStorage.getItem('routerSelectedMarker');
    if (routerSelectedMarker && routerSelectedMarker.length > 0) {
      routerSelectedMarker = JSON.parse(routerSelectedMarker);
      setSelectedMarker(routerSelectedMarker);
      setMapCenter({ lat: +routerSelectedMarker.lat, lng: +routerSelectedMarker.lng });
    } else if (positions[0]) {
      setMapCenter({ lat: +positions[0].latitudePonto, lng: +positions[0].longitudePonto });
    }
  };

  useEffect(() => {
    renderPositions();
    handleGetSelectedMarkerFromSession();
  }, [currentPositions, showMap]);

  useEffect(() => {
    if (page !== '') {
      // DETERMINA ZOOM INICIAL DO MAPA OCM PRIMEIRO PONTO DO ARRAY DE CLIENTES
      handleGetSelectedMarkerFromSession();
      setCurrentPositions(positions);
      validateWeekDates();
      renderPositions();
    }
    handleRenderCompMarkers();
  }, [page]);

  useEffect(() => {
    handleRenderCompMarkers();
  }, [compPositions, currentPositions, markersControler]);

  // DEFINE STATE QUE CONTROLA EXIBIÇÃO DOS BOTÕES DE PAGINAÇÃO
  const [showPrev, setShowPrev] = useState<boolean>(false);
  const [showNext, setShowNext] = useState<boolean>(true);

  // FUNÇÃO QUE TRATA A PAGINAÇÃO
  const handlePages = (prev?: boolean) => {
    // DEFINE VALOR QUE CONTROLA DIREÇÃO DA PAGINAÇÃO
    const movePrev = prev != undefined ? prev : false;

    // DEFINE VALOR DO INDEX DA PÁGINA ATUAL NO ARRAY DE PÁGINAS
    const actualIndex = pages.findIndex((el: any) => {
      if (el === page) {
        return true;
      } else {
        return false;
      }
    });
    // DEFINE VALOR DO ÚLTIMO INDEX DO ARRAY
    const lastIndex = pages.length - 1;

    if (!movePrev) {
      if (actualIndex < lastIndex) {
        setPage(pages[actualIndex + 1]);
        setShowPrev(true);
      } else {
        setShowPrev(false);
      }
      if (actualIndex + 1 === lastIndex) {
        setShowNext(false);
      }
    } else {
      if (actualIndex > 0) {
        setPage(pages[actualIndex - 1]);
        setShowNext(true);
      } else {
        setShowNext(false);
      }
      if (actualIndex - 1 === 0) {
        setShowPrev(false);
      } else {
        setShowPrev(true);
      }
    }

    return;
  };

  // BLOCO USE EFFECT A SE EXECUTAR NO PRIMEIRO RENDER DO COMPONENTE
  useEffect(() => {
    setSelectedRouter({ name: 'Linhas Diretas', code: 3 });
    handleGetCliData();
    setParamsCodScreenwriter({
      codEmpresa: codEmpresaParam,
      codVendedor: codVendedorParam,
      codMapa: codMapaParam,
      codSetor: codSetorParam,
    });
  }, [codEmpresaParam, codSetorParam, codVendedorParam]);

  // VALIDA EM QUAL PÁGINA INICIAR O ROTEIRIZADOR
  const handleRouterStartPage = (positions: [], pagesList: string[]): string => {
    let newPageToStart = pagesList[0];
    const actualDate = new Date().toLocaleDateString('pt-BR');
    for (let i = 0; i < positions.length; i++) {
      const rota: any = positions[i];
      const visitaDate = new Date(rota.dtProxVisita).toLocaleDateString('pt-BR');
      if (actualDate == visitaDate) {
        newPageToStart = `${rota.semana}/${rota.mes}`;
        break;
      }
    }
    return newPageToStart;
  };

  // BUSCA DADOS DE CLIENTES DO ROTEIRIZADOR
  const handleGetCliData = () => {
    setLoader({
      show: true,
      text: `Buscando roteiro, por favor aguarde...`,
    });
    getCliData(codSetorParam, codVendedorParam, codEmpresaParam)
      .then((res) => {
        if (res.length > 0) {
          setIsRouterButtonAble(true);
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              res[i].distancia = 0;
              for (let j = 0; j < res[i].clientesDaRota.length; j++) {
                res[i].distancia = +res[i].distancia + +res[i].clientesDaRota[j].distancia;
              }
            }
          }
          setPositions(res);
          setCurrentPositions(res);
          const pagesList: any = paginate(res);
          const pageToStart = handleRouterStartPage(res, pagesList);
          setPages(pagesList);
          setPage(pageToStart ?? pagesList[0]);
          if (pagesList[0] != pageToStart) {
            setShowPrev(true);
          }

          setMapZoom({
            zoom: 14,
            maxZoom: 22,
          });
          setMapCenter({ lat: +res[0].latitudePonto, lng: +res[0].longitudePonto });

          getCliCompData(codSetorParam, codEmpresaParam)
            .then((res) => {
              setCompPositions(res);
              handleRenderCompMarkers();
            })
            .catch((err) => {
              console.log('err :', err);
            });
        } else {
          checkStartCoord();
          setPositions([]);
          setCurrentPositions([]);
          setPages([]);
          setPage('');
          handleShowInfoModal();
          setShowExecRouterModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader({
          show: false,
          text: ``,
        });
      });
  };

  // DEFINE STATE QUE CONTROLA EXIBIÇÃO DA MODAL DE ENDEREÇO
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [forcePointRegister, setForcePointRegister] = useState(true);

  useEffect(() => {
    if (forcePointRegister) {
      checkStartCoord();
    }
  }, [showAddressModal]);

  // DEFINE STATE QUE CONTROLA O STATUS DO BOTÃO DO ROTEIRIZADOR
  const [isRouterButtonAble, setIsRouterButtonAble] = useState(false);

  // FUNÇÃO QUE VALIDA EXISTENCIA DE PONTO INICIAL
  const checkStartCoord = () => {
    getRouterStartPointsData(codEmpresaParam)
      .then((res) => {
        const pontoRca = res?.filter((ponto: any) => +ponto.codVendedor === +codVendedorParam);
        if (pontoRca.length === 0) {
          if (forcePointRegister) {
            setShowAddressModal(true);
            setForcePointRegister(false);
          }
          setIsRouterButtonAble(false);
        } else {
          setShowAddressModal(false);
          setIsRouterButtonAble(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // EXIBE MODAL INFORMATIVA SOBRE ROTEIRO
  const [showExecRouterModal, setShowExecRouterModal] = useState<boolean>(false);

  const handleShowInfoModal = () => {
    setShowExecRouterModal(showAddressModal ? false : true);
  };

  useEffect(() => {
    if (showExecRouterModal) {
      setShowExecRouterModal(showAddressModal ? false : true);
    }
    handleGetCliData();
  }, [showAddressModal]);

  // FUNÇÃO DE MARKER CUSTOMIZADO
  const customMarkerIcon = (color: string, value: number) => {
    let svgTemplate: any = '';
    if (value === 0) {
      svgTemplate = {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(HomeSvg),
      };
    } else {
      svgTemplate = {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(clientSvg1(value, color)),
      };
    }
    return svgTemplate;
  };

  // CRIA STATE QUE ARMAZENA ROTAS RENDERIZADAS
  const [renderedRoutes, setRenderedRoutes] = useState<any>([]);
  const [renderedMarkers, setRenderedMarkers] = useState<any>(<></>);
  const [renderedLines, setRenderedLines] = useState<any>(<></>);
  const [renderedCompMarkers, setRenderedCompMarkers] = useState<any>(<></>);

  interface Location {
    lat: number;
    lng: number;
  }

  interface LocationObject {
    location: Location;
  }

  function removeDuplicates(array: LocationObject[]): LocationObject[] {
    const uniqueLocations = new Set<string>();

    const filteredArray = array.filter((item) => {
      const locationString = `${item.location.lat}-${item.location.lng}`;
      if (!uniqueLocations.has(locationString)) {
        uniqueLocations.add(locationString);
        return true;
      }
      return false;
    });

    return filteredArray;
  }

  // FUNÇÃO QUE RETORNA AS ROTAS ENTRE OS PONTOS NO MAPA
  async function leafletRouterRender(pontos: any, cor: string) {
    // LIMPA O ESTADO
    setRenderedRoutes([
      {
        routes: {
          routes: [],
        },
        color: cor,
      },
    ]);
    setRenderedUniqueRoutes([
      {
        routes: {
          routes: [],
        },
        color: cor,
      },
    ]);

    let response: any = {};
    const directionsService = new window.google.maps.DirectionsService();
    const waypoints = pontos.map((coords: any) => {
      return { location: { lat: +coords.lat, lng: +coords.lng } };
    });
    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    const newwaypoints = removeDuplicates(waypoints);

    if (!showMap || newwaypoints.length > 25) {
      console.log(`Limite de pontos simultâneos atingido! Pontos: ${newwaypoints.length} | Máx: 25`);
      setSelectedRouter({ name: 'Linhas Diretas', code: 3 });
      return;
    }

    await directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: newwaypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          response = result;
          const legs = response.routes[0].legs;
          let total = 0;
          for (let i = 0; i < legs.length; i++) {
            total += legs[i].distance.value;
          }
          setRouterTotalKm(+(total / 1000).toFixed(2));
        } else {
          toast.error('Falha ao exibir rotas!');
          console.error(`Directions request failed with status: ${status}`);
        }
      },
    );
    setRenderedRoutes([
      {
        routes: response,
        color: cor,
      },
    ]);

    return;
  }

  // DEFINE STATE DE RENDERIZAÇÃO DE ROTAS UNITÁRIAS
  const [renderedUniqueRoutes, setRenderedUniqueRoutes] = useState([]);

  // FUNÇÃO QUE RETORNA AS ROTAS ENTRE OS PONTOS NO MAPA
  async function handleUniqueRouteRender(pontos: any, distancia: number) {
    setMapZoom({
      zoom: 15,
      maxZoom: distancia >= 0.5 ? 16 : distancia > 0.25 ? 17 : distancia > 0.1 ? 18 : distancia < 0.05 ? 20 : 18,
    });
    if (!showMap || pontos.length >= 25) {
      return;
    }

    let cor = '#202020';
    // LIMPA O ESTADO
    setRenderedUniqueRoutes([
      {
        routes: {
          routes: [],
        },
        color: cor,
      },
    ]);

    let response: any = {};
    const directionsService = new window.google.maps.DirectionsService();
    const waypoints = pontos.map((coords: any) => {
      return { location: { lat: +coords.lat, lng: +coords.lng } };
    });

    const newwaypoints = removeDuplicates(waypoints);

    if (!showMap || newwaypoints.length > 25) {
      console.log(`Limite de pontos simultâneos atingido! Pontos: ${newwaypoints.length} | Máx: 25`);
      setRouterTotalKm(0);
      setSelectedRouter({ name: 'Linhas Diretas', code: 3 });
      return;
    }

    const origin = waypoints.shift().location;
    const destination = waypoints.pop().location;

    await directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          response = result;
        } else {
          toast.error('Falha ao exibir rotas!');
          console.error(`Directions request failed with status: ${status}`);
          setRouterTotalKm(0);
        }
      },
    );
    setRenderedUniqueRoutes([
      {
        routes: response,
        color: cor,
      },
    ]);
    setTimeout(() => {
      distancia > 0.5 &&
        setMapZoom((prevState: any) => {
          return {
            ...prevState,
            maxZoom: mapZoom.zoom < 20 ? 20 : mapZoom.zoom + 1,
          };
        });
    }, 2000);
    return;
  }

  const [selectedRouter, setSelectedRouter] = useState<any>({ name: 'Linhas Diretas', code: 1 });
  useEffect(() => {
    renderPositions();

    const ktBody = document.getElementById('kt_body');
    ktBody.setAttribute('data-kt-aside-minimize', 'on');
  }, [selectedRouter]);

  // FUNÇÃO QUE RENDERIZA POSIÇÕES
  const renderPositions = () => {
    if (selectedRouter.code !== 2 && renderedRoutes[0] && renderedRoutes[0].routes.routes.length > 0) {
      setRenderedRoutes([
        {
          routes: {
            routes: [],
          },
          color: '#FFFFFF00',
        },
      ]);
    }
    if (selectedRouter.code === 1) {
      setRenderedLines(<></>);
      setRenderedUniqueRoutes([
        {
          routes: {
            routes: [],
          },
          color: '#202020',
        },
      ]);
    }

    let posList = currentPositions.filter((rout: any) => page == rout.semana + '/' + rout.mes);

    let points: any = [<></>];

    posList.map((pos: any) => {
      points.push(
        <Marker
          icon={customMarkerIcon(pos.visitaBloqueio == 'S' ? '#CCCCCC' : cores[pos.diaSemana], 0)}
          key={Math.random()}
          position={{ lat: +pos.latitudePonto, lng: +pos.longitudePonto }}
          onClick={(e: any) => {
            pos.lat = pos.latitudePonto;
            pos.lng = pos.longitudePonto;
            pos.nomeCli = pos.descricaoPonto;
            setSelectedMarker(pos);
          }}
        />,
      );

      points.push(
        pos.clientesDaRota.map((position: IPonto, index: any) => {
          return (
            <Marker
              icon={customMarkerIcon(
                position.visitaBloqueio == 'S' ? '#818080' : cores[position?.diaSemana ?? 'SEGUNDA'],
                position.sequencia,
              )}
              key={position.codCli + Math.random()}
              position={{ lat: +position.lat, lng: +position.lng }}
              onClick={(e: any) => {
                setSelectedMarker(position);
              }}
            />
          );
        }),
      );
    });
    if (showMap) setRenderedMarkers(points);

    if (selectedRouter.code === 2) {
      let pontos = [];
      let cor = '#cb2a29';
      if (posList[0]) {
        pontos.push(L.latLng(posList[0].latitudePonto, posList[0].longitudePonto));
        cor = cores[posList[0].diaSemana];
      }
      posList?.map((rota: any) => {
        rota.clientesDaRota?.map((cli: any) => {
          pontos.push({ lat: cli.lat, lng: cli.lng, cor: cor });
        });
      });
      leafletRouterRender(pontos, cor);
    }

    let polyLines = [<></>];
    if (selectedRouter.code === 3) {
      setRenderedUniqueRoutes([
        {
          routes: {
            routes: [],
          },
          color: '#202020',
        },
      ]);
      posList.map((pos: any, index: any) => {
        let linePoints = [];

        linePoints?.push({ diaSemana: pos.diaSemana, lat: +pos.latitudePonto, lng: +pos.longitudePonto });

        pos.clientesDaRota?.map((position: IPonto, index: any) =>
          linePoints.push({ diaSemana: position.diaSemana, lat: +position.lat, lng: +position.lng }),
        );

        polyLines.push(
          <Polyline
            key={pos.diaSemana + pos.mes + pos.dtProxVisita + pos.latitudePonto}
            options={{ strokeColor: cores[pos.diaSemana] }}
            path={linePoints}
          />,
        );
      });
    }
    if (showMap) setRenderedLines(polyLines);
  };

  const handleRenderCompMarkers = () => {
    try {
      let compMarkers = [<></>];
      let posList = currentPositions.filter((rout: any) => page == rout.semana + '/' + rout.mes);
      let allCurrentCli = [];
      const higherDate =
        posList[posList.length - 1] && new Date(posList[posList.length - 1].dtProxVisita)
          ? new Date(posList[posList.length - 1].dtProxVisita)
          : '';
      for (let i = 0; i < posList.length; i++) {
        allCurrentCli = [...allCurrentCli, ...posList[i].clientesDaRota];
      }

      const isCliInRoute = (cli: any) => {
        let resp = false;
        for (let i = 0; i < allCurrentCli.length; i++) {
          if (allCurrentCli[i].codCli == cli.codCli) {
            resp = true;
          }
        }
        return resp;
      };

      let newCompPositions = compPositions;
      let newCompPositionsNotRouted = compPositions.filter((c: any) => c.estaEmRota != 'S');

      const routedPoints = compPositions.filter(
        (p: any) => p.estaEmRota == 'S' && p.dtProxVisita && new Date(p.dtProxVisita) > new Date(),
      );
      routedPoints.sort((a: any, b: any) => {
        const dateA = new Date(a.dtProxVisita);
        const dateB = new Date(b.dtProxVisita);
        return dateA.getTime() - dateB.getTime();
      });

      let allClients = [];

      for (let i = 0; i < compPositions.length; i++) {
        if (compPositions[i].clientesDaRota) {
          allClients = allClients.concat(compPositions[i].clientesDaRota);
        }
      }
      let filteredRoutedPoints = routedPoints.filter(
        (point: any) => !allClients.some((position) => position.codCli === point.codCli),
      );

      const newFilteredRoutedPoints = [];
      for (let i = 0; i < filteredRoutedPoints.length; i++) {
        let isCliInArray = newFilteredRoutedPoints.findIndex(
          (cli: any) => cli.codCli === filteredRoutedPoints[i].codCli,
        );

        if (isCliInArray == -1) newFilteredRoutedPoints.push(filteredRoutedPoints[i]);
      }

      newCompPositions = [...newFilteredRoutedPoints, ...newCompPositionsNotRouted];

      newCompPositions.map((p: any) => {
        if (
          (markersControler.notInRoute && p.estaEmRota == 'N') ||
          (markersControler.nextWeeks &&
            !isCliInRoute(p) &&
            p.estaEmRota == 'S' &&
            p.dtProxVisita &&
            new Date(p.dtProxVisita) > higherDate)
        )
          compMarkers.push(
            <Marker
              icon={{
                url: p.urlIcon,
              }}
              key={Math.random()}
              position={{ lat: +p.lat, lng: +p.lng }}
              onClick={() => {
                setSelectedMarker(p);
              }}
            />,
          );
      });
      setRenderedCompMarkers(compMarkers);
      renderPositions();
    } catch (err) {
      console.log('err :', err);
    }
  };

  // DEFINE STATE E EXIBIÇÃO DO NOME DA SEMANA POR COMPLETO
  const [weekName, setWeekName] = useState<string>('');

  const validateWeekDates = () => {
    const data = positions.filter((rout: any) => page === rout.semana + '/' + rout.mes);

    let month = new Date(data[0] ? data[0].dtProxVisita : '').toLocaleString('default', { month: 'short' });
    month = month.replace('.', '')[0].toUpperCase() + month[1] + month[2];

    let mindate = new Date(data[0] ? data[0].dtProxVisita : '').getDate();
    let maxdate = new Date(data[data.length - 1] ? data[data.length - 1].dtProxVisita : '').getDate();
    setWeekName(`${page.split('/')[0]} | ${mindate}-${maxdate}/${month}`);
  };

  const [showFloatMenu, setShowFloatMenu] = useState(false);
  const routerType = [
    { name: 'Ocultar', code: 1 },
    { name: 'Rotas', code: 2 },
    { name: 'Linhas Diretas', code: 3 },
  ];

  // DEFINE STATE QUE TRATA LOADING DO ROTEIRIZADOR
  const [routerLoading, setRouterLoading] = useState<boolean>(false);

  // DEFINE STATE DE LOADING DO BOTÃO DE ROTEIRIZAR
  const [routerTimer, setRouterTimer] = useState(0);

  // FUNÇÃO QUE DECREMENTA O TIMER DO ROUTER
  const handleRouterTimer = () => {
    let timer = 30;
    setRouterTimer(timer);

    setInterval(() => {
      if (timer - 1 >= 0) {
        timer--;
        setRouterTimer(timer);
      }
    }, 1000);
  };

  const ConfirmPostRouterLayout = () => {
    const [plusDaysToProccessRoute, setPlusDaysToProccessRoute] = useState(null);
    const [showErrorButton, setShowErrorButton] = useState(false);
    return (
      <Container flexDirection="column" gap="30px" width="100%">
        <Container flexDirection="row" gap="30px" width="100%">
          <Container gap="5px" flexDirection="row">
            <RadioButton
              inputId="hoje"
              name="hoje"
              value="0"
              onChange={(e) => {
                setPlusDaysToProccessRoute(e.value);
                showErrorButton && setShowErrorButton(false);
              }}
              checked={plusDaysToProccessRoute == 0}
            />
            <label htmlFor="hoje">Hoje</label>
          </Container>
          <Container gap="5px">
            <RadioButton
              inputId="amanha"
              name="amanha"
              value="1"
              onChange={(e) => {
                setPlusDaysToProccessRoute(e.value);
                showErrorButton && setShowErrorButton(false);
              }}
              checked={plusDaysToProccessRoute == 1}
            />
            <label htmlFor="amanha">Amanhã</label>
          </Container>
        </Container>
        {showErrorButton && <Tag color='danger' label='Selecione uma opção' text />}
        <Button
          label="Confirmar"
          color={!plusDaysToProccessRoute ? 'dark' : 'success'}
          size="md"
          width="150px"
          onClick={() => {
            if (plusDaysToProccessRoute) {
              handleExecPostRouter(plusDaysToProccessRoute);
              dialog.hide();
            }else{
              setShowErrorButton(true);
            }
          }}
        />
      </Container>
    );
  };

  // FUNÇÃO QUE TRATA NOVO PROCESSAMENTO DE ROTAS
  const handlePostRouter = () => {
    dialog
      .show({
        icon: 'question',
        title: 'Processar roteiro a partir de:',
        showConfirmButton: false,
        showCancelButton: false,
        confirmButtonText: 'Hoje',
        cancelButtonText: 'Amanhã',
        cancelButtonStyle: 'info',
        allowClose: true,
        showCloseButton: true,
        htmlx: <ConfirmPostRouterLayout />,
      })
      .then((res) => {
        dialog.hide();
      });
  };

  const handleExecPostRouter = (pProcessaDia: number) => {
    getRoteirizaData()
      .then((routerData: any) => {
        setRouterLoading(true);
        postRouterData(codSetorParam, codVendedorParam, codEmpresaParam, pProcessaDia)
          .then((res: any) => {
            setRouterLoading(false);
            handleRouterTimer();
            if (res && res.executou) {
              handleGetCliData();
              setLoadCliMap(true);
              setTimeout(() => {
                toast.success('Roteiro processado com sucesso!');
                setLoadCliMap(false);
              }, 5000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setTitlePage(TITLE_PAGE.SCREENWRITER);
  }, [titlePage]);

  function exportCliToExcel() {
    const fileName: string = `ROTEIRO_RCA${codVendedorParam}`;
    let clientes: any = [];
    currentPositions?.map((cli: any) => {
      cli.clientesDaRota?.map((cliente: any) => {
        cliente.dtProxVisita =
          new Date(cliente.dtProxVisita).toLocaleDateString() != 'Invalid date'
            ? new Date(cliente.dtProxVisita).toLocaleDateString('pt-BR')
            : cliente.dtProxVisita;
        clientes.push(cliente);
      });
    });

    const ws = XLSX.utils.json_to_sheet(clientes);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ROTEIRO');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  }

  const [searchText, setSearchText] = useState('');

  return (
    <S.MapMainContainer>
      {showMap && (
        <RouterSideBar
          paginator={{
            showPrev: showPrev,
            handlePages: handlePages,
            setCurrentPositions: setCurrentPositions,
            positions: positions,
            page: page,
            cores: cores,
            showNext: showNext,
            weekName: weekName,
          }}
          currentPositions={currentPositions.filter((rout: any) => page == rout.semana + '/' + rout.mes)}
          cores={cores}
          setMapCenter={setMapCenter}
          selectedMarker={selectedMarker}
          setSelectedMarker={setSelectedMarker}
          compPositions={compPositions}
        />
      )}
      <S.RouterHeaderBox>
        <S.RouterHeaderMapToggle>
          <div className={'icon ' + (showMap ? ' iconChecked' : '')} onClick={(e: any) => setShowMap(!showMap)}>
            <MdOutlineMap />
          </div>
          <div className={'icon ' + (!showMap ? ' iconChecked' : '')} onClick={(e: any) => setShowMap(!showMap)}>
            <MdViewList />
          </div>
        </S.RouterHeaderMapToggle>
        {!showMap && (
          <>
            <S.SideBarHeaderInputBox>
              <S.SideBarHeaderInput
                type={'text'}
                placeholder="Buscar"
                value={searchText}
                onChange={(e: any) => {
                  setSearchText(e.target.value.toUpperCase());
                }}
              />
              <S.SideBarHeaderInputButton>
                <AiOutlineSearch />
              </S.SideBarHeaderInputButton>
            </S.SideBarHeaderInputBox>
          </>
        )}
        {isWebScreen && (
          <VmButton
            options={{
              icon: routerTimer > 0 ? '' : <FiRefreshCcw />,
              label: 'Roteirizar' + (routerTimer > 0 ? ` (${routerTimer})` : ''),
              iconPosition: 'right',
              style: 'btn-dark',
              loading: routerLoading,
              disabled: !isRouterButtonAble ? !isRouterButtonAble : routerTimer > 0,
            }}
            onClick={(e: any) => handlePostRouter()}
          />
        )}
        {!showMap && (
          <VmButton
            options={{
              icon: <AiFillFileExcel />,
              label: 'Exportar',
              iconPosition: 'left',
              style: 'btn-success',
            }}
            onClick={() => exportCliToExcel()}
          />
        )}
        <S.HeaderFloatMenu>
          <VmButton
            options={{
              icon: <FcSettings />,
              iconPosition: 'left',
              rounded: true,
              tooltip: {
                show: true,
                text: 'Opções',
                position: 'bottom',
              },
              style: 'btn-white',
            }}
            onClick={(e: any) => setShowFloatMenu(!showFloatMenu)}
            style={{ backgroundColor: '#FFF !important' }}
          />
          <S.MenuBox
            style={{
              visibility: showFloatMenu ? 'visible' : 'hidden',
            }}
          >
            <S.CloseButton onClick={(e) => setShowFloatMenu(false)}>
              <AiOutlineClose />
            </S.CloseButton>
            <span className="p-float-label" style={{ margin: '10px 10px' }}>
              <Dropdown
                value={selectedRouter}
                options={routerType}
                optionLabel="name"
                placeholder="Todos"
                className="w-full w-14rem "
                style={{ width: '100%' }}
                onChange={(e) => setSelectedRouter(e.value)}
              />
              <label htmlFor="dd-city">Trajetos</label>
            </span>
            {!isWebScreen && (
              <VmButton
                options={{
                  icon: routerTimer > 0 ? '' : <FiRefreshCcw />,
                  label: 'Roteirizar' + (routerTimer > 0 ? ` (${routerTimer})` : ''),
                  iconPosition: 'right',
                  style: 'btn-light',
                  loading: routerLoading,
                  disabled: !isRouterButtonAble ? !isRouterButtonAble : routerTimer > 0,
                }}
                onClick={() => handlePostRouter()}
              />
            )}
            <VmButton
              options={{
                icon: <BsFillCalendar3WeekFill />,
                label: 'Agenda',
                iconPosition: 'left',
                style: 'btn-light',
              }}
              onClick={() =>
                navigate(`/agenda-vendedor/${codEmpresaParam}/${codVendedorParam}/${codMapaParam}/${codSetorParam}`)
              }
            />
            <VmButton
              options={{
                icon: <FaMapMarkedAlt />,
                label: 'Meus Lugares',
                iconPosition: 'left',
                style: 'btn-light',
              }}
              onClick={() => {
                navigate(`/cadastro/ponto/${codEmpresaParam}/${codMapaParam}/${codVendedorParam}`);
              }}
            />
            <VmButton
              options={{
                icon: <MdEditCalendar />,
                label: 'Alterar Data do Roteiro',
                iconPosition: 'left',
                style: 'btn-light',
              }}
              onClick={() => {
                setShowAlterDtProxVisitaModal(true);
              }}
            />
          </S.MenuBox>
        </S.HeaderFloatMenu>
      </S.RouterHeaderBox>

      {showMap ? (
        <div className={'mapMainBox'}>
          <GoogleMap
            mapContainerClassName="map-container"
            zoom={mapZoom.zoom}
            center={mapCenter}
            options={{
              maxZoom: mapZoom.maxZoom,
              minZoom: mapZoom.zoom < mapZoom.maxZoom ? mapZoom.zoom : mapZoom.maxZoom - 1,
              zoomControl: false,
              streetViewControl: true,
              streetViewControlOptions: {
                position: window.google.maps.ControlPosition.TOP_LEFT,
              },
            }}
          >
            {renderedCompMarkers && renderedCompMarkers}
            {renderedMarkers && renderedMarkers}
            {renderedLines && renderedLines}
            {renderedRoutes &&
              renderedRoutes.map((route: any, index: any) => (
                <DirectionsRenderer
                  key={index}
                  options={{
                    directions: route.routes,
                    suppressMarkers: true,
                    polylineOptions: {
                      strokeColor: route.color,
                    },
                  }}
                />
              ))}
            {renderedUniqueRoutes &&
              renderedUniqueRoutes.map((route: any, index: any) => (
                <DirectionsRenderer
                  key={index}
                  options={{
                    directions: route.routes,
                    suppressMarkers: true,
                    polylineOptions: {
                      strokeColor: route.color,
                      strokeWeight: 6,
                    },
                  }}
                />
              ))}
            {/* POPUP DO MARKER */}
            {selectedMarker && (
              <InfoWindow
                position={{ lat: +selectedMarker.lat, lng: +selectedMarker.lng }}
                onCloseClick={handleInfoWindowClose}
                options={{
                  pixelOffset: new window.google.maps.Size(-2, -45),
                }}
              >
                <div style={{ padding: '0px' }}>
                  <CliPopUp cli={selectedMarker} handleGetCliData={handleGetCliData} compPositions={compPositions} />
                </div>
              </InfoWindow>
            )}
            {currentPositions.length === 1 && selectedRouter.code === 2 && (
              <NextRouterPagination
                rota={currentPositions[0]}
                handleUniqueRouteRender={handleUniqueRouteRender}
                routeTotalKm={routeTotalKm}
              />
            )}
            <MapZoomControl mapZoom={mapZoom} setMapZoom={setMapZoom} />
          </GoogleMap>

          <AddressModal
            isAdress={false}
            isEdit={false}
            showModal={showAddressModal}
            setShowModal={setShowAddressModal}
            title={'Para prosseguir com a roteirização, defina abaixo um ponto de partida para o roteiro:'}
            codVendedor={codVendedorParam}
            codMapaParam={codMapaParam}
            codEmpresaParam={codEmpresaParam}
          />
          <LoadCliMap title={'Roteirizando...'} />
          <ExecRouterModal
            showModal={showExecRouterModal}
            setShowModal={setShowExecRouterModal}
            handlePostRouter={handlePostRouter}
          />
        </div>
      ) : (
        <div className="col text-center">
          <RouterCliClist
            cliList={currentPositions.filter((rout: any) => page === rout.semana + '/' + rout.mes)}
            searchText={searchText}
          />
        </div>
      )}
      <AlterDtProxVisitaModal
        showModal={showAlterDtProxVisitaModal}
        setShowModal={setShowAlterDtProxVisitaModal}
        codSetor={codSetorParam ?? ''}
        codVendedor={codVendedorParam ?? ''}
        codEmpresa={codEmpresaParam ?? ''}
        currentPositions={currentPositions}
        page={page}
        handleGetCliData={handleGetCliData}
        setLoadCliMap={setLoadCliMap}
      />
    </S.MapMainContainer>
  );
};

export default Roteirizador;
