import * as S from './styles';
import React, { useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default function TesteMainBox() {
  const [content, setContent] = useState('');

  return (
    <div className="App">
      <h2>Using CKEditor 5 in React</h2>
      <CKEditor
        editor={ClassicEditor}
        data="<p>Type your content here...</p>"
        onChange={(event, editor) => {
          const data = editor.getData();
          console.log('data :', data);
          setContent(data);
        }}
      />
      <h3>Editor Data:</h3>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}
