import * as S from './restricoesTable.styles';

import { RegraProps, RegraRestricaoProps } from 'client/interfaces';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import { Accordion, Button } from 'reactivus';
import { useEffect, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { postDeleteRegraRestricao, postEditRegraRestricao } from 'client/api/regraComercial';
import NovaRestricaoModal from '../novaRestricaoModal/novaRestricaoModal.index';
import { regraRestricaoTypeDescriptions } from '../../regraComercial.types';

type itensTableProps = {
  selectedRegra: RegraProps;
  codEmpr: number;
  handleGetRegrasComerciais: () => void;
};

export default function RestricoesTable({ selectedRegra, codEmpr, handleGetRegrasComerciais }: itensTableProps) {
  const [restricoesList, setRestricoesList] = useState([]);
  const [showNovaRestricaoModal, setShowNovaRestricaoModal] = useState(false);

  useEffect(() => {
    setRestricoesList(selectedRegra && selectedRegra.regraRestricao ? selectedRegra.regraRestricao : []);
  }, [selectedRegra]);

  const geraBenefBodyTemplate = (rule: RegraRestricaoProps) => {
    const [checked, setChecked] = useState(rule.gerarbenef === 'S');
    const handleSwitchChange = (e) => {
      const newValue = e.value ? 'S' : 'N';
      rule.gerarbenef = newValue;

      postEditRegraRestricao(rule)
        .then((res) => {
          if (res.succeeded) {
            handleGetRegrasComerciais();
            setShowNovaRestricaoModal(false);
            setChecked(e.value);
          } else {
            rule.gerarbenef = newValue == 'S' ? 'N' : 'S';
          }
        })
        .catch((err) => {
          rule.gerarbenef = newValue == 'S' ? 'N' : 'S';
          toast.error('Falha inesperada ao editar restrição');
          console.log('err :', err);
        });
    };
    return <InputSwitch checked={checked} onChange={handleSwitchChange} />;
  };

  const removeRestricaoBodyTemplate = (rowData: RegraRestricaoProps) => {
    return (
      <Button
        icon={<FaTrash />}
        color={'danger'}
        text
        onClick={(e: any) => {
          handleRemoveRegraRestricao(rowData);
        }}
      />
    );
  };

  const handleRemoveRegraRestricao = (restricaoToRemove: RegraRestricaoProps) => {
    postDeleteRegraRestricao([restricaoToRemove])
      .then((res) => {
        if (res.succeeded) {
          handleGetRegrasComerciais();
        }
      })
      .catch((err) => {
        toast.error('Falha inesperada ao remover restrição');
        console.log('err :', err);
      });
  };

  const AccordionHeaderTemplate = () => {
    return (
      <S.AccordionHeaderTemplateBox>
        <span>Restrições</span>
        <Button
          icon={<FaPlus />}
          color={selectedRegra ? 'success' : 'light'}
          text
          label="Adicionar Restrições"
          shadow
          size="sm"
          onClick={(e: any) => {
            e.stopPropagation();
            selectedRegra && setShowNovaRestricaoModal(true);
          }}
          disabled={!selectedRegra}
        />
      </S.AccordionHeaderTemplateBox>
    );
  };

  return (
    <Accordion title={'Restrições'} headerTemplate={<AccordionHeaderTemplate />}>
      <S.RestricoesTableMainBox>
        <S.RestricoesBodyBox>
          <DataTable
            value={restricoesList ?? []}
            paginator={false}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={100}
            rowHover
            stripedRows
            scrollable
            scrollHeight="57dvh"
            style={{ maxWidth: '100%', minHeight: '57dvh' }}
            emptyMessage={
              selectedRegra ? 'Esta regra não possui restrições' : 'Selecione uma regra pra visualizar as restrições'
            }
          >
            <Column
              field="tiporestricao"
              header="Tipo"
              sortable
              body={(rowData: RegraRestricaoProps) => {
                return regraRestricaoTypeDescriptions[rowData.tiporestricao];
              }}
              style={{
                maxWidth: '100px',
              }}
            />
            <Column
              field="codigorestricao"
              header="Cód."
              sortable
              style={{
                maxWidth: '100px',
              }}
            />
            <Column
              field="descricao"
              header="Descrição"
              sortable
              style={{
                minWidth: '40%',
              }}
            />
            <Column field="gerarbenef" header="Gerar Benef." sortable body={geraBenefBodyTemplate} />
            <Column field="" header="Remover" body={removeRestricaoBodyTemplate} />
          </DataTable>
        </S.RestricoesBodyBox>
      </S.RestricoesTableMainBox>
      <NovaRestricaoModal
        showNovaRestricaoModal={showNovaRestricaoModal}
        setShowNovaRestricaoModal={setShowNovaRestricaoModal}
        codEmpresa={codEmpr}
        handleGetRegrasComerciais={handleGetRegrasComerciais}
        selectedRegra={selectedRegra}
      />
    </Accordion>
  );
}
