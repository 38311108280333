import { useTitlePageStore } from 'storesZustand/titlePageStore';
import * as S from './kpiList.styles';
import { useEffect, useState } from 'react';

import { InputText } from 'primereact/inputtext';
import VmButton from 'components/VmButton/VmButton';
import { FiEdit2, FiPlus, FiRefreshCcw } from 'react-icons/fi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { IoPlayOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useKpi } from 'modules/kpi/kpi.context';
import { KpiProps } from 'client/interfaces';
import EditKpiModal from 'modules/kpi/components/editKpiModal/editKpiModal.index';
import NewKpiModal from 'modules/kpi/components/newKpiModal/newKpiModal.index';

export default function KpiList() {
  const {
    agrupadorOptions,
    setSelectedKpi,
    kpis,
    handleKpiHeaderFilter,
    kpiFilters,
    setKpiFilters,
    SelectEmpresa,
    handleConfirmKpiReRun,
  } = useKpi();

  const navigate = useNavigate();

  const { setTitlePage } = useTitlePageStore();

  useEffect(() => {
    setTitlePage("KPI's");
  }, []);

  const [showEditKpiModal, setShowEditKpiModal] = useState(null);
  const [showNewSqlModal, setShowNewKpiModal] = useState(null);

  const reportTableActionFields = (kpi: KpiProps) => {
    return (
      <S.KpiTableActionField>
        <VmButton
          options={{
            icon: <IoPlayOutline />,
            iconPosition: 'left',
            style: 'btn-none',
            rounded: true,
            tooltip: {
              position: 'top',
              show: true,
              text: 'Visualizar',
            },
          }}
          onClick={() => {
            navigate(`/kpi/${kpi.nrokpi}`);
          }}
        />
        <VmButton
          options={{
            icon: <FiEdit2 />,
            iconPosition: 'left',
            style: 'btn-none',
            rounded: true,
            tooltip: {
              position: 'top',
              show: true,
              text: 'Editar',
            },
          }}
          onClick={() => {
            setShowEditKpiModal(true);
            setSelectedKpi(kpi);
          }}
        />
        <VmButton
          options={{
            icon: <FiRefreshCcw />,
            iconPosition: 'left',
            style: 'btn-none',
            rounded: true,
            tooltip: {
              position: 'top',
              show: true,
              text: 'Atualizar',
            },
          }}
          onClick={() => {
            handleConfirmKpiReRun(kpi);
          }}
        />
      </S.KpiTableActionField>
    );
  };

  useEffect(() => {
    if (kpis) {
      handleKpiHeaderFilter();
    }
  }, [kpiFilters]);

  const agrupadorTemplate = (rowData: KpiProps) => {
    const agrupadorAtual = agrupadorOptions?.filter((agrupador: any) => agrupador.code == rowData.agrupador)[0] ?? null;

    return <div>{agrupadorAtual ? `[${agrupadorAtual.code}] ${agrupadorAtual.name}` : `${rowData.agrupador}`}</div>;
  };

  return (
    <S.KpiMainBox>
      <S.KpiHeaderBox>
        <SelectEmpresa />
        <S.KpiInputBox>
          <label htmlFor="report">KPI</label>
          <InputText
            id="report"
            placeholder="Buscar por título ou SQL"
            style={{
              width: 'clamp(200px, 250px, 300px)',
            }}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase();
              setKpiFilters((prevState: any) => {
                return {
                  ...prevState,
                  text: e.target.value,
                };
              });
            }}
          />
        </S.KpiInputBox>
        <VmButton
          options={{
            style: 'btn-success',
            label: 'Novo',
            icon: <FiPlus />,
            iconPosition: 'left',
          }}
          onClick={() => {
            setShowNewKpiModal(true);
          }}
        />
      </S.KpiHeaderBox>

      <S.KpiBodyBox>
        <DataTable
          value={kpis?.filtered ?? []}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rows={100}
          rowHover
          stripedRows
          scrollable
          scrollHeight="68dvh"
          style={{ minWidth: '50rem' }}
        >
          <Column
            field="nrokpi"
            header="Cód."
            sortable
            style={{
              maxWidth: '7rem',
            }}
          />
          <Column field="titulo" header="Título" sortable />
          <Column
            field="agrupador"
            header="Agrupador"
            body={agrupadorTemplate}
            sortable
            style={{
              maxWidth: '15rem',
            }}
          />
          <Column
            field="freqatualiza"
            header="Freq. Atualiza"
            sortable
            style={{
              maxWidth: '10rem',
            }}
          />
          <Column
            field="dtproxatu"
            header="Dt. Próx. Atu."
            sortable
            style={{
              maxWidth: '10rem',
            }}
          />
          <Column field="" header="Ações" body={reportTableActionFields} />
        </DataTable>
      </S.KpiBodyBox>
      {showEditKpiModal && (
        <EditKpiModal showEditKpiModal={showEditKpiModal} setShowEditKpiModal={setShowEditKpiModal} />
      )}
      {showNewSqlModal && <NewKpiModal showNewKpiModal={showNewSqlModal} setShowNewKpiModal={setShowNewKpiModal} />}
    </S.KpiMainBox>
  );
}
