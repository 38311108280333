import styled, { css } from 'styled-components';

interface IResponsiveProps {
  mobile?: boolean;
  tablet?: boolean;
}

export const IconTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: last baseline;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
  gap: 20px;

  form {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 15px;
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
`;

export const ContainerTable = styled.div<IResponsiveProps>`
  width: 100%;
  max-height: 50vh;

  ${(props) =>
    props.mobile &&
    css`
      @media (max-width: 768px) {
        padding: 10px;
        padding-bottom: 40px;
        max-height: none;
      }
    `}

  ${(props) =>
    props.tablet &&
    css`
      @media (max-width: 992px) {
        padding: 10px;
        padding-bottom: 40px;
        max-height: none;
      }
    `}
`;

export const Dropdown = styled.div`
  .p-dropdown p-component p-inputwrapper p-inputwrapper-filled {
    width: 1040px;
  }
`;

export const GroupTemplate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10px;
  gap: 20px;
`;

export const GrupoTitle = styled.span``;

export const GrupoTimeInputBox = styled.span`
  position: relative;
  padding: 10px 0;
  label {
    position: absolute;
    bottom: 80%;
    left: 0;
    font-size: 0.8rem;
    padding: 0 5px;
  }
`;

export const GrupoTimeInput = styled.input`
  border: 1px solid #60606090;
  border-radius: 2.5px;
  padding: 2px 5px 2px 10px;
`;

export const MapFormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
`;

export const TableButtonBox = styled.div`
  display: flex;
  justify-content: center;

  button svg {
    font-size: 0.9rem;
  }
`;
