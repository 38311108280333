import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getSellersCompany, getSupervisorByRole } from 'client/api/vendedores';
import { ISupervisor } from 'client/interfaces/supervisor.interface';

export const useSupervisor = (CodEmpresa: number, enabled = true) =>
  useQuery<ISupervisor[], AxiosError>(['vendedores', CodEmpresa], async () => await getSellersCompany(CodEmpresa), {
    enabled,
  });

export const useSupervisorByRole = (codEmpresa: number | string, codUser: number | string, enabled = true) =>
  useQuery<ISupervisor[], AxiosError>(
    ['useSupervisorByRole'],
    async () => await getSupervisorByRole(codEmpresa, codUser),
    {
      enabled,
    },
  );

export const useSupervisorByUser = (codEmpresa: number | string, codUser: number | string, enabled = true) =>
  useQuery<ISupervisor[], AxiosError>(
    ['useSupervisorByRole'],
    async () => await getSupervisorByRole(codEmpresa, codUser),
    {
      enabled,
    },
  );
