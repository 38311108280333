/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { MdInfoOutline, MdOutlineModeEditOutline, MdPersonOutline } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import * as S from './styles';
import storage from 'services/storage';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import useMediaQuery from 'hooks/useMediaQuery';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import Swal from 'sweetalert2';
import { useLoaderEffect } from 'providers/loaderEffect';
import { Checkbox } from 'primereact/checkbox';
import { Container } from 'reactivus';
import { MapContext } from 'providers/map';
import { Isetores2 } from 'client/interfaces';

type FilterProps = {
  name: string;
  code: string;
};

interface IMapSidebarParams {
  codMapaParam: string;
  codEmpresaParam: string;
  selectedFilter: FilterProps;
  setSelectedFilter: React.Dispatch<SetStateAction<FilterProps>>;
}

const MapSidebar = ({ codEmpresaParam, codMapaParam, selectedFilter, setSelectedFilter }: IMapSidebarParams) => {
  const {
    vendedores,
    setMapGridShow,
    clientes,
    setClientesList,
    setSetorToEdit,
    setores,
    setShowEditSetor,
    setCurrentSetor,
    currentSetor,
    setRoteirizaSetor,
    isClientsWithoutSectorLoaded,
  } = useContext(MapContext);

  const { setLoader } = useLoaderEffect();

  const isWebScreen = useMediaQuery('(min-width: 1024px)');

  const navigate = useNavigate();

  const setorSearchRef: any = useRef(null);

  const [showMenu, setShowMenu] = useState(false);

  const [setoresList, setSetoresList] = useState([]);

  const returnRcaName = (rcaCod: any) => {
    if (rcaCod == null) {
      return '';
    }

    const actualRca: any = vendedores?.filter((rca: any) => rca.codVendedor === rcaCod);

    if (actualRca.length > 0) {
      return ' - ' + actualRca[0].nome;
    } else {
      return '';
    }
  };

  useEffect(() => {
    setSetoresList(setores);
    handleSetoresSearch();
  }, [showMenu, setores]);

  const handleSetoresSearch = () => {
    if (setorSearchRef.current) {
      let filter = setores.filter(
        (set: any) =>
          set.setor.toUpperCase().includes(setorSearchRef.current.value.toUpperCase()) ||
          returnRcaName(set.codRca ? set.codRca : '')
            .toUpperCase()
            .includes(setorSearchRef.current.value.toUpperCase()) ||
          (set.codRca && set.codRca.toString().includes(setorSearchRef.current.value)),
      );

      if (filter.length == 0) {
        filter = setores.filter((set: any) => set.codSetor == +setorSearchRef.current.value);
      }

      if (selectedFilter) {
        if (filter && selectedFilter.code === 'A') {
          filter = filter.filter((map) => map.ativo == 'S');
        } else if (filter && selectedFilter.code === 'I') {
          filter = filter.filter((map) => map.ativo == 'N');
        } else {
          filter = filter;
        }
      }
      setSetoresList(filter);
    } else {
      setSetoresList(setores);
    }
  };

  const handleCliSetorFilter = (rowData: any) => {
    const filteredCli = clientes.filter((cli: any) => cli.codSetor === rowData.codSetor);
    setMapGridShow(false);
    setShowMenu(false);
    setCurrentSetor({
      setores: [rowData],
    });
    setClientesList(filteredCli);
  };

  const editTemplate = (rowdata: any) => {
    if (rowdata.codSetor == -1) {
      return <></>;
    } else {
      const qtClientes = clientes?.filter((cli: any) => cli.codSetor === rowdata.codSetor).length;
      return (
        <S.SetorTableIconTd>
          <span
            onClick={(e) => {
              setShowEditSetor(true);
              setSetorToEdit(rowdata);
            }}
          >
            <MdOutlineModeEditOutline />
          </span>
          <span onClick={() => handleCliSetorFilter(rowdata)}>
            <MdPersonOutline color="#f64f3d" />
          </span>
          {/* {rowdata.atualizaAutomatico === 'S' && qtClientes > 0 && ( */}
          {qtClientes > 0 && (
            <span
              onClick={(e) => {
                setRoteirizaSetor(rowdata);
                if (rowdata.codRca) {
                  localStorage.setItem('roteirizaSetor', JSON.stringify(rowdata));
                  storage.setItem('selectedScreenwriter', JSON.stringify(rowdata));
                  navigate(`/roteirizador/${rowdata.codMapa}/${rowdata.codSetor}/${rowdata.codRca}/${codEmpresaParam}`);
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Setor sem vendedor vinculado!',
                    text: 'Vincule um vendedor para prosseguir com a roteirização.',
                    confirmButtonText: 'Ok',
                  });
                }
              }}
            >
              <FaMapMarkedAlt color={rowdata.atualizaAutomatico === 'S' ? '#375e97' : '#cb2a29'} />
            </span>
          )}
        </S.SetorTableIconTd>
      );
    }
  };
  const codColorSetorTemplate = (rowdata: any) => {
    return (
      <S.SetorCodColorBackground
        onClick={() => {
          handleCurrentSetorSelection(rowdata);
        }}
        codColorSetor={rowdata.cor}
      />
    );
  };

  function isDataInArray(data: any, dataArray: any[]): boolean {
    if (!dataArray) return false;
    const codSetorToFind = data.codSetor;

    for (const item of dataArray) {
      if (item.codSetor === codSetorToFind) {
        return true;
      }
    }

    return false;
  }

  type PercBarProps = {
    value: number;
  };

  const PercBar = ({ value }: PercBarProps) => {
    return (
      <S.PercBarBox>
        <S.PercBar
          width={value}
          color={value <= 25 ? '#f32929' : value <= 50 ? '#FFC300' : value <= 70 ? '#F64F3D' : '#228F6B'}
        ></S.PercBar>
        <S.PercBarText color={value <= 25 ? '#000' : value <= 50 ? '#000' : value <= 70 ? '#000' : '#000'}>
          {value}%
        </S.PercBarText>
      </S.PercBarBox>
    );
  };

  const numberFormat = (number) => {
    if (number != null && number.toString() == 'Infinity') {
      number = 100.0;
    }
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(number).replace('$', '');
  };

  type PotencialDataProps = {
    vendaPotencial: number;
    vendaUltTri: number;
    vendaPercUltTri?: number;
    vendaUltMes: number;
    vendaPercUltMes?: number;
  };

  const vendaPotencialData = (props: PotencialDataProps, clientes: number) => {
    props.vendaPercUltTri = props.vendaUltTri < 1 ? 0 : +((props.vendaUltTri * 100) / props.vendaPotencial).toFixed(2);
    props.vendaPercUltMes = props.vendaUltMes < 1 ? 0 : +((props.vendaUltMes * 100) / props.vendaPotencial).toFixed(2);

    return (
      <S.SetorVendaPotencialRow>
        <S.SetorVendaPotencialRowContent>
          <span>Clientes: {clientes}</span>
          <span>Total Km: 0</span>
        </S.SetorVendaPotencialRowContent>

        <S.SetorVendaPotencialRowContent>
          <span>Potencial:</span>
          R${numberFormat(props.vendaPotencial)}
        </S.SetorVendaPotencialRowContent>
        <S.SetorVendaPotencialRowContent>
          <span>Ult. Trim: R${numberFormat(props.vendaUltTri)}</span>
          <PercBar value={props.vendaPercUltTri} />
        </S.SetorVendaPotencialRowContent>
        <S.SetorVendaPotencialRowContent>
          <span>Ult. Mês: R${numberFormat(props.vendaUltMes)}</span>
          <PercBar value={props.vendaPercUltMes} />
        </S.SetorVendaPotencialRowContent>
      </S.SetorVendaPotencialRow>
    );
  };

  const InactiveTag = () => {
    return <S.InactiveTag>INATIVO</S.InactiveTag>;
  };

  const nomeSetorTemplate = (rowdata: any) => {
    if (rowdata.codSetor === -1) {
      // CARD DE CLIENTES SEM SETOR
      return (
        <S.SetorCardBox>
          <div>
            <span>
              <S.SetorName
                onClick={() => {
                  handleCurrentSetorSelection(rowdata);
                  if (rowdata.codSetor == -1) {
                    setLoader({
                      show: true,
                      text: 'Por favor aguarde...',
                    });
                  }
                }}
              >
                {isDataInArray(rowdata, currentSetor ? currentSetor.setores : []) && (
                  <BsFillCheckCircleFill color={'green'} />
                )}
                {rowdata.setor.toUpperCase()}
              </S.SetorName>
            </span>
            <br />
            <span>
              <span style={{ backgroundColor: '#f5f5fe', padding: '2px', borderRadius: '2px' }}>
                [Clientes:{rowdata.QTD_CLIENTES}
                {/* {rowdata.codSetor === -1
                  ? clientes?.filter((cli: any) => cli.codSetor == null || cli.codSetor == 0).length
                  : clientes?.filter((cli: any) => cli.codSetor === rowdata.codSetor).length} */}
                ]
              </span>
            </span>
          </div>
        </S.SetorCardBox>
      );
    }
    if (rowdata.polygono !== undefined && rowdata.polygono.length > 0) {
      // CARD DE SETOR COM POLYGONO
      return (
        <S.SetorCardBox>
          {rowdata.ativo == 'N' && <InactiveTag />}
          <S.SetorCardTitle>
            <S.SetorCardTitleName>
              <S.SetorCodBackground
                onClick={(e) => {
                  handleCurrentSetorSelection(rowdata);
                  setShowMenu(false);
                }}
                style={{
                  backgroundColor: rowdata.cor != null ? '#' + rowdata.cor + 'A0' : '#000000',
                  borderColor: rowdata.cor != null ? '#' + rowdata.cor : 'transparent',
                }}
              >
                <S.SetorName>
                  {isDataInArray(rowdata, currentSetor ? currentSetor.setores : []) && (
                    <BsFillCheckCircleFill color={'green'} />
                  )}
                  {rowdata.setor.toUpperCase()} [{rowdata.codSetor}]
                </S.SetorName>
              </S.SetorCodBackground>

              <span>
                {rowdata.codRca ? (
                  `[${rowdata.codRca}] ${returnRcaName(rowdata.codRca)}`
                ) : (
                  <S.VagoTag>
                    <MdInfoOutline /> VAGO
                  </S.VagoTag>
                )}
              </span>
            </S.SetorCardTitleName>
            {editTemplate(rowdata)}
          </S.SetorCardTitle>
          {vendaPotencialData(
            {
              vendaPotencial: rowdata.vlPotencial ?? 0,
              vendaUltTri: rowdata.vlMedUltTrim ?? 0,
              vendaUltMes: rowdata.vlMesAtual ?? 0,
            },
            rowdata.QTD_CLIENTES,
          )}
        </S.SetorCardBox>
      );
    } else {
      // CARD DE SETOR SEM POLYGONO
      return (
        <S.SetorCardBox>
          {rowdata.ativo == 'N' && <InactiveTag />}
          <S.SetorCardTitle>
            <S.SetorCardTitleName>
              <S.SetorName
                onClick={(e) => {
                  handleCurrentSetorSelection(rowdata);
                }}
              >
                {isDataInArray(rowdata, currentSetor ? currentSetor.setores : []) && (
                  <BsFillCheckCircleFill color={'green'} />
                )}
                {rowdata.setor.toUpperCase()} [{rowdata.codSetor}]
              </S.SetorName>
              <span>
                {rowdata.codRca ? (
                  `[${rowdata.codRca}] ${returnRcaName(rowdata.codRca)}`
                ) : (
                  <S.VagoTag>
                    <MdInfoOutline /> VAGO
                  </S.VagoTag>
                )}
              </span>
            </S.SetorCardTitleName>
            {editTemplate(rowdata)}
          </S.SetorCardTitle>
          {vendaPotencialData(
            {
              vendaPotencial: rowdata.vlPotencial ?? 0,
              vendaUltTri: rowdata.vlMedUltTrim ?? 0,
              vendaUltMes: rowdata.vlMesAtual ?? 0,
            },
            rowdata.codSetor === -1
              ? clientes?.filter((cli: any) => cli.codSetor == null || cli.codSetor == 0).length
              : clientes?.filter((cli: any) => cli.codSetor === rowdata.codSetor).length,
          )}
        </S.SetorCardBox>
      );
    }
  };

  const handleCurrentSetorSelection = (setor: any) => {
    let setoresSelectionList = [];
    if (currentSetor) {
      setoresSelectionList = currentSetor.setores[0] ? currentSetor.setores : [currentSetor.setores];
    }

    if (isDataInArray(setor, currentSetor ? currentSetor.setores : [])) {
      setoresSelectionList = setoresSelectionList.filter((sets: any) => sets.codSetor != setor.codSetor);
    } else {
      setoresSelectionList.push(setor);
    }

    setCurrentSetor(
      setoresSelectionList.length > 0
        ? {
            setores: setoresSelectionList,
          }
        : null,
    );
  };

  const filters = [
    { name: 'Todos', code: 'T' },
    { name: 'Ativos', code: 'A' },
    { name: 'Inativos', code: 'I' },
  ];

  useEffect(() => {
    handleSetoresSearch();
  }, [selectedFilter]);

  const headerTemplate = () => {
    return (
      <Container alignItems="center" gap="10px">
        <Checkbox
          inputId={'selectAll'}
          checked={currentSetor && currentSetor.setores && currentSetor.setores.length == setoresList.length}
          onChange={(e) => {
            setLoader({
              show: true,
              text: 'Por favor aguarde...',
            });
            if (currentSetor && currentSetor.setores.length == setoresList.length) {
              // setCurrentSetor({
              //   setores: setoresList
              //   ? setoresList.filter((sector: Isetores2) => sector.CODSETOR != 0 && sector.CODSETOR != -1)
              //   : null,
              // });
              setCurrentSetor(null);
            } else if (setCurrentSetor) {
              setCurrentSetor({
                setores: setoresList,
              });
            }
          }}
        />
        <h5>Selecionar todos</h5>
      </Container>
    );
  };

  return (
    <S.MapSideBarMainBox showMenu={showMenu}>
      {!showMenu && <S.SideBarHeaderClosed>SETORIZAÇÃO DE CLIENTES</S.SideBarHeaderClosed>}

      <S.MapSideBarOpenBtn showMenu={showMenu} onClick={(e) => setShowMenu(!showMenu)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http:// www.w3.org/2000/svg"
          className="mh-50px"
          color="#7bcda0"
        >
          <path
            opacity="0.5"
            d="M9.63433 11.4343L5.45001 7.25C5.0358 6.83579 5.0358 6.16421 5.45001 5.75C5.86423 5.33579 6.5358 5.33579 
            6.95001 5.75L12.4929 11.2929C12.8834 11.6834 12.8834 12.3166 12.4929 12.7071L6.95001 18.25C6.5358 18.6642 5.86423 18.6642 
            5.45001 18.25C5.0358 17.8358 5.0358 17.1642 5.45001 16.75L9.63433 12.5657C9.94675 12.2533 9.94675 11.7467 9.63433 11.4343Z"
            fill="currentColor"
          />
          <path
            d="M15.6343 11.4343L11.45 7.25C11.0358 6.83579 11.0358 6.16421 11.45 5.75C11.8642 5.33579 
             12.5358 5.33579 12.95 5.75L18.4929 11.2929C18.8834 11.6834 18.8834 12.3166 18.4929 12.7071L12.95 18.25C12.5358 18.6642 
             11.8642 18.6642 11.45 18.25C11.0358 17.8358 11.0358 17.1642 11.45 16.75L15.6343 12.5657C15.9467 12.2533 15.9467 11.7467 
             15.6343 11.4343Z"
            fill="currentColor"
          />
        </svg>
      </S.MapSideBarOpenBtn>

      {showMenu && (
        <S.SideBarHeader>
          <p>SETORIZAÇÃO DE CLIENTES</p>
          <S.HeaderFilterBox>
            <S.TextInputBox>
              <InputText
                id="name"
                placeholder="Pesquisar Setor / Vendedor"
                ref={setorSearchRef}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value = e.target.value.toUpperCase();
                  handleSetoresSearch();
                }}
                width={'width: clamp(60%, 70%, 80%);'}
              />
            </S.TextInputBox>

            <S.InputBox>
              <Dropdown
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.value)}
                options={filters}
                optionLabel="name"
                placeholder="Todos"
                className="f-lg-4"
                style={{ width: !isWebScreen ? '100px' : '120px' }}
              />
            </S.InputBox>
          </S.HeaderFilterBox>
        </S.SideBarHeader>
      )}

      <S.Content showMenu={showMenu}>
        <DataTable
          value={setoresList}
          tableStyle={{ minWidth: '100%', fontSize: '.8rem' }}
          paginator={false}
          rows={10}
          rowsPerPageOptions={[10, 25, 50]}
          paginatorTemplate="RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink"
          emptyMessage="Nenhum setor encontrado :/"
        >
          <Column field="" header="" style={{ fontSize: '.4rem', padding: '0' }} body={codColorSetorTemplate} />
          <Column field="setor" header={headerTemplate} style={{ fontSize: '.8rem' }} body={nomeSetorTemplate} />
        </DataTable>
      </S.Content>
    </S.MapSideBarMainBox>
  );
};

export default MapSidebar;
