import * as S from './styles';
import StopListCard from './stopListCard';
import { useContext } from 'react';
import { MapContext } from 'providers/map';
import { useAnaliseRoteiro } from '../../analiseRoteiro.context';

export default function RouterStopsList({ searchText }: { searchText: string }) {
  const { markersControler } = useContext(MapContext);

  const { positions, pages } = useAnaliseRoteiro();

  const higherDate =
    positions.all[positions.all.length - 1] && new Date(positions.all[positions.all.length - 1].dtProxVisita)
      ? new Date(positions.all[positions.all.length - 1].dtProxVisita)
      : '';

  const routedPoints = markersControler.nextWeeks
    ? []?.filter((p: any) => p.estaEmRota == 'S' && p.dtProxVisita && new Date(p.dtProxVisita) > higherDate)
    : [];
  routedPoints.sort((a, b) => {
    const dateA = new Date(a.dtProxVisita);
    const dateB = new Date(b.dtProxVisita);
    return dateA.getTime() - dateB.getTime();
  });
  const notRoutedPoints = markersControler.notInRoute ? []?.filter((p: any) => p.estaEmRota == 'N') : [];

  let allClients = [];

  for (let i = 0; i < positions.all.length; i++) {
    if (positions.all[i].clientesDaRota) {
      allClients = allClients.concat(positions.all[i].clientesDaRota);
    }
  }
  let filteredRoutedPoints = routedPoints.filter(
    (point) => !allClients.some((position) => position.codCli === point.codCli),
  );

  const newFilteredRoutedPoints = [];
  for (let i = 0; i < filteredRoutedPoints.length; i++) {
    let isCliInArray = newFilteredRoutedPoints.findIndex((cli: any) => cli.codCli === filteredRoutedPoints[i].codCli);

    if (isCliInArray == -1) newFilteredRoutedPoints.push(filteredRoutedPoints[i]);
  }

  return (
    <S.PointsListMainBox>
      <S.PointsListLabel>Rotas da semana:</S.PointsListLabel>
      {positions.current.filter((rout: any) => pages.current === rout.semana + '/' + rout.mes).map((point: any) => {
        if (point.latitudePonto)
          return (
            <StopListCard
              point={point}
              searchText={searchText}
              key={new Date(point.dtProxVisita).toLocaleDateString('pt-BR')}
            />
          );
      })}
    </S.PointsListMainBox>
  );
}
