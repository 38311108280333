import { useForm } from 'react-hook-form';
import { useEffect } from 'react';

interface FormEditFrequenceVisit {
  freqVisita: number;
  tempoMinVisita: number;
  dtProxVisita: string;
  sequenciaVisita: number;
  bloqRoteiro: string;
  agendamentoDoCliente: string;
}

interface IUseEditFrequenceVisit {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMarker: any;
  codCompany: string;
  codMap: string;
}

export const UseEditFrequenceVisit = ({ setShowModal, selectedMarker, codCompany, codMap }: IUseEditFrequenceVisit) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm<FormEditFrequenceVisit>({});

  useEffect(() => {
    setValue('freqVisita', selectedMarker.freqVisita);
    setValue('tempoMinVisita', selectedMarker.tempoMinVisita);
    if (selectedMarker.dtProxVisita) {
      const dtProxVisita = selectedMarker.dtProxVisita
        ? selectedMarker.dtProxVisita.split('T')[0].split('-')
        : [];
      setValue('dtProxVisita', dtProxVisita.join('-'));
      console.log('dtProxVisita :', dtProxVisita.join('-'));
    }
    setValue('sequenciaVisita', selectedMarker.sequencia);
    setValue('bloqRoteiro', selectedMarker.bloqRoteiro);
    setValue('agendamentoDoCliente', selectedMarker.agendamentoDoCliente);
  }, [selectedMarker]);

  return {
    handleSubmit,
    control,
    errors,
    isValid,
    setValue,
    reset,
  };
};
