import { FC } from 'react';
import { Link } from 'react-router-dom';

import * as S from './styles';

interface Props {
  to: string;
  title: string;
  codigo?: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
  ktMenuMinimized: boolean;
}

const AsideMenuItem: FC<Props> = ({ to, title, codigo, ktMenuMinimized }) => {
  return (
    <S.MenuItem
      style={{
        fontSize: '14px',
        borderRadius: '4px',
        padding: '4px',
      }}
    >
      <Link style={{ padding: '0px', width: '100%' }} to={to}>
        <S.MenuItemTitle
          style={{
            fontSize: '15px',
            padding: '4px',
            borderRadius: '4px',
          }}
        >
          {codigo && <S.MenuItemTitleCod ktMenuMinimized={ktMenuMinimized}>{codigo}</S.MenuItemTitleCod>}{' '}
          <S.MenuItemTitleName ktMenuMinimized={ktMenuMinimized}>{title.replace(codigo, '')}</S.MenuItemTitleName>
        </S.MenuItemTitle>
      </Link>
    </S.MenuItem>
  );
};

export { AsideMenuItem };
