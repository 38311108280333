import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { toast } from 'react-toastify';

import storage from './storage';

export const ORIGIN = window.location.origin.toString();

export const BASE_URL = process.env.REACT_APP_BASE_URL as string;
export const BASE_API_URL = `${BASE_URL}/api`;

const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': ORIGIN,
  },
});

const CheckApiOnline = async () =>
  await axios
    .get(`${BASE_API_URL}/test`)
    .then(() => true)
    .catch(() => false);

const errorHandler = async (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401 && storage.getItem<string>('token')) {
      toast.error('Sessão do usuário expirou!', {
        toastId: '401error',
        position: 'top-right',
        autoClose: false,
      });
      const cpfCodErp = storage.getItem<string>('cpfCodErp');
      const empresaCNPJ = storage.getItem<string>('empresaCNPJ');
      storage.clearStorage();
      storage.setItem('token', null);
      storage.setItem('user', null);
      storage.setItem('cpfCodErp', cpfCodErp);
      storage.setItem('empresaCNPJ', empresaCNPJ);
      window.location.reload();
    }

    if (error.response?.status === 500) {
      let dataMessage: any = null;
      try {
        dataMessage = error.response.data ? error.response.data : null;
      } catch (err) {
        console.log('dataMessage Err :', err);
      }
      toast.error(
        dataMessage && dataMessage.message
          ? dataMessage.message
          : `Erro interno no servidor. Tente novamente mais tarde. Se o erro persistir entre em contato com o administrador`,
        {
          position: 'top-right',
          toastId: '500error',
          autoClose: false,
        },
      );
    }

    if (error.response?.status === 400) {
      let dataMessage: any = null;
      try {
        dataMessage = error.response.data ? error.response.data : null;
        if (!dataMessage.message && dataMessage.errors) {
          try {
            dataMessage.message = dataMessage.errors[Object.keys(dataMessage.errors).find((key) => key !== 'ponto')][0];
          } catch (err) {
            console.log('dataMessage Err :', err);
          }
        }
      } catch (err) {
        console.log('dataMessage Err :', err);
      }
      toast.error(
        dataMessage && dataMessage.message
          ? dataMessage.message
          : `Erro interno no servidor. Tente novamente mais tarde. Se o erro persistir entre em contato com o administrador`,
        {
          position: 'top-right',
          toastId: '400error',
          autoClose: 5000,
          pauseOnHover: true,
        },
      );
    }

    if (error.response === null) {
      if (await CheckApiOnline()) {
        toast.error('Erro de conexão! Verifique sua conexão com a internet e tente novamente.', {
          position: 'top-right',
          toastId: 'networkError',
          autoClose: false,
        });
      } else {
        toast.error(`Erro de conexão! Servidor fora do ar. Tente novamente em alguns instantes`, {
          position: 'top-right',
          toastId: 'offlineError',
          autoClose: false,
        });

        // storage.setItem('token', null);
        // storage.clearStorage();
        // localStorage.clear();
        // window.location.reload();
      }
    }
  }
  return await Promise.reject(error);
};

api.interceptors.response.use(
  (response) => {
    toast.dismiss('500error');
    toast.dismiss('networkError');
    return response;
  },
  async (error) => await errorHandler(error),
);

api.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers != null && config.headers.authorization == null) {
    const token = storage.getItem<string>('token');
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

export default api;
