import styled from 'styled-components';

interface BoxProps {
  show: boolean;
}

export const Box = styled.div<BoxProps>`
  height: 100%;
  width: 100%;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: none;
  pointer-events: none !important;
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, .90);
  z-index: 9999999 !important;
  pointer-events: auto !important;

  img {
    width: 10rem;
    height: 10rem;
    pointer-events: none !important;
  }
`;

export const Text = styled.div`
  padding: 10px 20px 10px 10px;
  font-size: 1.5rem;
`;
