import styled from 'styled-components';

export const RowItens = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: last baseline;
  gap: 15px;
  padding: 5px 0;
`;

export const ContainerTable = styled.div`
  width: 100%;

  @media (max-width: 992px) {
    padding: 10px;
    padding-bottom: 30px;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: first baseline;
  text-align: left;
  gap: 25px;
`;

export const NewFormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: first baseline;
  text-align: left;
  gap: 10px;
`;

export const NewFormInputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
  gap: 6px;

  label {
    font-weight: bolder;
    font-size: 1.1rem;
    color: #000;
  }

  .p-calendar .p-inputtext,
  .p-dropdown {
    height: 3.5rem;
    border-radius: 7.5px;
  }

  .p-placeholder {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
  }

  .p-inputtext,
  .p-inputwrapper {
    background-color: var(--gray-300);
    border: none;
  }

  .p-multiselect {
    max-width: 200px !important;
  }
`;

export const FormFvImage = styled.div`
  font-size: 1.5rem;
  padding-right: 10px;
  text-align: center !important;
  svg {
    color: #606060;
    font-size: 1.5rem;
  }

  img {
    max-width: 150px;
    max-height: 150px;
    margin: 0 auto;
    border: 1px solid #60606050;
    cursor: pointer;
    border-radius: 5px;
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: first baseline;
  text-align: left;
  gap: 15px;
`;

export const ItemImageTemplateBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
  cursor: pointer;
`;

export const FomImageTableIcon = styled.section`
  font-size: 1.5rem;
  padding-right: 10px;
  text-align: center !important;
  svg {
    color: #606060;
    font-size: 1.5rem;
  }

  img {
    max-width: 50px;
    max-height: 50px;
    margin: 0 auto;
  }
`;

export const RestricaoTable = styled.div`
  padding: 10px;
  table thead {
    position: sticky;
    top: 5rem;
    z-index: 99;
  }
  .p-datatable-header {
    position: sticky !important;
    top: 0rem !important;
    z-index: 101 !important;
  }
`;

export const ItensTable = styled.div`
  padding: 10px;
  table thead {
    position: sticky;
    top: 5rem;
    z-index: 99;
  }
  .p-datatable-header {
    position: sticky !important;
    top: 0rem !important;
    z-index: 101 !important;
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
`;
