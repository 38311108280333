import { Button } from 'primereact/button';
import { Column, ColumnEventParams } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect, MultiSelectChangeParams } from 'primereact/multiselect';
import { Row } from 'primereact/row';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { replicateParams, updateParams } from 'client/api';
import { useParametros } from 'client/hooks';
import { Parametro } from 'client/interfaces';
import { filterByGlobalFilter, numberFilterTemplate } from 'components/datatable';
import useMediaQuery from 'hooks/useMediaQuery';
import styles from './ParametrosTable.module.scss';
import * as S from './styles';
import Swal from 'sweetalert2';
import { UseCompaniesSelect } from 'hooks/useCompanies/useCompaniesSelect';
import { UseByUserFiliais } from 'hooks/useFiliais/useByRoleFiliais';
import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { UseUsersByCompany } from 'hooks/useCompanies/useUserByCompany';
import { initialFiltersColumnsParamsTable } from './initialFiltersColumnsParamsTable';
import { GroupColumns, columnGroups } from './columnGroupsParamsTable';

interface ChangedCell {
  field: string;
  value: string | number;
}

const ParametrosTable = () => {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');
  const user = useAppSelector(userSelector);

  const [selectedCompany, setSelectedCompany] = useState(user?.codEmpresa);
  const [selectedUserByCompany, setSelectedUserByCompany] = useState(user?.codUsuario);

  const { optionsCompanies } = UseCompaniesSelect();
  const { optionsUsersByCompany } = UseUsersByCompany(selectedCompany);
  const { optionsFiliais, refetchFiliais } = UseByUserFiliais({
    codEmpresa: Number(selectedCompany),
    codUser: Number(selectedUserByCompany),
  });

  const { data: parametros, isLoading, isFetching, refetch } = useParametros(true, Number(selectedCompany));

  const [lastChangedCell, setLastChangedCell] = useState<ChangedCell>();
  const [filteredData, setFilteredData] = useState<Parametro[] | null>();
  const [confirmReplicateVisible, setConfirmReplicateVisible] = useState(false);
  const [confirmReplicateNoFilterVisible, setConfirmReplicateNoFilterVisible] = useState(false);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [dataTable, setDataTable] = useState();
  const [selectedColumns, setSelectedColumns] = useState(columnGroups);
  const [filters, setFilters] = useState(initialFiltersColumnsParamsTable);
  const [selectedFilial, setSelectedFilial] = useState(user?.codEmpresa);

  const isAdmin = useMemo(() => user?.admin ?? false, [user]);
  const codEmpresaVMais = 2072021;

  const onCellEditComplete = (e: ColumnEventParams) => {
    const { newRowData, rowData, field } = e;
    if (newRowData[field] === rowData[field]) return;
    setDataTable(newRowData);
    setLastChangedCell({ field, value: newRowData[field] });
  };
  const handleSaveAllData = () => {
    Swal.fire({
      title: 'Deseja salvar todas as alterações?',
      icon: 'warning',
      confirmButtonText: 'Salvar',
      cancelButtonText: 'Cancelar',
      showCloseButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        updateParams(dataTable)
          .then(() => {
            toast.success('Alterações salvas!');
            setDataTable(null);
          })
          .catch(() => {
            toast.error('Erro ao salvar alterações!');
          });
      }
    });
  };

  const columns = useMemo(() => selectedColumns.flatMap((g) => g.items), [selectedColumns]);

  const headerGroup = useMemo(
    () => (
      <ColumnGroup>
        <Row>
          {isAdmin && <Column key="COD.EMP" align="center" colSpan={1} frozen />}

          {selectedColumns.map((g, i) => (
            <Column
              headerStyle={{
                fontSize: '18px',
                background: i % 2 === 0 ? '#CFD1DA' : '#E2E3E9',
                fontWeight: 'bold',
              }}
              key={g.header}
              align="center"
              header={g.header}
              colSpan={g.items.length}
            />
          ))}
        </Row>
        <Row>
          {isAdmin && (
            <Column
              key="COD.EMP"
              header="COD.EMP"
              field="codEmpresa"
              filter
              sortable
              dataType="numeric"
              filterElement={numberFilterTemplate}
              frozen
            />
          )}
          {columns.map((c) => (
            <Column
              key={c.header}
              header={c.header}
              field={c.field}
              filter
              sortable
              dataType={c.dataType}
              filterElement={c.filterElement}
            />
          ))}
        </Row>
      </ColumnGroup>
    ),
    [selectedColumns, columns],
  );

  const onColumnToggle = (event: MultiSelectChangeParams) => {
    const sColumns = event.value as GroupColumns[];
    const orderedColumns = columnGroups.filter((group) => sColumns.some((sGroup) => sGroup.header === group.header));
    setSelectedColumns(orderedColumns);
  };

  const replicateValue = async (values: Parametro[]) => {
    if (!lastChangedCell) return;
    try {
      await replicateParams({
        field: lastChangedCell.field,
        value: lastChangedCell.value,
        params: values.map((v) => ({
          codEmpresa: v.codEmpresa,
          codUsur: v.codUsur,
        })),
      });
      toast.success('Dados replicados com sucesso');
      await refetch();
      setLastChangedCell(undefined);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      toast.error(`Erro ao replicar dados: ${e.message}`);
    }
  };

  const confirmReplicateValue = () => {
    if (!lastChangedCell) return;
    setConfirmReplicateVisible(true);
  };

  const onShow = () => {
    let selectAllCheckbox = document.querySelector('.p-multiselect-header > .p-checkbox');
    selectAllCheckbox.after(' TODAS COLUNAS');
  };

  const header = () => (
    <S.HeaderContainer>
      <S.ContainerButtons>
        <Button
          disabled={!lastChangedCell}
          type="button"
          icon="pi pi-fw pi-copy"
          label="Replicar Última Alteração"
          className="p-button-outlined p-button-secondary"
          onClick={confirmReplicateValue}
        />

        <Button
          disabled={!dataTable}
          icon="pi pi-fw pi-check"
          label="Salvar"
          className="button-vmais-primary"
          onClick={handleSaveAllData}
        />
      </S.ContainerButtons>

      <S.Header>
        <S.ContainerInputs style={{ marginTop: !isWebScreen && '10px' }}>
          <S.ContainerSelect>
            <label>Colunas</label>
            <MultiSelect
              value={selectedColumns}
              options={columnGroups}
              optionLabel="header"
              dataKey="header"
              showClear
              onChange={onColumnToggle}
              style={{ width: '150px' }}
              onShow={onShow}
            />
          </S.ContainerSelect>

          {user.codEmpresa === codEmpresaVMais && (
            <S.ContainerSelect>
              <label>Empresa</label>
              <Dropdown
                value={selectedCompany}
                options={optionsCompanies}
                onChange={(e) => {
                  setSelectedCompany(e.value);
                }}
                filter
                placeholder="Selecionar Empresa"
                filterBy="label"
                style={{ width: '150px' }}
              />
            </S.ContainerSelect>
          )}

          {user.codEmpresa === codEmpresaVMais && (
            <S.ContainerSelect>
              <span>Usuário</span>
              <Dropdown
                value={selectedUserByCompany}
                options={optionsUsersByCompany}
                onChange={(e) => {
                  setSelectedUserByCompany(e.value);
                }}
                placeholder="Selecionar um Usuário"
                filter
                filterBy="label"
              />
            </S.ContainerSelect>
          )}

          <S.ContainerSelect>
            <label>Filial</label>
            <Dropdown
              value={selectedFilial}
              options={optionsFiliais}
              onChange={(e) => {
                setSelectedFilial(e.value);
              }}
              emptyMessage="Nenhuma Filial encontrada :/"
              placeholder="Selecionar Filial"
              filterBy="label"
              style={{ width: '150px' }}
            />
          </S.ContainerSelect>

          <S.ContainerSelect>
            <label>Bucar</label>

            <S.ContainerInput className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                type="search"
                onChange={(e) => setGlobalFilter(e.target.value ?? undefined)}
                placeholder="Buscar..."
                style={{ width: !isWebScreen && '120px' }}
              />
            </S.ContainerInput>
          </S.ContainerSelect>
        </S.ContainerInputs>
      </S.Header>
    </S.HeaderContainer>
  );

  const filteredValue = useMemo(
    () => filterByGlobalFilter(parametros?.map((p, index) => ({ ...p, id: index })) ?? [], globalFilter),
    [parametros, globalFilter],
  );

  const accessDataManager = useMemo(() => {
    return filteredValue?.filter((objeto) => objeto.codFilial === selectedFilial);
  }, [filteredValue, selectedFilial]);

  useEffect(() => {
    setSelectedCompany(user.codEmpresa);
    setSelectedUserByCompany(user.codUsuario);
  }, []);

  useEffect(() => {
    refetchFiliais();
  }, [selectedUserByCompany, selectedCompany]);

  return (
    <S.ContainerTable>
      <DataTable
        tableClassName={styles.datatable}
        onValueChange={(fd) => {
          setFilteredData(fd);
        }}
        value={accessDataManager}
        headerColumnGroup={headerGroup}
        header={header}
        size="small"
        showGridlines
        editMode="cell"
        stripedRows
        resizableColumns
        columnResizeMode="fit"
        paginator
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        rowsPerPageOptions={[10, 25, 50, 100, 200, 300, 500]}
        rows={!isWebScreen ? 10 : 50}
        dataKey="id"
        rowHover
        filters={filters}
        filterDisplay="menu"
        loading={isLoading || isFetching}
        scrollDirection="both"
        scrollHeight="calc(100vh - 250px)"
        emptyMessage="Nenhum dado encontrado"
        currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
      >
        {isAdmin && <Column header="COD.EMP" field="codEmpresa" filterElement={numberFilterTemplate} frozen />}
        {columns.map((c) => (
          <Column
            key={c.header}
            header={c.header}
            field={c.field}
            editor={c.editor}
            onCellEditComplete={onCellEditComplete}
            body={c.itemTemplate}
          />
        ))}
      </DataTable>
      <ConfirmDialog
        visible={confirmReplicateVisible}
        message={`Tem certeza que deseja replicar o valor '${lastChangedCell?.value ?? ''}' do campo '${
          lastChangedCell?.field ?? ''
        }' para os ${(filteredData?.length || parametros?.length) ?? 0} registros?`}
        header="COnfirmação!"
        onHide={() => setConfirmReplicateVisible(false)}
        icon="pi pi-exclamation-triangle"
        accept={async () => {
          if (!filteredData?.length || filteredData?.length === parametros?.length)
            setConfirmReplicateNoFilterVisible(true);
          else {
            await replicateValue(filteredData);
          }
        }}
      />
      <ConfirmDialog
        visible={confirmReplicateNoFilterVisible}
        message={`Não existem filtros selecionados! Esta operação irá alterar ${
          parametros?.length ?? 0
        } registros. Tem certeza que deseja continuar?`}
        onHide={() => setConfirmReplicateNoFilterVisible(false)}
        header="COnfirmação!"
        icon="pi pi-exclamation-triangle"
        accept={async () => await replicateValue(parametros ?? [])}
      />
    </S.ContainerTable>
  );
};

export default ParametrosTable;
