import * as S from './styles';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useState } from 'react';
import ControlHeader from './components/controlHeader/index';
import ControlSideBar from './components/controlSideBar';
import { useForm } from 'react-hook-form';
import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { useVisitControlPointsData, useVisitControlStatusData } from 'client/hooks/roteirizador';
import { IPointsVisitControl } from 'client/interfaces/roteirizador.interfaces';
import ControlLoading from './components/controlLoading/controlLoading';
import { useLocation } from 'react-router-dom';

const Buffer = require('buffer').Buffer;

export default function ControleVisitas() {
  const user = useAppSelector(userSelector);

  const location = useLocation();
  const currentPath = location.pathname;

  const [isMobileView, setIsMobileView] = useState(currentPath == '/controle-visitas-mobile');

  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [cliMarkers, setCliMarkers] = useState(null);
  const [mapZoom, setMapZoom] = useState(5);
  const [mapCenter, setMapCenter] = useState({
    lat: -15.79530797472881,
    lng: -47.88850280934874,
  });
  const [selectedMarker, setSelectedMarker] = useState(null);

  const [showLoading, setShowLoading] = useState(true);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      codCompany: user?.codEmpresa ?? 2072021,
      codUserByCompany: user?.codUsuario,
      codSupervisor: '0',
      codSeller: '0',
      filterGlobal: '',
    },
  });

  const values = watch();

  const { data: dataPoints, refetch: refetchDataPoints } = useVisitControlPointsData(
    String(values.codCompany) ?? '2072021',
    values.codSeller,
    values.codSupervisor,
    String(values.codUserByCompany) ?? '-1',
  );
  const { data: dataStatus, refetch: refetchDataStatus } = useVisitControlStatusData(
    String(values.codCompany) ?? '2072021',
    values.codSeller,
    values.codSupervisor,
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyAUHxQUnO76uq2HBu2X6xzaLZPapIFv--0',
    libraries: ['drawing', 'places'],
  });

  const renderMarkers = () => {
    dataPoints &&
      dataPoints[0] &&
      setMapCenter({
        lat: +dataPoints[0].LATITUDE ?? 0,
        lng: +dataPoints[0].LONGITUDE ?? 0,
      });
    let markersCli = dataPoints?.map((cli: IPointsVisitControl) => {
      return (
        <Marker
          icon={{
            url: cli.ICONE,
          }}
          key={Math.random()}
          position={{ lat: +cli.LATITUDE, lng: +cli.LONGITUDE }}
          onClick={(e) => setSelectedMarker(cli)}
        />
      );
    });
    setCliMarkers(markersCli);
    if (!cliMarkers) {
      setMapZoom(5);
    }
    setTimeout(() => {
      setShowLoading(false);
    }, 1000);
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  const getVisitControlFullData = () => {
    refetchDataStatus();
    refetchDataPoints().finally(() => {
      setTimeout(() => {
        setShowLoading(false);
      }, 2500);
    });
  };

  useEffect(() => {
    setShowLoading(true);
    getVisitControlFullData();
  }, [values.codCompany, values.codSeller, values.codSupervisor]);

  useEffect(() => {
    renderMarkers();
  }, [dataPoints]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('t');
    if (token && token.length > 0) {
      const decodedToken = Buffer.from(token, 'base64').toString('utf8');
      const tokenData = decodedToken.split('|');
      if (tokenData[0] && tokenData[1]) {
        setShowMap(isLoaded);
        setValue('codCompany', tokenData[0]);
        setValue('codUserByCompany', tokenData[1]);
      } else {
        setShowMap(false);
      }
    } else if (currentPath == '/controle-visitas-mobile') {
      setShowMap(false);
    } else if (!showMap) {
      setShowMap(isLoaded);
    }
  }, [isLoaded]);

  if (showMap) {
    return (
      <S.MapMainBox toTop={isFullScreenEnabled}>
        {showMap && (
          <GoogleMap
            mapContainerClassName="map-container"
            center={mapCenter}
            zoom={mapZoom}
            options={{
              mapTypeControl: false,
              mapTypeControlOptions: {
                position: window.google.maps.ControlPosition.TOP_LEFT,
              },
              streetViewControl: true,
              streetViewControlOptions: {
                position: window.google.maps.ControlPosition.TOP_LEFT,
              },
              zoomControlOptions: {
                position: window.google.maps.ControlPosition.TOP_LEFT,
              },
              fullscreenControl: false,
            }}
          >
            {cliMarkers && <div id={'markersBox'}>{cliMarkers}</div>}

            {selectedMarker && (
              <InfoWindow
                position={{ lat: +selectedMarker.LATITUDE, lng: +selectedMarker.LONGITUDE }}
                onCloseClick={handleInfoWindowClose}
                options={{
                  pixelOffset: new window.google.maps.Size(0, -45),
                }}
              >
                <S.CustomInfoWindow>
                  <div dangerouslySetInnerHTML={{ __html: selectedMarker.POPUP }} />
                </S.CustomInfoWindow>
              </InfoWindow>
            )}
          </GoogleMap>
        )}

        <ControlHeader
          setIsFullScreenEnabled={setIsFullScreenEnabled}
          isFullScreenEnabled={isFullScreenEnabled}
          getVisitControlFullData={getVisitControlFullData}
        />

        <ControlSideBar dataStatus={dataStatus} values={values} setValue={setValue} control={control} />
        <ControlLoading showLoading={showLoading} />
      </S.MapMainBox>
    );
  } else {
    return <></>;
  }
}
