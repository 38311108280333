import gif from 'assets/gift/cliMapLoader.gif';

import * as S from './controlLoading.styles';

interface ControlLoadingProps {
  showLoading: boolean;
  message?: string;
}

export default function ControlLoading({ showLoading, message }: ControlLoadingProps) {
  if (showLoading) {
    return (
      <S.Container>
        <img src={gif} />
        <span>{message ?? 'Aguarde, buscando dados...'}</span>
      </S.Container>
    );
  } else {
    return <></>;
  }
}
