import useMediaQuery from 'hooks/useMediaQuery';
import { Dialog } from 'primereact/dialog';
import { SetStateAction, useRef, useState } from 'react';
import VmButton from 'components/VmButton/VmButton';
import styled from 'styled-components';
import { InputTextarea } from 'primereact/inputtextarea';

import * as S from './editKpiModal.styles';
import { KpiProps } from 'client/interfaces';
import { useKpi } from 'modules/kpi/kpi.context';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { putEditKpi } from 'client/api/kpis';
import { toast } from 'react-toastify';

interface editKpiModalProps {
  showEditKpiModal: boolean;
  setShowEditKpiModal: React.Dispatch<SetStateAction<boolean>>;
}

export default function EditKpiModal({ showEditKpiModal, setShowEditKpiModal }: editKpiModalProps) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const { selectedKpi, handleGetKpiList, agrupadorOptions } = useKpi();

  const [toEditKpi, setToEditKpi] = useState<KpiProps>({ ...selectedKpi });

  const textareaRef = useRef(null);

  const handleEditRegra = () => {
    console.log(agrupadorOptions.filter((agrupador: any) => +agrupador.code == toEditKpi.agrupador)[0]);
    const kpiDataToEdit = { ...toEditKpi };
    kpiDataToEdit.sqlleitura = textareaRef.current ? textareaRef.current.value : '';
    kpiDataToEdit.itens = null;
    putEditKpi(kpiDataToEdit)
      .then((res) => {
        if (res.succeeded) {
          handleGetKpiList();
          toast.success('KPI editado com sucesso');
          setShowEditKpiModal(false);
        }
      })
      .catch((err) => {
        toast.error('Falha inesperada ao editar KPI.');
        console.log('err :', err);
      });
  };

  const StyledTextarea = styled(InputTextarea)`
    resize: none;
  `;

  return (
    <Dialog
      header={toEditKpi ? `Editar KPI [${toEditKpi.nrokpi}] - ${toEditKpi.titulo}` : 'Editar KPI'}
      visible={showEditKpiModal}
      style={{ width: isWebScreen ? '55vw' : '90%' }}
      onHide={() => {
        setShowEditKpiModal(false);
      }}
    >
      <S.EditKpiModalFormBox>
        <S.EditKpiModalFormRow>
          <S.KpiInputBox>
            <label htmlFor="titulo_kpi">Título</label>
            <InputText
              id="titulo_kpi"
              placeholder="Título"
              style={{
                width: 'clamp(200px, 250px, 300px)',
              }}
              value={toEditKpi.titulo}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                setToEditKpi((prevState: any) => {
                  return {
                    ...prevState,
                    titulo: e.target.value,
                  };
                });
              }}
            />
          </S.KpiInputBox>

          <S.KpiInputBox>
            <label htmlFor="freqatualiza_kpi">Freq. Atualiza</label>
            <InputText
              id="freqatualiza_kpi"
              placeholder="Freq. Atualiza"
              style={{
                width: 'clamp(75px, 90px, 120px)',
              }}
              value={toEditKpi.freqatualiza}
              onChange={(e) => {
                setToEditKpi((prevState: any) => {
                  return {
                    ...prevState,
                    freqatualiza: e.target.value,
                  };
                });
              }}
            />
          </S.KpiInputBox>

          <S.KpiInputBox>
            <label htmlFor="agrupador_kpi">Agrupador</label>
            <Dropdown
              options={agrupadorOptions}
              value={(toEditKpi.agrupador ?? 1).toString()}
              onChange={(e) => {
                setToEditKpi((prevState: any) => {
                  return {
                    ...prevState,
                    agrupador: e.value,
                  };
                });
              }}
              placeholder="Agrupador"
              optionLabel="name"
              optionValue="code"
              itemTemplate={(agrupador) => {
                return '[' + agrupador.code + '] - ' + agrupador.name;
              }}
              id="agrupador_kpi"
            />
          </S.KpiInputBox>
        </S.EditKpiModalFormRow>

        <S.EditKpiModalFormRow>
          <S.KpiInputBox>
            <label htmlFor="sqlleitura_kpi">SQL Apuração</label>
            <StyledTextarea
              id="sqlleitura_kpi"
              ref={textareaRef}
              defaultValue={toEditKpi ? toEditKpi.sqlleitura : ''}
              rows={19}
              cols={100}
            />
          </S.KpiInputBox>
        </S.EditKpiModalFormRow>

        <S.EditKpiModalFormFooter>
          <VmButton
            options={{
              label: 'Salvar',
              style: 'btn-success',
              size: 'btn-md',
            }}
            onClick={() => {
              handleEditRegra();
            }}
          />
        </S.EditKpiModalFormFooter>
      </S.EditKpiModalFormBox>
    </Dialog>
  );
}
