const verbasList = [
  {
    nro: 1001,
    codEmpresa: 122008,
    descricao: 'Verba para promoção de produtos de limpeza',
    dtInicio: '01/01/2023',
    dtFim: '31/12/2023',
    valor: 50000.0,
    valorDistribuido: 30000.0,
    saldoDistribuido: 20000.0,
    valorAplicado: 25000.0,
    saldoAplicado: 25000.0,
    codFuncCad: 123,
    nomeFuncCad: 'João Silva',
    dtCadastro: '02/01/2023',
    observacao: 'Promoção especial para o primeiro semestre.',
    saldoNegativo: 'N',
    alterDistValor: 'S',
    aplicDescValorOferta: 'S',
    gerDebCred: 'N',
    descMaxItem: 15,
    descMaxPed: 10,
    restrAplicLista: 1,
    formaAplicaBnf: 'S',
    formaAplicaDesc: 'N',
    exigeAprovaBnf: 'N',
    exigeAprovaDesc: 'S',
    filial: '7',
    status: 'S',
  },
  {
    nro: 1002,
    codEmpresa: 122008,
    descricao: 'Verba para campanha de eletrônicos',
    dtInicio: '15/03/2023',
    dtFim: '15/09/2023',
    valor: 80000.0,
    valorDistribuido: 50000.0,
    saldoDistribuido: 30000.0,
    valorAplicado: 40000.0,
    saldoAplicado: 40000.0,
    codFuncCad: 456,
    nomeFuncCad: 'Maria Souza',
    dtCadastro: '14/03/2023',
    observacao: 'Campanha para TVs e eletrodomésticos.',
    saldoNegativo: 'N',
    alterDistValor: 'N',
    aplicDescValorOferta: 'S',
    gerDebCred: 'S',
    descMaxItem: 20,
    descMaxPed: 12,
    restrAplicLista: 0,
    formaAplicaBnf: 'N',
    formaAplicaDesc: 'S',
    exigeAprovaBnf: 'S',
    exigeAprovaDesc: 'N',
    filial: '7',
    status: 'S',
  },
  {
    nro: 1003,
    codEmpresa: 122008,
    descricao: 'Verba para desconto em moda e vestuário',
    dtInicio: '01/06/2023',
    dtFim: '31/08/2023',
    valor: 60000.0,
    valorDistribuido: 35000.0,
    saldoDistribuido: 25000.0,
    valorAplicado: 20000.0,
    saldoAplicado: 40000.0,
    codFuncCad: 789,
    nomeFuncCad: 'Carlos Almeida',
    dtCadastro: '31/05/2023',
    observacao: 'Descontos para o período de liquidação de inverno.',
    saldoNegativo: 'S',
    alterDistValor: 'S',
    aplicDescValorOferta: 'N',
    gerDebCred: 'N',
    descMaxItem: 25,
    descMaxPed: 15,
    restrAplicLista: 1,
    formaAplicaBnf: 'N',
    formaAplicaDesc: 'S',
    exigeAprovaBnf: 'N',
    exigeAprovaDesc: 'S',
    filial: '34',
    status: 'S',
  },
  {
    nro: 1004,
    codEmpresa: 122008,
    descricao: 'Verba para promoções de móveis',
    dtInicio: '10/04/2024',
    dtFim: '10/10/2024',
    valor: 100000.0,
    valorDistribuido: 60000.0,
    saldoDistribuido: 40000.0,
    valorAplicado: 55000.0,
    saldoAplicado: 45000.0,
    codFuncCad: 321,
    nomeFuncCad: 'Ana Ferreira',
    dtCadastro: '09/04/2024',
    observacao: 'Promoção para sofás e estantes.',
    saldoNegativo: 'N',
    alterDistValor: 'N',
    aplicDescValorOferta: 'S',
    gerDebCred: 'N',
    descMaxItem: 18,
    descMaxPed: 14,
    restrAplicLista: 0,
    formaAplicaBnf: 'S',
    formaAplicaDesc: 'N',
    exigeAprovaBnf: 'S',
    exigeAprovaDesc: 'N',
    filial: '7',
    status: 'N',
  },
];

export default verbasList;
