import styled from 'styled-components';

export const MapMainBox = styled.section`
  position: relative;
  transition: all 0.4s ease;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: 0px;

  .map-container {
    height: 99%;
    width: 100%;
    border: 3px solid #fff;
    border-radius: 5px;
    position: relative;
  }
`;

export const CustomInfoWindow = styled.div`
  position: relative;
  top: 0px;
  white-space: nowrap;
  padding: 5px;
`;

export const MarkersSubtitle = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  padding: 10px 10px 5px 5px;
  border-top-right-radius: 10px;
  padding-right: 25px;

  @media (max-width: 992px) {
    display: none;
  }
`;

export const Subtitle = styled.span``;
