import { PropagateLoader } from 'react-spinners';

import logo from 'assets/images/logo/v-mais.svg';

import * as S from './styles';
import { useLoaderEffect } from 'providers/loaderEffect';

const LoaderEffect = () => {
  const { loader } = useLoaderEffect();

  return (
    <S.Box show={loader.show}>
      <img src={logo} />
      <S.Text>{loader.text ?? ''}</S.Text>
      <PropagateLoader size="13px" color="#228F6B" />
    </S.Box>
  );
};

export default LoaderEffect;
