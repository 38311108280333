import { ISupervisor, VendedoresByCompanyProps } from 'client/interfaces/supervisor.interface';
import api from 'services/api';

const _controller = 'Supervisor';

export const getSellersCompany = async (CodEmpresa: number): Promise<ISupervisor[]> => {
  const { data } = await api.get(`${_controller}?empresa=${CodEmpresa}`);
  return data;
};

export const getVendedoresByUsuario = async (
  codEmpresa: number | string,
  codUsuario: number | string,
): Promise<VendedoresByCompanyProps[]> => {
  const { data } = await api.get(`/Vendedor/GetVendedorSupervisor/${codEmpresa}/${codUsuario}`);
  return data;
};

export const getSupervisorByRole = async (
  codEmpresa: number | string,
  codUser: number | string,
): Promise<ISupervisor[]> => {
  const { data } = await api.get(`${_controller}/Allowed/ByUser/${codEmpresa}/${codUser}`);
  return data;
};
