import { IFiliais } from 'client/interfaces/filiais.interface';
import api from 'services/api';

const _controller = 'Filial';

export const getFiliaisEmpresa = async (CodEmpresa: number): Promise<IFiliais[]> => {
  const { data } = await api.get(`${_controller}/Filiais/${CodEmpresa ?? 0}`);
  return data;
};

export const getFiliaisByUser = async (CodEmpresa: number, CodUser: number): Promise<IFiliais[]> => {
  const { data } = await api.get(`Filial/Allowed/ByUser/${CodEmpresa}/${CodUser}`);
  return data;
};
