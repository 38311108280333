import styled, { keyframes } from 'styled-components';

export const KpiViewMainBox = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const KpiProgressBar1 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;

  label {
    text-align: end;
    padding-right: 5px;
  }
`;

export const KpiHeaderBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  border-radius: 7.5px;
  border: 1px solid #cccccc70;
  background-color: #fff;
  width: auto;
  position: sticky;
  top: 20px;
  left: 0px;
  z-index: 9;
`;

export const KpiHeaderButtonsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  padding: 0 25px;
`;

export const KpiHeaderFormBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: flex-start;
  gap: 20px;
`;

export const KpiInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const showAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const KpiBodyTableBox = styled.section`
  width: 100%;
  height: 68dvh;
  background-color: #00000010;
  border-radius: 7.5px;
  overflow: hidden;
  border: 1px solid #cccccc70;
  background-color: #fff;
  transition: all 0.4s ease;
  animation: ${showAnimation} 0.8s ease forwards;
  text-align: right !important;

  .vlObjetivoColumn {
    background-color: #60606010 !important;
    cursor: text;
  }
`;

export const KpiViewModeButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #cccccc10;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #cccccc40;
`;

interface ModeButtonProps {
  active: boolean;
}

export const KpiViewModeButton = styled.div<ModeButtonProps>`
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${(props) => (props.active ? '#cccccc40' : '#cccccc10')};
  transition: all 0.4s ease;
  svg {
    font-size: 2rem;
    color: ${(props) => (props.active ? '#000000' : '#606060')};
  }
  &:hover {
    background-color: ${(props) => (props.active ? '#cccccc60' : '#cccccc20')};
  }
`;

export const KpiBodyMapBox = styled.section`
  width: 100%;
  max-height: calc(68dvh - 10px);
  border-radius: 7.5px;
  overflow: auto;
  background-color: #fff;
  transition: all 0.4s ease;
  animation: ${showAnimation} 0.8s ease forwards;
  background-color: #f5f8fa;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
`;

export const KpiMapProgressBox = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: calc(25% - 10px);
  min-width: 280px;
  height: 140px;
  border-radius: 5px;
  border: 1px solid #cccccc70;
  padding: 10px;
  gap: 12px;
  label {
    font-size: 1rem;
  }
`;

export const KpiMapProgressBoxLabels = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 1rem;
  }
`;

export const KpiMapProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  width: 100%;
  margin: 0 auto;

  label {
    text-align: end;
    padding-right: 5px;
    color: #606060;
  }
`;

export const KpiMapObectives = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 2px;
  max-width: 100%;
  min-width: 90%;
  margin: 0 auto;
  color: #606060;
  font-size: 1.2rem;
`;

export const KpiMapObectiveSpan = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 2px;
  max-width: 100%;
  min-width: 40%;
  margin: 0 auto;
  color: #606060;
  font-size: 1.2rem;
`;

export const KpiMapRealSpan = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 2px;
  max-width: 100%;
  min-width: 40%;
  margin: 0 auto;
  color: #606060;
  font-size: 1.2rem;
`;

export const KpiBodyMapClockBox = styled.section`
  width: 100%;
  max-height: calc(68dvh - 10px);
  border-radius: 7.5px;
  overflow: auto;
  background-color: #fff;
  transition: all 0.4s ease;
  animation: ${showAnimation} 0.8s ease forwards;
  background-color: #f5f8fa;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
`;

export const KpiMapProgressBoxClock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: calc(25% - 10px);
  min-width: 200px;
  height: 150px;
  border-radius: 5px;
  border: 1px solid #cccccc70;
  padding: 10px;
  gap: 12px;
  position: relative;
`;

export const KpiMapProgressBoxClockLabels = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 1rem;
  }
`;

export const KpiMapProgressBarClock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  width: 100%;
  margin: 0 auto;

  label {
    text-align: end;
    padding-right: 5px;
    color: #606060;
  }
`;

export const KpiMapObectivesClock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
  gap: 2px;
  max-width: 100%;
  min-width: 90%;
  margin: 0 auto;
  color: #606060;
  font-size: 1.2rem;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(0%, -20%);
  text-align: right;
`;

export const KpiMapObectiveSpanClock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: right;
  align-items: center;
  gap: 2px;
  max-width: 100%;
  min-width: 40%;
  color: #606060;
  font-size: 1.2rem;

  @media (max-width: 1080px) {
    font-size: 1rem;
  }
`;

export const KpiMapObectiveSpanClockLabel = styled.div`
  font-size: 0.9rem;

  @media (max-width: 1080px) {
    font-size: 0.75rem;
  }
`;

export const KpiMapRealSpanClock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: right;
  align-items: center;
  gap: 2px;
  max-width: 100%;
  min-width: 40%;
  color: #606060;
  font-size: 1.2rem;

  @media (max-width: 1080px) {
    font-size: 1rem;
  }
`;
