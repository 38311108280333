import styled from 'styled-components';

export const BoxTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  position: relative;

  .p-datatable {
    padding: 10px;
    max-height: 80dvh !important;
    overflow: scroll;
  }
  .p-datatable-thead {
    position: sticky !important;
    top: -15px !important;
    z-index: 101 !important;
  }
  .p-paginator {
    position: sticky !important;
    bottom: -15px !important;
    z-index: 101 !important;
  }
`;
