import { useSupervisorByRole } from 'client/hooks/supervisor';

interface IUseSupervisorParams {
  codCompany: number | string;
  codUser: number | string;
}

export const UseSupervisorByRole = ({ codCompany, codUser }: IUseSupervisorParams) => {
  const { data: supervisors, isLoading, refetch: refetchSupervisor } = useSupervisorByRole(codCompany, codUser);

  const optionsSupervisorByRole = supervisors?.map((item) => ({
    value: item.codSupervisor,
    label: `${item.nomesup} - [${item.codSupervisor}]`,
  }));

  return {
    optionsSupervisorByRole,
    isLoading,
    refetchSupervisor,
  };
};
