import {
  IClient,
  IGroupClient,
  createGroupClientDTO,
  deleteGroupClientDTO,
  editGroupClientDTO,
} from 'client/interfaces/groupClient';
import api from 'services/api';

const _controller = 'GrupoCliente';

export const postGroupClient = async (data: createGroupClientDTO): Promise<void> => {
  await api.post(_controller, data);
};

export const putGroupClient = async (data: editGroupClientDTO): Promise<void> => {
  await api.put(_controller, data);
};

export const getGroupClient = async (codEmpresa: number | string): Promise<IGroupClient[]> => {
  const { data } = await api.get(`${_controller}/${codEmpresa}`);
  return data;
};

export const getGroupClientFilial = async (
  codEmpresa: number | string,
  codFilial: number | string,
): Promise<IGroupClient[]> => {
  const { data } = await api.get(`${_controller}/${codEmpresa}/${codFilial}`);
  return data;
};

export const getGroupClientFiliais = async (
  codEmpresa: number | string,
  codFiliais: Array<number> | any,
  codCliente?: number,
): Promise<IGroupClient[]> => {
  const codFiliaisParams = codFiliais.map((codFilial, index) => `CodFiliais[${index}]=${codFilial}`).join('&');
  const { data } = await api.get(`${_controller}/${codEmpresa}/Filiais/${codCliente}?${codFiliaisParams}`);
  return data;
};

export const deleteGroupClient = async (data: deleteGroupClientDTO): Promise<void> => {
  await api.delete(_controller, {
    data: data,
  });
};

export const getClients = async (codEmpresa: number | string): Promise<IClient[]> => {
  const { data } = await api.get(`GrupoCliente/GetCliente?CodEmpresa=${codEmpresa}`);
  return data;
};

export const getClientsToAddGroup = async (
  codCompany: number | string,
  codGroup: number | string,
): Promise<IClient[]> => {
  const { data } = await api.get(`GrupoCliente/GetCliente/ToAddGroup?codCompany=${codCompany}&codGroup=${codGroup}`);
  return data;
};
