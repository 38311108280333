import useMediaQuery from 'hooks/useMediaQuery';
import * as S from './FormImagesModal.styles';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import VmButton from 'components/VmButton/VmButton';
import { FaTrash } from 'react-icons/fa';
import { postDeleteImageFormExemplo, postImageFormExemplo } from 'client/api/form';

export default function FormImagesModal({ showFormImagesModal, setShowFormImagesModal, item, refetchForms }: any) {
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [imagesForm, setImagesForm] = useState({
    img1: item ? item.fotoExemplo : null,
    img1Url: item ? item.fotoExemplo : null,
    codEmpresa: item ? item.codEmpresa : 0,
    nroForm: item ? item.nroform : 0,
    ordem: item ? item.ordem : 0,
  });

  useEffect(() => {
    if (showFormImagesModal)
      setImagesForm({
        img1: item ? item.fotoExemplo : null,
        img1Url:
          item && item.fotoExemplo
            ? `${process.env.REACT_APP_BASE_URL}/fotos_exemplo_formulario/${item.fotoExemplo}`
            : null,
        codEmpresa: item ? item.codEmpresa : 0,
        nroForm: item ? item.nroform : 0,
        ordem: item ? item.ordem : 0,
      });
  }, [item, showFormImagesModal]);

  const handleImageSelect = (file: any, imageName: string) => {
    if (file.files && file.files[0]) {
      let img = file.files[0];
      if (
        file.files[0].type != 'image/png' &&
        file.files[0].type != 'image/webp' &&
        file.files[0].type != 'image/jpg' &&
        file.files[0].type != 'image/jpeg'
      ) {
        toast.error('Arquivo inválido!');
        return;
      }
      setImagesForm((prevState: any) => {
        prevState[imageName + 'Url'] = URL.createObjectURL(img) ?? img.name ?? '';
        prevState[imageName] = img;
        return { ...prevState };
      });
    }
  };

  const handleRefetchFormData = async () => {
    refetchForms();
  };

  const handleInsertImages = () => {
    postImageFormExemplo(imagesForm)
      .then((res: any) => {
        if (res.succeeded) {
          toast.success('Sucesso ao gravar imagens');
          setShowFormImagesModal(false);
          handleRefetchFormData();
        }
      })
      .catch((err: any) => {
        console.log('err :', err);
      });
  };

  const handleDeleteImage = () => {
    postDeleteImageFormExemplo(imagesForm.img1, imagesForm.codEmpresa, imagesForm.nroForm, imagesForm.ordem)
      .then((res: any) => {
        if (res.succeeded) {
          toast.success('Imagem Removida');
          setShowFormImagesModal(false);
          handleRefetchFormData();
        }
      })
      .catch((err: any) => {
        console.log('err :', err);
      });
  };

  return (
    <Dialog
      header={item ? `Imagem exemplo do item [${item.ordem}] - ${item.tipo}` : 'Imagenm exemplo'}
      visible={showFormImagesModal}
      style={{ width: isWebScreen ? '35vw' : '90%' }}
      onHide={() => {
        setShowFormImagesModal(false);
      }}
    >
      <S.RegrasImagesModalMainBox>
        <S.ImagemCard image={imagesForm.img1Url}>
          <S.FloatCenterAddImage
            onClick={() => {
              const imageIinput = document.getElementById('newCampanhaImagem1');
              imageIinput.click();
            }}
          />
          <img src={imagesForm.img1Url ? imagesForm.img1Url : ''} alt={imagesForm.img1Url ?? ''} />
          {/* <S.RegrasImagesLabel>Tamanho ideal para a imagem: 359x162px</S.RegrasImagesLabel> */}
          <input
            type="file"
            id="newCampanhaImagem1"
            name="newCampanhaImagem1"
            accept="image/png, image/webp, image/jpeg, image/jpg"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleImageSelect(e.target, 'img1');
            }}
          />
          <S.FloatIcons>
            <FaTrash
              onClick={() => {
                imagesForm.img1 && handleDeleteImage();
              }}
            />
          </S.FloatIcons>
        </S.ImagemCard>
      </S.RegrasImagesModalMainBox>

      <S.RegrasImagesModalFooterBox>
        {imagesForm.img1 && (
          <VmButton
            options={{
              label: 'Salvar',
              style: 'btn-success',
            }}
            onClick={() => handleInsertImages()}
          />
        )}
      </S.RegrasImagesModalFooterBox>
    </Dialog>
  );
}
