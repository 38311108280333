import { Parametro } from 'client/interfaces';
import {
  booleanBodyTemplate,
  booleanEditor,
  booleanFilterTemplate,
  dateFilterTemplate,
  dateTimeTemplate,
  numberEditor,
  numberFilterTemplate,
  textBodyTemplate,
  textEditor,
  textFilterTemplate,
} from 'components/datatable';
import { ColumnEditorType, ColumnFilterElementType } from 'primereact/column';
import { ReactNode } from 'react';
export interface GroupColumns {
  header: string;
  items: GroupColumnItem[];
}
interface GroupColumnItem {
  header: string;
  field: string;
  dataType: 'numeric' | 'boolean' | 'date' | 'text';
  filterElement?: ColumnFilterElementType;
  editor?: ColumnEditorType;
  tooltip?: string;
  itemTemplate?: (rowData: Parametro) => ReactNode;
}
export const columnGroups: GroupColumns[] = [
  {
    header: 'Vendedor',
    items: [
      {
        header: 'FIL',
        field: 'codFilial',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
      },
      {
        header: 'GERENTE',
        field: 'gerente',
        dataType: 'text',
        filterElement: textFilterTemplate,
      },
      {
        header: 'SUPERVISOR',
        field: 'supervisor',
        dataType: 'text',
        filterElement: textFilterTemplate,
      },
      {
        header: 'VENDEDOR',
        field: 'codUsur',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        itemTemplate: (rowData) => textBodyTemplate(rowData?.nomeVendedor, rowData?.codUsur),
      },
      {
        header: 'CONECTA NUVEM',
        field: 'conectaNuvem',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.conectaNuvem),
      },
      {
        header: 'PEDIDOS NUVEM',
        field: 'pedidosNuvem',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.pedidosNuvem),
      },
    ],
  },
  {
    header: 'Versão',
    items: [
      {
        header: 'VER.CELULAR',
        field: 'versaoAtual',
        dataType: 'text',
        filterElement: textFilterTemplate,
      },
      {
        header: 'VER.DISP',
        field: 'ultversao',
        dataType: 'text',
        filterElement: textFilterTemplate,
        editor: textEditor,
      },
      {
        header: 'DTALTVERSAO',
        field: 'dtaltversao',
        dataType: 'date',
        filterElement: dateFilterTemplate,
        itemTemplate: (rowData) => dateTimeTemplate(rowData, 'dtaltversao'),
      },
      {
        header: 'ATUALIZADO',
        field: 'versaoAtualizada',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.versaoAtualizada),
      },
    ],
  },
  {
    header: 'GPS',
    items: [
      {
        header: 'USA GPS',
        field: 'controleGps',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.controleGps),
      },
      {
        header: 'USA GPS FORA ROTA',
        field: 'gpsForaRota',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.gpsForaRota),
      },
      {
        header: 'RAIO VISITA',
        field: 'raioCheckin',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: numberEditor,
      },
      {
        header: 'SEG.ROTA',
        field: 'seguirSequenciaRota',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.seguirSequenciaRota),
      },

      {
        header: 'QT.CLI.FORA ROTA',
        field: 'cliForaDaRota',
        tooltip: 'Quantidade de clientes que o usuário pode visitar fora da rota do dia',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: numberEditor,
      },

      {
        header: 'QT.CLI.AUSENTE',
        field: 'qtCliAusenteRetorno',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: numberEditor,
      },
    ],
  },
  {
    header: 'Jornada de Trabalho',
    items: [
      {
        header: 'C.ALMOCO',
        field: 'controlarAlmoco',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.controlarAlmoco),
      },
      {
        header: 'INI.JORNADA',
        field: 'hrIniJornada',
        dataType: 'text',
        filterElement: textFilterTemplate,
        editor: textEditor,
      },
      {
        header: 'INI.ALMOCO',
        field: 'hrIniAlmoco',
        dataType: 'text',
        filterElement: textFilterTemplate,
        editor: textEditor,
      },
      {
        header: 'FIM.ALMOCO',
        field: 'hrFimAlmoco',
        dataType: 'text',
        filterElement: textFilterTemplate,
        editor: textEditor,
      },

      {
        header: 'FIM.JORNADA',
        field: 'hrFimJornada',
        dataType: 'text',
        filterElement: textFilterTemplate,
        editor: textEditor,
      },
      {
        header: 'ALMOÇO(MIN)',
        field: 'almocoMinutos',
        tooltip: 'intervalo de almoço em minutos',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: numberEditor,
      },
      {
        header: 'TRABALHA SÁB.',
        field: 'x',
        tooltip: 'Trabalha sábado',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
      },
      {
        header: 'TRABALHA DOM.',
        field: 'x',
        tooltip: 'Trabalha domingo',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
      },
    ],
  },
  {
    header: 'Acessos',
    items: [
      {
        header: 'CAD.CLI.',
        field: 'cadcliente',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.cadcliente),
      },
      {
        header: 'ALT.CAD',
        field: 'alteraContatoCli',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.alteraContatoCli),
      },
      {
        header: 'ALT.PEDIDO',
        field: 'alteraped',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.alteraped),
      },
      {
        header: 'CANCEL.PED',
        field: 'cancelaped',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.cancelaped),
      },
      {
        header: 'DESC.CX',
        field: 'usaDescCxmaster',
        tooltip: 'Usa desconto da caixa master?',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.usaDescCxmaster),
      },
      {
        header: '%SUG.CX',
        field: 'percSugCxmaster',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: numberEditor,
      },
      {
        header: 'CONTRA-SENHA',
        field: 'contrasenha',
        dataType: 'numeric',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.contrasenha),
      },
      {
        header: 'LUCRATIVIDADE',
        field: 'permitelb',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.permitelb),
      },
      {
        header: 'LER.MSG',
        field: 'obrigaleremail',
        tooltip: 'Obriga o usuário a ler as notificações antes de prosseguir',
        dataType: 'boolean',
        filterElement: booleanFilterTemplate,
        editor: booleanEditor,
        itemTemplate: (rowData) => booleanBodyTemplate(rowData.obrigaleremail),
      },
      {
        header: 'DIAS ORÇ.',
        field: 'diasOrcamento',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: numberEditor,
      },
      {
        header: 'DIAS OFFLINE',
        field: 'diasConexao',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: numberEditor,
      },
    ],
  },
  {
    header: 'Dispositivo Autorizado',
    items: [
      {
        header: 'IMEI1',
        field: 'imei1',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: textEditor,
      },
      {
        header: 'IMEI2',
        field: 'imei2',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: textEditor,
      },
      {
        header: 'IMEI3',
        field: 'imei3',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: textEditor,
      },
      {
        header: 'IMEI SUPERV.',
        field: 'serial1',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
        editor: textEditor,
      },
    ],
  },
  {
    header: 'Informação do Dispositivo',
    items: [
      {
        header: 'FABRICANTE',
        field: 'fabricante',
        dataType: 'text',
      },
      {
        header: 'MARCA',
        field: 'marca',
        dataType: 'text',
      },
      {
        header: 'MODELO',
        field: 'modelo',
        dataType: 'text',
      },
      {
        header: 'TAMANHO TELA',
        field: 'tamanhoTela',
        dataType: 'text',
      },
      {
        header: 'VERSÃO ANDROID',
        field: 'versaoAndroid',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
      },
      {
        header: 'TIPO APARELHO',
        field: 'tipoAparelho',
        dataType: 'numeric',
        filterElement: numberFilterTemplate,
      },
    ],
  },
];
