import styled from 'styled-components';

export const Box = styled.div`
  width: 40%;
`;

export const Title = styled.div`
  width: 100%;
  background-color: var(--primary-green);
  height: 45px;
  border-radius: 4px;
  align-items: center;
  display: flex;
  margin-bottom: 1rem;

  h1 {
    color: white;
    padding: 10px;
  }
`;

export const ContainerPage = styled.div`
  margin-top: 2rem;

  .fc-popover {
    z-index: 999 !important;
  }

  @media (max-width: 768px) {
    margin-top: 0px;
    padding: 10px;
    padding-bottom: 40px;
  }
`;
