import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getMotivosNaoVenda } from 'client/api/motivoNaoVenda';
import { MotivoNaoVendaProps } from 'client/interfaces';

export const useMotivosNaoVenda = (codEmpresa: number, enabled = true) =>
  useQuery<MotivoNaoVendaProps[], AxiosError>(
    ['motivosNaoVenda', codEmpresa],
    async () => await getMotivosNaoVenda(codEmpresa),
    {
      enabled,
    },
  );
