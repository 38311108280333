import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { useEffect } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'client/hooks/usuarios';
import ContainerPage from 'components/ContainerPage';
import { styleHeaderCenter } from 'components/datatable';
import VmButton from 'components/VmButton/VmButton';
import * as S from './style';
import useMediaQuery from 'hooks/useMediaQuery';
import { ListMapsByCompany } from './ListMapsByCompany';
import { Controller, useForm } from 'react-hook-form';
import { UseCompaniesSelect } from 'hooks/useCompanies/useCompaniesSelect';
import { useSelectActive } from 'hooks/useSelectActive/useSelectActice';
import { UseUsersByCompany } from 'hooks/useCompanies/useUserByCompany';

const CadastroMapa = () => {
  const { control, watch, setValue } = useForm();

  const values = watch();

  const { data: user } = useUser();
  const { optionsCompanies, codEmpresaVMais } = UseCompaniesSelect();
  const { optionsActiveAndInactive } = useSelectActive();
  const { optionsUsersByCompany } = UseUsersByCompany(values.company ? values.company : 0);

  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const navigate = useNavigate();

  const leftToolbarTemplate = () => (
    <S.HeaderBox>
      <form>
        {codEmpresaVMais === user?.codEmpresa && (
          <S.InputBox>
            <label>Empresa</label>

            <Controller
              name="company"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Dropdown
                  placeholder="Selecione uma Empresa"
                  value={field.value}
                  options={optionsCompanies}
                  filter
                  onChange={(e) => setValue('company', e.value)}
                />
              )}
            />
          </S.InputBox>
        )}

        {codEmpresaVMais === user?.codEmpresa && (
          <S.InputBox>
            <label>Usuário</label>
            <Controller
              name="user"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <Dropdown
                  disabled={!values.company}
                  placeholder="Selecione um Usuário"
                  value={field.value}
                  options={optionsUsersByCompany}
                  onChange={(e) => setValue('user', e.value)}
                  filter
                  filterBy='label'
                />
              )}
            />
          </S.InputBox>
        )}

        <S.InputBox>
          <label>Ativo/Inativo</label>
          <Controller
            name="status"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <Dropdown
                placeholder="Ativo"
                value={field.value}
                options={optionsActiveAndInactive}
                onChange={(e) => setValue('status', e.value)}
              />
            )}
          />
        </S.InputBox>

        <VmButton
          options={{
            style: 'btn-success',
            rounded: !isWebScreen,
            icon: <BsPlusLg />,
            iconPosition: 'left',
            label: isWebScreen ? 'Adicionar' : '',
          }}
          onClick={() => {
            navigate(`./novo/${values?.company}`);
          }}
        />
      </form>
    </S.HeaderBox>
  );

  useEffect(() => {
    if (user?.codUsuario) {
      setValue('user', user?.codUsuario);
      setValue('company', user?.codEmpresa);
      setValue('status', 'S');
    }
  }, [user]);

  return (
    <ContainerPage>
      <Toolbar className="mb-4 pt-8" left={leftToolbarTemplate} />

      <S.ContainerTable mobile tablet>
        {values?.company && user?.codUsuario ? (
          <ListMapsByCompany codCompany={values?.company} codUser={values?.user} status={values?.status} />
        ) : (
          <DataTable
            value={[]}
            loading
            paginator
            rows={10}
            emptyMessage="Nenhum Mapa Encontrado"
            responsiveLayout="scroll"
            scrollHeight="calc(80vh - 150px)"
          >
            <Column header="CodEmpresa" field="codEmpresa" sortable />
            <Column header="CodFilial" field="codFilial" sortable headerClassName="testHead" />
            <Column header="Nome" field="nome" />
            <Column
              header="Ativo"
              headerStyle={styleHeaderCenter}
              field="ativo"
              sortable
              alignHeader="center"
              align="center"
            />
            <Column header="Mapa / Setor" />
            <Column header={'Editar'} />
          </DataTable>
        )}
      </S.ContainerTable>
    </ContainerPage>
  );
};

export default CadastroMapa;
