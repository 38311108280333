import styled from 'styled-components';

export const AnaliseRoteiroMainBox = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
`;
