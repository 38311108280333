import styled from 'styled-components';

export const VerbasMainBox = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0px;
`;

export const VerbasHeaderMainBox = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: end;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  border-radius: 7.5px;
  border: 1px solid #cccccc40;
  background-color: #fff;
  width: auto;
`;

export const VerbasHeaderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
  gap: 25px;
`;

export const VerbasRadioInputBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const VerbasInputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  input::focus {
    outline: none;
    border: none;
  }
`;

export const VerbasContentBox = styled.section`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  border-radius: 7.5px;
  width: auto;
  left: 0px;
  z-index: 9;
  height: 66dvh;
`;

export const VerbasContentItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 49.3%;
  max-height: 66vh;
  overflow: auto;
  gap: 5px;
`;
