import { Dropdown } from 'primereact/dropdown';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMapsByUser } from 'client/hooks/mapa';
import { MapContext } from 'providers/map';
import { regionsHomologUrl } from 'utils/urls';
import storage from 'services/storage';
import { useMapSectorsStore } from 'storesZustand/mapSectorsStore';

export const HeaderClientSectors = () => {
  const mapDataSelected = JSON.parse(storage.getItem('mapDataSelected'));
  const { codUser, codCompanyUser } = useMapSectorsStore();

  const [selectedMap, setSelectedMap] = useState(mapDataSelected);

  // const { data: mapas } = useMapsByUser(Number(codCompanyUser), Number(codUser));

  const { setCodEmpresa, SetCodMapa } = useContext(MapContext);

  const navigate = useNavigate();

  const onChangeMap = (option: any) => {
    setCodEmpresa(option.target.value.codEmpresa);
    SetCodMapa(option.target.value.cod);
    setSelectedMap(option.target.value);
    storage.setItem('mapDataSelected', JSON.stringify(option.target.value));
    navigate(`/${regionsHomologUrl}/${option.target.value.codEmpresa}/${option.target.value.cod}`);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    setSelectedMap(JSON.parse(storage.getItem('mapDataSelected')));
  }, []);

  return (
    <></>
    // <Dropdown
    //   value={selectedMap}
    //   onChange={onChangeMap}
    //   className="form-control form-control-lg form-control-solid"
    //   options={mapas?.filter((mapa: any) => mapa.ativo)}
    //   placeholder="Alterar Mapa"
    //   dataKey="cod"
    //   optionLabel="nome"
    //   filter={mapas?.length >= 10}
    //   filterBy="nome"
    //   style={{
    //     height: '30px',
    //     width: '200px',
    //     display: 'flex',
    //     alignItems: 'center',
    //   }}
    // />
  );
};
