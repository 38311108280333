import * as S from './styles';

import { useState, useEffect } from 'react';

import { useTitlePageStore } from 'storesZustand/titlePageStore';

import AnaliseMapa from './components/analiseMapa/index';

export default function AnaliseVisitas() {
  const { setTitlePage, titlePage } = useTitlePageStore();

  useEffect(() => {
    setTitlePage('Análise de Visitas');
  }, []);

  const [selectedCli, setSelectedCli] = useState<any>(null);

  const [searchResult, setSearchResult] = useState<any>([]);

  return (
    <S.AnaliseVisitaMainBox>
      <AnaliseMapa
        selectedCli={selectedCli}
        data={searchResult}
        setSearchResult={setSearchResult}
        setSelectedCli={setSelectedCli}
      />
    </S.AnaliseVisitaMainBox>
  );
}
