import styled, { keyframes } from 'styled-components';

interface ModeButtonProps {
  active: boolean;
}

export const HeaderMapToggle = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  overflow: hidden;
  padding: 5px;
  gap: 7px;
  position: absolute;
  right: 10px;
  bottom: 22.5px;
  z-index: 10;
  background-color: #FFF;
`;

export const HeaderMapToggleButton = styled.div<ModeButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px 10px;
  background-color: ${(props) => (props.active ? '#FFF' : 'transparent')};
  transition: all 0.4s ease;
  border-radius: 5px;
  transition: all 0.4s ease;
  box-shadow: ${(props) => (props.active ? '#17614870 0px 0px 0px 1px' : 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px')};
  color: ${(props) => (props.active ? '#176148' : '#606060')};
  svg {
    font-size: 2rem;
    color: ${(props) => (props.active ? '#176148' : '#606060')};
    transition: all 0.4s ease;
  }
  &:hover {
    background-color: #3f7d5410;
  }
`;
